import { Component, OnInit } from "@angular/core";

import { Platform } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})


export class AppComponent {

 
  ionViewWillEnter() {

    
  }
   
 

  public appPages = [

    {
      title: "Home",
      url: "/home",
      icon: "home",
    },
    // {
    //   title: "Map",
    //   url: "/map",
    //   icon: "earth",
    // },
    // {
    //   title: "HRIS",
    //   url: "/hris",
    //   icon: "time",
    // },
    // {
    //   title: "Saving",
    //   url: "/koperasi",
    //   icon: "leaf",
    // },
    // {
    //   title: "Point of Sales",
    //   url: "/pos",
    //   icon: "bag-handle",
    // },
    {
      title: "Dashboard",
      url: "/dashboard",
      icon: "easel",
    }
    // ,{
    //   title: "Report",
    //   url: "/report",
    //   icon: "podium",
    // }
    ,
    // {
    //   title: "Preference",
    //   url: "/setting",
    //   icon: "settings",
    // },
    // {
    //   title: "User",
    //   url: "/user/hm",
    //   icon: "person",
    // },
    
    {
      title: "About",
      url: "/about",
      icon: "disc",
    },
    {
      title: "Logout",
      url: "/login",
      icon: "exit",
    }
  ];


  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
  constructor(private platform: Platform, private splashScreen: SplashScreen,private statusBar: StatusBar ) {

  this.initializeApp();

  }


  initializeApp() {
    this.platform.ready().then(() => {
    
      if (this.platform.is('android')) {
        this.statusBar.backgroundColorByHexString("#875A7B");
      }

      this.splashScreen.hide();
    });
  }


}
