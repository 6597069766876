import { CONTEXT_NAME } from "@angular/compiler/src/render3/view/util";
import { Injectable } from "@angular/core";
import { SQLite, SQLiteObject } from "@ionic-native/sqlite/ngx";
import { strict } from "assert";
import { stringify } from "querystring";


@Injectable({
  providedIn: "root",
})

export class DatabaseService {
  databaseObj: SQLiteObject;
  databaseObj2: SQLiteObject;
  tables = {

    partners: "partners",
    products: "products",
    grproducts: "grproducts",
    units: "units",
    belis: "belis",
    belidets: "belidets",
    terimas: "terimas",
    terimadets: "terimadets",
    kirims: "kirims",
    kirimdets: "kirimdets",
    juals: "juals",
    jualdets: "jualdets",
    forexs: "forexs",
    companies: "companies",

    bkins: "bkins",
    bkindets: "bkindets",

    bkouts: "bkouts",
    bkoutdets: "bkoutdets",

    reks: "reks",
    banks: "banks",


    branches: "branches",
    whs: "whs",

    categories: "categories",
    joborders: "joborders",
    joborderdets: "joborderdets",

    mutasis: "mutasis",
    mutasidets: "mutasidets",

    stos: "stos",
    stodets: "stodets",

    securities: "securities",

    users: "users",

    simpans: "simpans",
    simpandets: "simpandets",

    settings: "settings",

    whouts: "whouts",
    whoutdets: "whoutdets",

    whins: "whins",
    whindets: "whindets",

  };

  constructor(private sqlite: SQLite) {
  }


  async dropAllTable(){

    await this.databaseObj.executeSql(
      `DROP TABLE IF EXISTS ${this.tables.users}`,
      []
    );
    
    
    await this.databaseObj.executeSql(
      `DROP TABLE IF EXISTS ${this.tables.settings}`,
      []
    );


    await this.databaseObj.executeSql(
      `DROP TABLE IF EXISTS ${this.tables.simpans}`,
      []
    );

    await this.databaseObj.executeSql(
      `DROP TABLE IF EXISTS ${this.tables.simpandets}`,
      []
    );

    await this.databaseObj.executeSql(
      `DROP TABLE IF EXISTS ${this.tables.companies}`,
      []
    );

    await this.databaseObj.executeSql(
      `DROP TABLE IF EXISTS ${this.tables.partners}`,
      []
    );
 
    await this.databaseObj.executeSql(
      `DROP TABLE IF EXISTS ${this.tables.belis}`,
      []
    
    );

    await this.databaseObj.executeSql(
      `DROP TABLE IF EXISTS ${this.tables.belidets}`,
      []
    );

    await this.databaseObj.executeSql(
      `DROP TABLE IF EXISTS ${this.tables.terimas}`,
      []
    
    );

    await this.databaseObj.executeSql(
      `DROP TABLE IF EXISTS ${this.tables.terimadets}`,
      []
    );

    await this.databaseObj.executeSql(
      `DROP TABLE IF EXISTS ${this.tables.kirims}`,
      []
    
    );

    await this.databaseObj.executeSql(
      `DROP TABLE IF EXISTS ${this.tables.kirimdets}`,
      []
    );
    
    await this.databaseObj.executeSql(
      `DROP TABLE IF EXISTS ${this.tables.units}`,
      []
    );

    await this.databaseObj.executeSql(
      `DROP TABLE IF EXISTS ${this.tables.grproducts}`,
      []
    );


    await this.databaseObj.executeSql(
      `DROP TABLE IF EXISTS ${this.tables.products}`,
      []
    );

      
    await this.databaseObj.executeSql(
      `DROP TABLE IF EXISTS ${this.tables.forexs}`,
      []
    );

    await this.databaseObj.executeSql(
      `DROP TABLE IF EXISTS ${this.tables.juals}`,
      []

    );

    
    await this.databaseObj.executeSql(
      `DROP TABLE IF EXISTS ${this.tables.jualdets}`,
      []
    );


    await this.databaseObj.executeSql(
      `DROP TABLE IF EXISTS ${this.tables.bkins}`,
      []

    );

    
    await this.databaseObj.executeSql(
      `DROP TABLE IF EXISTS ${this.tables.bkindets}`,
      []
    );

    
    await this.databaseObj.executeSql(
      `DROP TABLE IF EXISTS ${this.tables.bkouts}`,
      []

    );

    
    await this.databaseObj.executeSql(
      `DROP TABLE IF EXISTS ${this.tables.bkoutdets}`,
      []
    );

    await this.databaseObj.executeSql(
      `DROP TABLE IF EXISTS ${this.tables.reks}`,
      []
    );


    await this.databaseObj.executeSql(
      `DROP TABLE IF EXISTS ${this.tables.banks}`,
      []
    );


    await this.databaseObj.executeSql(
      `DROP TABLE IF EXISTS ${this.tables.branches}`,
      []
    );

    await this.databaseObj.executeSql(
      `DROP TABLE IF EXISTS ${this.tables.whs}`,
      []
    );

    await this.databaseObj.executeSql(
      `DROP TABLE IF EXISTS ${this.tables.categories}`,
      []
    );


    await this.databaseObj.executeSql(
      `DROP TABLE IF EXISTS ${this.tables.joborders}`,
      []
    );

    await this.databaseObj.executeSql(
      `DROP TABLE IF EXISTS ${this.tables.joborderdets}`,
      []
    );


    await this.databaseObj.executeSql(
      `DROP TABLE IF EXISTS ${this.tables.mutasis}`,
      []
    );

    await this.databaseObj.executeSql(
      `DROP TABLE IF EXISTS ${this.tables.mutasidets}`,
      []
    );

    await this.databaseObj.executeSql(
      `DROP TABLE IF EXISTS ${this.tables.stos}`,
      []
    );

    await this.databaseObj.executeSql(
      `DROP TABLE IF EXISTS ${this.tables.stodets}`,
      []
    );

    await this.databaseObj.executeSql(
      `DROP TABLE IF EXISTS ${this.tables.securities}`,
      []
    );

    
    await this.databaseObj.executeSql(
      `DROP TABLE IF EXISTS ${this.tables.whouts}`,
      []
    );

    await this.databaseObj.executeSql(
      `DROP TABLE IF EXISTS ${this.tables.whoutdets}`,
      []
    );


    await this.databaseObj.executeSql(
      `DROP TABLE IF EXISTS ${this.tables.whins}`,
      []
    );

    await this.databaseObj.executeSql(
      `DROP TABLE IF EXISTS ${this.tables.whindets}`,
      []
    );



      
  }

  async resetDatabase() {
    await this.sqlite
      .create({
        name: "ionic_sqlite_crud",
        location: "default",
      })
      .then((db: SQLiteObject) => {
        this.databaseObj = db;
      })
      .catch((e) => {
        alert("error on creating database " + JSON.stringify(e));
      });

    await this.dropAllTable();
    await this.createTables();


    // this.addDefUnit();

    // this.addDefProductGroup();

    // this.addDefPartner();

    // this.addDefProduct();


    // this.addDefBank();

    // this.addDefAccount();

    // this.addDefCategory();

    // this.addDefBranch();

    // this.addDefWh();

    // this.cekDefCompany(1);

    // this.cekDefSec(1);
    
  }



  async createDatabase() {
    await this.sqlite
      .create({
        name: "ionic_sqlite_crud",
        location: "default",
      })
      .then((db: SQLiteObject) => {
        this.databaseObj = db;
      })
      .catch((e) => {
        alert("error on creating database " + JSON.stringify(e));
      });

    await this.createTables();
    
  }

  //Set Default value

   async createTables() {

    await this.databaseObj.executeSql(
      `CREATE TABLE IF NOT EXISTS ${this.tables.settings} (id INTEGER PRIMARY KEY AUTOINCREMENT, delim char(1) null,bhs char(1) null,lastupdate Date null, lastuser number null)`,
      []
    );

    await this.databaseObj.executeSql(
      `CREATE TABLE IF NOT EXISTS ${this.tables.users} (id INTEGER PRIMARY KEY AUTOINCREMENT, kduser varchar(20) null ,nmuser varchar(50) null,address varchar(100) null,city varchar(50) null
      ,zipcode char(10) null,province varchar(50) null,phone varchar(20) null,ktp varchar(20) null,npwp varchar(15) null,email varchar(50) null, lastupdate Date null, lastuser number null)`,
      []
    );

    await this.databaseObj.executeSql(
      `CREATE TABLE IF NOT EXISTS ${this.tables.simpans} (id INTEGER PRIMARY KEY AUTOINCREMENT, nosimpan varchar(50) null,simpandate Date null,
      user_id number null,tr char(10) null,amount float null,stat char(10) null,lastupdate Date null,lastuser number null)`,
      []
    );

  
    await this.databaseObj.executeSql(
      `CREATE TABLE IF NOT EXISTS ${this.tables.securities} (id INTEGER PRIMARY KEY AUTOINCREMENT, jumlah INTEGER NOT NULL)`,
      []
    );

    await this.databaseObj.executeSql(
      `CREATE TABLE IF NOT EXISTS ${this.tables.companies} (id INTEGER PRIMARY KEY AUTOINCREMENT, name VARCHAR(50) NOT NULL, address text NULL,city VARCHAR(50) NULL,province VARCHAR(50) NULL,zipcode char(10) NULL,phone VARCHAR(20) NULL,dir VARCHAR(50) NULL,email VARCHAR(50) NULL,website VARCHAR(50) NULL,npwp VARCHAR(50) NULL,lastupdate Date null,lastuser INTEGER null)`,
      []
    );


    await this.databaseObj.executeSql(
      `CREATE TABLE IF NOT EXISTS ${this.tables.partners} (id INTEGER PRIMARY KEY AUTOINCREMENT, kdpartner VARCHAR(50) NOT NULL, nmpartner VARCHAR(50) NOT NULL, address text NULL,city VARCHAR(50) NOT NULL,province VARCHAR(50) NULL,zipcode char(10) NULL,phone VARCHAR(20) NULL,pic VARCHAR(50) NULL,tp CHAR(1) NOT NULL, term INTEGER UNSIGNED NOT NULL,ppn INTEGER UNSIGNED NULL,npwp VARCHAR(50) NOT NULL,lastupdate Date null,lastuser INTEGER null)`,
      []
    );
 
    await this.databaseObj.executeSql(
      `CREATE TABLE IF NOT EXISTS ${this.tables.belis} (id INTEGER PRIMARY KEY AUTOINCREMENT, nopo VARCHAR(20) NOT NULL,podate Date NOT NULL, vendor_id INTEGER UNSIGNED NOT NULL, stat char(10) NOT NULL, tp char(1) NOT NULL,forex_id integer not null, kurs REAL not null, jenis char(2) NOT NULL,branch_id INTEGER UNSIGNED NOT NULL,lastupdate Date null,lastuser INTEGER null)`,
      []
    
    );

    await this.databaseObj.executeSql(
      `CREATE TABLE IF NOT EXISTS ${this.tables.belidets} (id INTEGER PRIMARY KEY AUTOINCREMENT, beli_id INTEGER UNSIGNED, product_id INTEGER UNSIGNED, qty INTEGER UNSIGNED,lastupdate Date null,lastuser INTEGER null)`,
      []
    );

    await this.databaseObj.executeSql(
      `CREATE TABLE IF NOT EXISTS ${this.tables.terimas} (id INTEGER PRIMARY KEY AUTOINCREMENT, nogr VARCHAR(20) NOT NULL,grdate Date NOT NULL, noht VARCHAR(20) NULL,htdate Date NULL,grandht FLOAT NULL,paidht FLOAT NULL, 
      beli_id INTEGER UNSIGNED NOT NULL, stat char(10) NOT NULL,stinv char(10) NULL,wh_id INTEGER UNSIGNED NOT NULL,refno varchar(50) null, penerima varchar(50) null,nowhin varchar(50) null, lastupdate Date null,lastuser INTEGER null)`,
      []
    
    );

    await this.databaseObj.executeSql(
      `CREATE TABLE IF NOT EXISTS ${this.tables.terimadets} (id INTEGER PRIMARY KEY AUTOINCREMENT, terima_id INTEGER UNSIGNED, product_id INTEGER UNSIGNED, qty INTEGER UNSIGNED,harga FLOAT NULL,diskon FLOAT NULL,lastupdate Date null,lastuser INTEGER null)`,
      []
    );


    await this.databaseObj.executeSql(
      `CREATE TABLE IF NOT EXISTS ${this.tables.kirims} (id INTEGER PRIMARY KEY AUTOINCREMENT, nodo VARCHAR(20) NOT NULL,dodate Date NOT NULL, nopt VARCHAR(20) NULL,ptdate Date NULL,grandpt FLOAT NULL,paidpt FLOAT NULL, jual_id INTEGER UNSIGNED NOT NULL, stat char(10) NOT NULL,stinv char(10) NULL,wh_id integer not null,refno varchar(50) null, pengirim varchar(50) null,nowhout varchar(20) null, lastupdate Date null,lastuser INTEGER null)`,
      []
    
    );
    

    await this.databaseObj.executeSql(
      `CREATE TABLE IF NOT EXISTS ${this.tables.kirimdets} (id INTEGER PRIMARY KEY AUTOINCREMENT, kirim_id INTEGER UNSIGNED, product_id INTEGER UNSIGNED, qty INTEGER UNSIGNED,harga FLOAT NULL,diskon FLOAT NULL,lastupdate Date null,lastuser INTEGER null)`,
      []
    );

    await this.databaseObj.executeSql(
      `CREATE TABLE IF NOT EXISTS ${this.tables.units} (id INTEGER PRIMARY KEY AUTOINCREMENT, nmunit VARCHAR(255) NOT NULL,lastupdate date null,lastuser INTEGER null)`,
      []
    );

    await this.databaseObj.executeSql(
      `CREATE TABLE IF NOT EXISTS ${this.tables.grproducts} (id INTEGER PRIMARY KEY AUTOINCREMENT, nmgrup VARCHAR(255) NOT NULL,lastupdate date null,lastuser INTEGER null)`,
      []
    );


    await this.databaseObj.executeSql(
      `CREATE TABLE IF NOT EXISTS ${this.tables.products} (id INTEGER PRIMARY KEY AUTOINCREMENT, kdproduct VARCHAR(255) NOT NULL, 
      nmproduct VARCHAR(255) NOT NULL,spek VARCHAR(255) NOT NULL,qty integer not null,min integer not null, 
      hargabeli float not null, hargajual float not null,diskon float not null,diskon2 float not null, unit_id integer not null,
      grup_id INTEGER not null,categ_id INTEGER not null, wh_id INTEGER not null,uri blob null,lastupdate Date null,lastuser INTEGER null)`,
      []
    );

      
    await this.databaseObj.executeSql(
      `CREATE TABLE IF NOT EXISTS ${this.tables.forexs} (id INTEGER PRIMARY KEY AUTOINCREMENT, nmforex VARCHAR(255) NOT NULL, descforex VARCHAR(255) NOT NULL,simbol CHAR(5) NOT NULL,df VARCHAR(3) NOT NULL,kurs integer not null,lastupdate date null,lastuser INTEGER null)`,
      []
    );

    await this.databaseObj.executeSql(
      `CREATE TABLE IF NOT EXISTS ${this.tables.juals} (id INTEGER PRIMARY KEY AUTOINCREMENT, noso VARCHAR(20) NOT NULL,sodate Date NOT NULL, cust_id INTEGER UNSIGNED NOT NULL, stat char(10) NOT NULL, tp char(1) NOT NULL, forex_id integer not null, kurs REAL not null, jenis char(2) NOT NULL,branch_id integer not null,uang float null,kembali float null,lastupdate date null,lastuser INTEGER null)`,
      []

    );

    
    await this.databaseObj.executeSql(
      `CREATE TABLE IF NOT EXISTS ${this.tables.jualdets} (id INTEGER PRIMARY KEY AUTOINCREMENT, jual_id INTEGER UNSIGNED, product_id INTEGER UNSIGNED, qty INTEGER UNSIGNED,lastupdate Date null,lastuser INTEGER null)`,
      []
    );

    
    await this.databaseObj.executeSql(
      `CREATE TABLE IF NOT EXISTS ${this.tables.bkins} (id INTEGER PRIMARY KEY AUTOINCREMENT, nobkin VARCHAR(20) NOT NULL,bkindate Date NOT NULL, cust_id INTEGER UNSIGNED NOT NULL, stat char(10) NOT NULL, tp char(5) NOT NULL,pf char(10) NOT NULL, forex_id integer not null, kurs REAL not null,rek_id integer NULL,lastupdate date null,lastuser INTEGER null)`,
      []

    );

    
    await this.databaseObj.executeSql(
      `CREATE TABLE IF NOT EXISTS ${this.tables.bkindets} (id INTEGER PRIMARY KEY AUTOINCREMENT, bkin_id INTEGER UNSIGNED, kirim_id INTEGER UNSIGNED, detdesc varchar(100) null, amount float UNSIGNED,nostruk varchar(50) null,lastupdate Date null,lastuser INTEGER null)`,
      []
    );


      
    await this.databaseObj.executeSql(
      `CREATE TABLE IF NOT EXISTS ${this.tables.bkouts} (id INTEGER PRIMARY KEY AUTOINCREMENT, nobkout VARCHAR(20) NOT NULL,bkoutdate Date NOT NULL, vendor_id INTEGER UNSIGNED NOT NULL, stat char(10) NOT NULL, tp char(5) NOT NULL,pf char(10) NOT NULL, forex_id integer not null, kurs REAL not null,rek_id integer NULL,lastupdate date null,lastuser INTEGER null)`,
      []

    );

    
    await this.databaseObj.executeSql(
      `CREATE TABLE IF NOT EXISTS ${this.tables.bkoutdets} (id INTEGER PRIMARY KEY AUTOINCREMENT, bkout_id INTEGER UNSIGNED, terima_id INTEGER UNSIGNED, detdesc varchar(100) null, amount float UNSIGNED,nostruk varchar(50) null,lastupdate Date null,lastuser INTEGER null)`,
      []
    );


    await this.databaseObj.executeSql(
      `CREATE TABLE IF NOT EXISTS ${this.tables.banks} (id INTEGER PRIMARY KEY AUTOINCREMENT, nmbank VARCHAR(255) NULL,lastupdate date null,lastuser INTEGER null)`,
      []
    );
    
    await this.databaseObj.executeSql(
      `CREATE TABLE IF NOT EXISTS ${this.tables.reks} (id INTEGER PRIMARY KEY AUTOINCREMENT, norek VARCHAR(50) not NULL,bank_id integer NOT NULL,branch VARCHAR(50) not NULL,atasnama VARCHAR(70) not NULL,lastupdate date null,lastuser INTEGER null)`,
      []
    );


    await this.databaseObj.executeSql(
      `CREATE TABLE IF NOT EXISTS ${this.tables.categories} (id INTEGER PRIMARY KEY AUTOINCREMENT, nmcategory VARCHAR(255) NOT NULL,lastupdate date null,lastuser INTEGER null)`,
      []
    );

      
    await this.databaseObj.executeSql(
      `CREATE TABLE IF NOT EXISTS ${this.tables.whs} (id INTEGER PRIMARY KEY AUTOINCREMENT, kdwh VARCHAR(50) NOT NULL, nmwh VARCHAR(50) NOT NULL,branch_id INTEGER UNSIGNED,lastupdate Date null,lastuser INTEGER null)`,
      []
    );


    await this.databaseObj.executeSql(
      `CREATE TABLE IF NOT EXISTS ${this.tables.branches} (id INTEGER PRIMARY KEY AUTOINCREMENT, kdbranch VARCHAR(50) NOT NULL, nmbranch VARCHAR(50) NOT NULL, address text NULL,city VARCHAR(50) NOT NULL,province VARCHAR(50) NULL,zipcode char(10) NULL,phone VARCHAR(20) NULL,pic VARCHAR(50) NULL,lastupdate Date null,lastuser INTEGER null)`,
      []
    );


    await this.databaseObj.executeSql(
      `CREATE TABLE IF NOT EXISTS ${this.tables.joborders} (id INTEGER PRIMARY KEY AUTOINCREMENT, nojob VARCHAR(20) NOT NULL,jobdate Date NOT NULL, finishdate Date NULL,target INTEGER UNSIGNED NOT NULL,final INTEGER UNSIGNED NOT NULL, reject INTEGER UNSIGNED NOT NULL,status char(10) NOT NULL,wh_id integer not null,product_fg_id integer not null,forex_id integer,kurs FLOAT NULL,hpp float,hargajual float,cs char(3) null, lastupdate Date null,lastuser INTEGER null)`,
      []
    
    );


    await this.databaseObj.executeSql(
      `CREATE TABLE IF NOT EXISTS ${this.tables.joborderdets} (id INTEGER PRIMARY KEY AUTOINCREMENT, job_id INTEGER UNSIGNED, product_raw_id INTEGER UNSIGNED, qty DECIMAL(10,2) null, harga FLOAT NULL,lastupdate Date null,lastuser INTEGER null)`,
      []
    );


    await this.databaseObj.executeSql(
      `CREATE TABLE IF NOT EXISTS ${this.tables.mutasis} (id INTEGER PRIMARY KEY AUTOINCREMENT, nomutasi VARCHAR(20) NOT NULL,mutasidate Date NOT NULL, status char(10) NOT NULL,wh_id integer not null,wh_id2 integer not null,forex_id integer,kurs FLOAT NULL,lastupdate Date null,lastuser INTEGER null)`,
      []
    
    );


    await this.databaseObj.executeSql(
      `CREATE TABLE IF NOT EXISTS ${this.tables.mutasidets} (id INTEGER PRIMARY KEY AUTOINCREMENT, mutasi_id INTEGER UNSIGNED, product_id INTEGER UNSIGNED, qty DECIMAL(10,2) null, lastupdate Date null,lastuser INTEGER null)`,
      []
    );


    await this.databaseObj.executeSql(
      `CREATE TABLE IF NOT EXISTS ${this.tables.stos} (id INTEGER PRIMARY KEY AUTOINCREMENT, nosto VARCHAR(20) NOT NULL,stodate Date NOT NULL, status char(10) NOT NULL,wh_id integer not null,lastupdate Date null,lastuser INTEGER null)`,
      []
    
    );


    await this.databaseObj.executeSql(
      `CREATE TABLE IF NOT EXISTS ${this.tables.stodets} (id INTEGER PRIMARY KEY AUTOINCREMENT, sto_id INTEGER UNSIGNED, product_id INTEGER UNSIGNED, qty DECIMAL(10,2) not null,selisih  DECIMAL(10,2) not null, lastupdate Date null,lastuser INTEGER null)`,
      []
    );

    
    await this.databaseObj.executeSql(
      `CREATE TABLE IF NOT EXISTS ${this.tables.whouts} (id INTEGER PRIMARY KEY AUTOINCREMENT, nowhout VARCHAR(20) NOT NULL,whoutdate Date NOT NULL, stat char(10) NOT NULL,wh_id INTEGER UNSIGNED NOT NULL,refno varchar(50) null, pic varchar(50) null,dodate Date null,tr char(1) null, lastupdate Date null,lastuser INTEGER null)`,
      []
    
    );

    await this.databaseObj.executeSql(
      `CREATE TABLE IF NOT EXISTS ${this.tables.whoutdets} (id INTEGER PRIMARY KEY AUTOINCREMENT, whout_id INTEGER UNSIGNED, product_id INTEGER UNSIGNED, qty INTEGER UNSIGNED,lastupdate Date null,lastuser INTEGER null)`,
      []
    );


    await this.databaseObj.executeSql(
      `CREATE TABLE IF NOT EXISTS ${this.tables.whins} (id INTEGER PRIMARY KEY AUTOINCREMENT, nowhin VARCHAR(20) NOT NULL,whindate Date NOT NULL, stat char(10) NOT NULL,wh_id INTEGER UNSIGNED NOT NULL,refno varchar(50) null, 
      pic varchar(50) null,grdate Date null,tr char(1) null, lastupdate Date null,lastuser INTEGER null)`,
      []
    
    );

    await this.databaseObj.executeSql(
      `CREATE TABLE IF NOT EXISTS ${this.tables.whindets} (id INTEGER PRIMARY KEY AUTOINCREMENT, whin_id INTEGER UNSIGNED, product_id INTEGER UNSIGNED, qty INTEGER UNSIGNED,lastupdate Date null,lastuser INTEGER null)`,
      []
    );


       
  }


  async convertDate() {

  var date = new Date();
  var yyyy = date.getFullYear().toString();
  var mm = (date.getMonth()+1).toString();
  var dd  = date.getDate().toString();

  var mmChars = mm.split('');
  var ddChars = dd.split('');

  return yyyy + '-' + (mmChars[1]?mm:"0"+mmChars[0]) + '-' + (ddChars[1]?dd:"0"+ddChars[0]);
}



async delAll(tp) {


  if (tp=='Y'){

    await this.databaseObj.executeSql(
      `delete from settings`,
      []
    );

    await this.databaseObj.executeSql(
      `delete from users`,
      []
    );

    await this.databaseObj.executeSql(
      `delete from simpans`,
      []
    );

    
    await this.databaseObj.executeSql(
      `delete from partners`,
      []
    );


    await this.databaseObj.executeSql(
      `delete from units`,
      []
    );

    await this.databaseObj.executeSql(
      `delete from forexs`,
      []
    );

    await this.databaseObj.executeSql(
      `delete from grproducts`,
      []
    );

    await this.databaseObj.executeSql(
      `delete from products`,
      []
    );

    var msg ="All Data has been deleted!";

  }


  await this.databaseObj.executeSql(
    `delete from belis`,
    []
  );
  
  await this.databaseObj.executeSql(
    `delete from belidets`,
    []
  );

  
  await this.databaseObj.executeSql(
    `delete from juals`,
    []
  );

  await this.databaseObj.executeSql(
    `delete from jualdets`,
    []
  );

  
  await this.databaseObj.executeSql(
    `delete from terimas`,
    []
  );

  await this.databaseObj.executeSql(
    `delete from terimadets`,
    []
  ).then(() => {
    return "Deleted!";
  })


  await this.databaseObj.executeSql(
    `delete from bkins`,
    []
  );

  
  await this.databaseObj.executeSql(
    `delete from bkindets`,
    []
  );



  await this.databaseObj.executeSql(
    `delete from bkouts`,
    []
  );

  
  await this.databaseObj.executeSql(
    `delete from bkoutdets`,
    []
  );

 
  await this.databaseObj.executeSql(
    `delete from reks`,
    []
  ).then(() => {
    return "Deleted!";
  })

  await this.databaseObj.executeSql(
    `delete from banks`,
    []
  ).then(() => {
    return "Deleted!";
  })
  
  await this.databaseObj.executeSql(
    `delete from categories`,
    []
  ).then(() => {
    return "Deleted!";
  })

  await this.databaseObj.executeSql(
    `delete from whs`,
    []
  ).then(() => {
    return "Deleted!";
  })

  await this.databaseObj.executeSql(
    `delete from branches`,
    []
  ).then(() => {
    return "Deleted!";
  })

  await this.databaseObj.executeSql(
    `delete from whouts`,
    []
  );

  
  await this.databaseObj.executeSql(
    `delete from whoutdets`,
    []
  );


  await this.databaseObj.executeSql(
    `delete from whins`,
    []
  );

  
  await this.databaseObj.executeSql(
    `delete from whindets`,
    []
  );


}



async cekMaster(){

  return this.databaseObj
  .executeSql(
    `SELECT id,name,address,city,phone,zipcode FROM ${this.tables.companies} 
    where id= 1`,
    []
  )
  .then((res) => {

        
    if (res.rows.length>0){
       
    }
    else{

      this.addDefUnit();
      this.addDefProductGroup();
      this.addDefPartner();
      this.addDefProduct();
      this.addDefBank();
      this.addDefAccount();
      this.addDefCategory();
      this.addDefBranch();
      this.addDefWh();
      this.cekDefCompany(1);
      this.cekDefSec(1);
      this.cekDefForex(1);

      this.addDefUser();
      this.addDefSetting();
         
    }
   
    
  })
  .catch((e) => {
    return "error on getting partner " + JSON.stringify(e);
  });

  

}


async getDefCompany(id:number) {

  var now= await this.convertDate();

  return this.databaseObj
    .executeSql(
      `SELECT id,name,address,city,phone,zipcode FROM ${this.tables.companies} 
      where id= ${id}`,
      []
    )
    .then((res) => {

          
      if (res.rows.length>0){

        return {
          name: res.rows.item(0).name,
          address: res.rows.item(0).address,
          city: res.rows.item(0).city,
          phone: res.rows.item(0).phone,
          zipcode: res.rows.item(0).zipcode,
        }
         
      }
     
      
    })
    .catch((e) => {
      return "error on getting partner " + JSON.stringify(e);
    });
}


async cekDefSec(id) {

  var now= await this.convertDate();

  return this.databaseObj
    .executeSql(
      `SELECT id,jumlah FROM ${this.tables.securities} 
      where id='${id}'
      `,
      []
    )
    .then((res) => {

          
      if (res.rows.length>0){

      }
      else{

        this.databaseObj
        .executeSql(
          `INSERT INTO ${this.tables.securities} (id,jumlah) 
          VALUES (1,0)`,
          []
        )

        

      }

      
    })
    .catch((e) => {
      return "error on getting partner " + JSON.stringify(e);
    });
}


async addDefSetting() {

  var now= await this.convertDate();

  return this.databaseObj
    .executeSql(
      `SELECT delim FROM ${this.tables.settings}`,
      []
    )
    .then((res) => {

          
      if (res.rows.length>0){

      }
      else{

        this.databaseObj
        .executeSql(
          `INSERT INTO ${this.tables.settings} (id,delim,bhs,lastupdate,lastuser) 
          VALUES (1,';','1','${now}',1)`,
          []
        )

        

      }

      
    })
    .catch((e) => {
      return "error on getting partner " + JSON.stringify(e);
    });
}


async cekDefCompany(id:number) {

  var now= await this.convertDate();

  return this.databaseObj
    .executeSql(
      `SELECT id,name FROM ${this.tables.companies} 
      where id= ${id}`,
      []
    )
    .then((res) => {

      if (res.rows.length>0){

      }
      else{

        this.databaseObj
        .executeSql(
          `INSERT INTO ${this.tables.companies} (name,address,city,province,zipcode,phone,dir,email,website,npwp,lastupdate,lastuser) 
          VALUES ('PT. RealBiz','Jl. Contoh No.1','Kota Contoh','Provinsi Contoh','123456','021-1234567','Mr. Contoh','contoh-email@gmail.com','www.contoh-web.com','123456789','${now}','1')`,
          []
        )

        

      }

      
    })
    .catch((e) => {
      return "error on getting partner " + JSON.stringify(e);
    });
}



async cekDefForex(id:number) {

  var now= await this.convertDate();

  return this.databaseObj
    .executeSql(
      `SELECT id FROM ${this.tables.forexs} 
      where id= ${id}`,
      []
    )
    .then((res) => {
     
      if (res.rows.length>0){

         
         
      }
      else{

        return this.databaseObj
        .executeSql(
          `INSERT INTO ${this.tables.forexs} (nmforex,descforex,simbol,df,kurs,lastupdate,lastuser) VALUES ('IDR','Indonesian Rupiah','Rp','Yes',1,'${now}','1')`,
          []
          
        )

      }

      
    })
    .catch((e) => {
      return "error on getting partner " + JSON.stringify(e);
    });
}





async addDefBank() {

  var now= await this.convertDate();

  return this.databaseObj
    .executeSql(
      `SELECT id FROM ${this.tables.banks} 
      where id=3`,
      []
    )
    .then((res) => {
     
      if (res.rows.length>0){

         
         
      }
      else{

        return this.databaseObj
        .executeSql(
          `INSERT INTO ${this.tables.banks} (nmbank,lastupdate,lastuser) VALUES ('Bank BNI','${now}','1'), 
          ('Bank Mandiri','${now}','1'), ('Bank BSI','${now}','1')`,
          []
          
        )

      }

      
    })
    .catch((e) => {
      return "error on getting banks " + JSON.stringify(e);
    });
}


async addDefUser() {

  var now= await this.convertDate();

  return this.databaseObj
    .executeSql(
      `SELECT id FROM ${this.tables.users} 
      where id=3`,
      []
    )
    .then((res) => {
     
      if (res.rows.length>0){

         
         
      }
      else{

        return this.databaseObj
        .executeSql(
          `INSERT INTO ${this.tables.users} (id,kduser,nmuser,address,city,province,zipcode,phone,email,ktp,npwp,lastupdate,lastuser) 
          VALUES (1,'M0001','Other','-','-','-','-','1','-','345411','0.4935-0991','${now}','1'),
          (2,'M0002','Surya Jaya','Jl. Nuri No.23','Cianjur','Jawa Barat','43217','0251-3573848','-','345411','0.4935-0991','${now}','1'),
          (3,'M0003','Hadi Jaya','Jl. Nangka No.23','Bandung','Jawa Barat','43217','0251-3573848','-','345511','0.4935-0991','${now}','1') `,
          []
          
        )

      }

      
    })
    .catch((e) => {
      return "error on getting User " + JSON.stringify(e);
    });
}

async addDefAccount() {

  var now= await this.convertDate();

  return this.databaseObj
    .executeSql(
      `SELECT id FROM ${this.tables.reks} 
      where id=3`,
      []
    )
    .then((res) => {
     
      if (res.rows.length>0){

         
         
      }
      else{

    
        return this.databaseObj
        .executeSql(
          `INSERT INTO ${this.tables.reks} (norek,bank_id,branch,atasnama,lastupdate,lastuser) VALUES ('455453545',1,'Depok','Iman','${now}','1'), 
          ('1132333322',2,'Depok','Budi','${now}','1'), ('546444453533',3,'Depok','Wendi','${now}','1')`,
          []
          
        )

      }

      
    })
    .catch((e) => {
      return "error on getting Reks " + JSON.stringify(e);
    });
}



async addDefPartner() {

  var now= await this.convertDate();

  return this.databaseObj
    .executeSql(
      `SELECT id FROM ${this.tables.partners} 
      where id=1`,
      []
    )
    .then((res) => {
     
      if (res.rows.length>0){

         
         
      }
      else{

        
        return this.databaseObj
        .executeSql(
          `INSERT INTO ${this.tables.partners} (id,kdpartner,nmpartner,address,city,province,zipcode,phone,pic,tp,term,ppn,npwp,lastupdate,lastuser) 
          VALUES (2,'V0001','Other','-','-','-','-','-','-','1',0,0,'-','${now}','1'),
          (4,'V0002','PD Service Jaya','Jl. Nuri No.23','Cianjur','Jawa Barat','43217','0251-3573848','Yusman','1',30,11,'0.4935-0991','${now}','1'),
          (5,'V0003','PD Prima Jaya','Jl. Nangka No.23','Bandung','Jawa Barat','43217','0251-3573848','Yusman','1',30,11,'0.4935-0991','${now}','1'),
          (6,'V0004','PD Ultra Jaya','Jl. Apel No.23','Sukabumi','Jawa Barat','43217','0251-3573848','Yusman','1',30,11,'0.4935-0991','${now}','1'),
          (1,'C0001','POS Customer','-','-','-','-','-','-','2',30,11,'-','${now}','1'),
          (3,'C0002','Other','-','-','-','-','-','-','2',0,0,'-','${now}','1'),
          (7,'C0003','UD Juliana Jaya','Jl. Pelangi No.23','Ciamis','Jawa Barat','43217','0251-3573848','Yusman','2',30,11,'0.4935-0991','${now}','1'),
          (8,'C0004','UD H. Dudung','Jl. Durian No.23','Ciamis','Jawa Barat','43217','0251-3573848','Yusman','2',30,11,'0.4935-0991','${now}','1'),
          (9,'C0005','UD Mulia Lestari','Jl. Mangga No.23','Ciamis','Jawa Barat','43217','0251-3573848','Yusman','2',30,11,'0.4935-0991','${now}','1') `,
          []
          
        )

      }

      
    })
    .catch((e) => {
      return "error on getting partner " + JSON.stringify(e);
    });
}


async addDefProductGroup() {

  var now= await this.convertDate();

  return this.databaseObj
    .executeSql(
      `SELECT id FROM ${this.tables.grproducts} 
      where id=5`,
      []
    )
    .then((res) => {
     
      if (res.rows.length>0){

         
         
      }
      else{

        return this.databaseObj
        .executeSql(
          `INSERT INTO ${this.tables.grproducts} (nmgrup,lastupdate,lastuser) VALUES ('Bahan Pokok','${now}','1'), 
          ('Makanan Ringan','${now}','1'), ('Bumbu','${now}','1'), ('Aneka Mie','${now}','1'), ('Minuman','${now}','1') `,
          []
          
        )

      }

      
    })
    .catch((e) => {
      return "error on getting partner " + JSON.stringify(e);
    });
}



async addDefProduct() {

  var now= await this.convertDate();

  return this.databaseObj
    .executeSql(
      `SELECT id FROM ${this.tables.partners} 
      where id=2`,
      []
    )
    .then((res) => {
     
      if (res.rows.length>0){
         
      }
      else{

        
        return this.databaseObj
        .executeSql(
          `INSERT INTO ${this.tables.products} (kdproduct,nmproduct,spek,qty,min,hargabeli,hargajual,diskon,diskon2,unit_id,grup_id,categ_id,wh_id,lastupdate,lastuser) 
          VALUES ('MIE1234','Indomie Ayam Bawang','Kemasan 100 mg','24','5','1200','1300','0','0','2','4'
          ,'1',1,'${now}','1'), ('MIE3334','Indomie Goreng Special','Kemasan 100 mg','24','5','1200','1300','0','0','2','4'
          ,'1',1,'${now}','1'), ('BHP0133','Terigu Segitiga Biru','Kemasan 1000 mg','24','5','12000','13000','0','0','2','1'
          ,'1',1,'${now}','1'), ('BLB5340','Margarin Blue Band','Kemasan 1000 mg','104','5','12000','13000','0','0','2','1'
          ,'1',1,'${now}','1'),('BRS5340','Beras Ramos','Kemasan 2000 mg','104','5','12000','13000','0','0','2','1'
          ,'1',1,'${now}','1') `,
          []
          
        )

      }

      
    })
    .catch((e) => {
      return "error on getting products " + JSON.stringify(e);
    });
}




async addDefUnit() {

  var now= await this.convertDate();

  return this.databaseObj
    .executeSql(
      `SELECT id FROM ${this.tables.units} 
      where id=3`,
      []
    )
    .then((res) => {
     
      if (res.rows.length>0){

         
         
      }
      else{

        return this.databaseObj
        .executeSql(
          `INSERT INTO ${this.tables.units} (nmunit,lastupdate,lastuser) VALUES ('Kg (Kilogram)','${now}','1'), 
          ('Pcs (Pieces)','${now}','1'), ('Dz (Dozen)','${now}','1') `,
          []
          
        )

      }

      
    })
    .catch((e) => {
      return "error on getting Unit " + JSON.stringify(e);
    });
}


async addDefCategory() {

  var now= await this.convertDate();

  return this.databaseObj
    .executeSql(
      `SELECT id FROM ${this.tables.categories} 
      where id=3`,
      []
    )
    .then((res) => {
     
      if (res.rows.length>0){

         
         
      }
      else{

        return this.databaseObj
        .executeSql(
          `INSERT INTO ${this.tables.categories} (id,nmcategory,lastupdate,lastuser) VALUES (1,'Finished Goods','${now}','1'), 
          (2,'Services','${now}','1'), (3,'Raw Material','${now}','1') `,
          []
          
        )

      }

      
    })
    .catch((e) => {
      return "error on getting Categories " + JSON.stringify(e);
    });
}


async addDefWh() {

  var now= await this.convertDate();


  return this.databaseObj
    .executeSql(
      `SELECT id FROM ${this.tables.whs} 
      where id=2`,
      []
    )
    .then((res) => {
     
      if (res.rows.length>0){

         
         
      }
      else{

      

        return this.databaseObj
        .executeSql(
          `INSERT INTO ${this.tables.whs} (kdwh,nmwh,branch_id,lastupdate,lastuser) VALUES ('W0001','WH Pusat','1','${now}','1')`,
          []
          
        )

      }

      
    })
    .catch((e) => {
      return "error on getting Wh " + JSON.stringify(e);
    });
}


async addDefBranch() {

  var now= await this.convertDate();

  return this.databaseObj
    .executeSql(
      `SELECT id FROM ${this.tables.branches} 
      where id=2`,
      []
    )
    .then((res) => {
     
      if (res.rows.length>0){

         
         
      }
      else{

        
        return this.databaseObj
        .executeSql(
          `INSERT INTO ${this.tables.branches} (kdbranch,nmbranch,address,city,province,zipcode,phone,pic,lastupdate,lastuser) 
          VALUES ('B0001','Cabang Utama','Jl. Angkasa No.23','Bogor','Jawa Barat','43217','0251-3573848','Yusman','${now}','1')`,
          []
          
        )

      }

      
    })
    .catch((e) => {
      return "error on getting partner " + JSON.stringify(e);
    });
}

async getSetting() {
  return this.databaseObj.executeSql(`SELECT delim,bhs   
  FROM settings `, []).then(res => { 

    
      return {
        delim: res.rows.item(0).delim,
        bhs: res.rows.item(0).bhs,
      }
    

   
  });
}

async cekCount() {
  return this.databaseObj.executeSql(`SELECT jumlah  
  FROM securities `, []).then(res => { 
    return {
      jumlah: res.rows.item(0).jumlah,
    }
  });
}

//Beli-Det


async cekProductAddedSto(sto_id:number,product_id:number) {
  return this.databaseObj
    .executeSql(
      `SELECT product_id FROM ${this.tables.stodets} 
      where sto_id= ${sto_id} and product_id= ${product_id}`,
      []
    )
    .then((res) => {
     
      if (res.rows.item(0).product_id==null){
          return "Y";
      }
      else{
          return "N";
      }
      
    })
    .catch((e) => {
      return "error on getting partner " + JSON.stringify(e);
    });
}

async cekProductAddedMutasi(mutasi_id:number,product_id:number) {
  return this.databaseObj
    .executeSql(
      `SELECT product_id FROM ${this.tables.mutasidets} 
      where mutasi_id= ${mutasi_id} and product_id= ${product_id}`,
      []
    )
    .then((res) => {
     
      if (res.rows.item(0).product_id==null){
          return "Y";
      }
      else{
          return "N";
      }
      
    })
    .catch((e) => {
      return "error on getting partner " + JSON.stringify(e);
    });
}


async cekProductAddedBeli(beli_id:number,product_id:number) {
  return this.databaseObj
    .executeSql(
      `SELECT product_id FROM ${this.tables.belidets} 
      where beli_id= ${beli_id} and product_id= ${product_id}`,
      []
    )
    .then((res) => {
     
      if (res.rows.item(0).product_id==null){
          return "Y";
      }
      else{
          return "N";
      }
      
    })
    .catch((e) => {
      return "error on getting partner " + JSON.stringify(e);
    });
}


async addBelidet(beli_id: number,product_id: number,qty:number) {

  var cek= await this.cekProductAddedBeli(beli_id,product_id);


  if (cek=='N'){

    return "N";
  }
  else{

  var now= await this.convertDate();
  return this.databaseObj
    .executeSql(
      `INSERT INTO ${this.tables.belidets} (beli_id,product_id,qty,lastupdate,lastuser) VALUES ('${beli_id}','${product_id}','${qty}','${now}','1')`,
      []
    )
    .then(() => {
      return "Y";
    })
    .catch((e) => {
      if (e.code === 6) {
        return "Product is already exists";
      }

      return "error on Added Product " + JSON.stringify(e);
    });

  }
}


async editSec() {
  return this.databaseObj
    .executeSql(
      `UPDATE ${this.tables.securities} SET jumlah = jumlah + 1 WHERE id = 1`,
      []
    )
    .then(() => {
      return "Detail updated";
    })
    .catch((e) => {
      if (e.code === 6) {
        return "Detail already exist";
      }

      return "error on updating Detail" + JSON.stringify(e);
    });
}



async editBelidet(product_id: number,qty: number, id: number) {
  return this.databaseObj
    .executeSql(
      `UPDATE ${this.tables.belidets} SET product_id = ${product_id},qty = ${qty} WHERE id = ${id}`,
      []
    )
    .then(() => {
      return "Detail updated";
    })
    .catch((e) => {
      if (e.code === 6) {
        return "Detail already exist";
      }

      return "error on updating Detail" + JSON.stringify(e);
    });
}


async addBkoutdetCash(bkout_id: number,terima_id: number,detdesc: string,nostruk: string) {


  var now= await this.convertDate();
  var amount= await this.grandHT(terima_id);

  return this.databaseObj
    .executeSql(
      `INSERT INTO ${this.tables.bkoutdets} (bkout_id,terima_id,amount,detdesc,nostruk,lastupdate,lastuser) VALUES ('${bkout_id}','${terima_id}','${amount}','${detdesc}','${nostruk}','${now}','1')`,
      []
    )
    .then(() => {
      return "Y";
    })
    .catch((e) => {
      if (e.code === 6) {
        return "Invoice is already exists";
      }

      return "error on Added Invoice " + JSON.stringify(e);
    });

  
}

async addBkindetCash(bkin_id: number,kirim_id: number,detdesc: string,nostruk: string) {


  var now= await this.convertDate();
  var amount= await this.grandPT(kirim_id);

  return this.databaseObj
    .executeSql(
      `INSERT INTO ${this.tables.bkindets} (bkin_id,kirim_id,amount,detdesc,nostruk,lastupdate,lastuser) VALUES ('${bkin_id}','${kirim_id}','${amount}','${detdesc}','${nostruk}','${now}','1')`,
      []
    )
    .then(() => {
      return "Y";
    })
    .catch((e) => {
      if (e.code === 6) {
        return "Invoice is already exists";
      }

      return "error on Added Invoice " + JSON.stringify(e);
    });

  
}


async addBkoutdetJob(nobkout: string,detdesc: string,nostruk: string,amount: number) {


  var now= await this.convertDate();
  var bkout_id= await this.getBkoutID(nobkout);

  var sQuery=`INSERT INTO ${this.tables.bkoutdets} (bkout_id,terima_id,amount,detdesc,nostruk,lastupdate,lastuser) 
  VALUES ('${bkout_id}',0,${amount},'${detdesc}','${nostruk}','${now}','1')`;

  return this.databaseObj
    .executeSql(sQuery
      ,
      []
    )
    .then(() => {
      return sQuery;
    })
    .catch((e) => {
      if (e.code === 6) {
        return "Invoice is already exists";
      }

      return "error on Added Invoice " + JSON.stringify(e);
    });

  
}


async addBkindetCashPos(bkin_id: number,detdesc: string,nostruk: string, amount: number) {


  var now= await this.convertDate();

  return this.databaseObj
    .executeSql(
      `INSERT INTO ${this.tables.bkindets} (bkin_id,kirim_id,amount,detdesc,nostruk,lastupdate,lastuser) VALUES ('${bkin_id}','0','${amount}','${detdesc}','${nostruk}','${now}','1')`,
      []
    )
    .then(() => {
      return "Y";
    })
    .catch((e) => {
      if (e.code === 6) {
        return "Invoice is already exists";
      }

      return "error on Added Invoice " + JSON.stringify(e);
    });

  
}

async addBkoutdet(bkout_id: number,terima_id: number,amount:number,detdesc: string,nostruk: string,pf: string) {

  if (pf=='Expense'){
    var cek='Y';
  }
  else{
    var cek= await this.cekHTAddedBkout(bkout_id,terima_id);

  }
  

  if (cek=='N'){

    return "N";
  }
  else{

  var now= await this.convertDate();
  return this.databaseObj
    .executeSql(
      `INSERT INTO ${this.tables.bkoutdets} (bkout_id,terima_id,amount,detdesc,nostruk,lastupdate,lastuser) VALUES ('${bkout_id}','${terima_id}','${amount}','${detdesc}','${nostruk}','${now}','1')`,
      []
    )
    .then(() => {
      return "Y";
    })
    .catch((e) => {
      if (e.code === 6) {
        return "Invoice is already exists";
      }

      return "error on Added Invoice " + JSON.stringify(e);
    });

  }
}


async addBkindet(bkin_id: number,kirim_id: number,amount:number,detdesc: string,nostruk: string,pf: string) {

  if (pf=='Expense'){
    var cek='Y';
  }
  else{
    var cek= await this.cekHTAddedBkout(bkin_id,kirim_id);

  }
  

  if (cek=='N'){

    return "N";
  }
  else{

  var now= await this.convertDate();
  return this.databaseObj
    .executeSql(
      `INSERT INTO ${this.tables.bkindets} (bkin_id,kirim_id,amount,detdesc,nostruk,lastupdate,lastuser) VALUES ('${bkin_id}','${kirim_id}','${amount}','${detdesc}','${nostruk}','${now}','1')`,
      []
    )
    .then(() => {
      return "Y";
    })
    .catch((e) => {
      if (e.code === 6) {
        return "Invoice is already exists";
      }

      return "error on Added Invoice " + JSON.stringify(e);
    });

  }
}


async addJoborderdet(job_id: number,product_raw_id: number,qty:number,harga: number) {


  var cek= await this.cekProductAddedJoborder(job_id,product_raw_id);


  if (cek=='N'){

    return "N";
  }
  else{

  var now= await this.convertDate();
  return this.databaseObj
    .executeSql(
      `INSERT INTO ${this.tables.joborderdets} (job_id,product_raw_id,qty,harga,lastupdate,lastuser) 
      VALUES ('${job_id}','${product_raw_id}','${qty}','${harga}','${now}','1')`,
      []
    )
    .then(() => {
      return "Y";
    })
    .catch((e) => {
      if (e.code === 6) {
        return "Invoice is already exists";
      }

      return "error on Added Invoice " + JSON.stringify(e);
    });
  
  }

}


async addMutasidet(mutasi_id: number,product_id: number,qty:number) {


  var cek= await this.cekProductAddedMutasi(mutasi_id,product_id);


  if (cek=='N'){

    return "N";
  }
  else{

  var now= await this.convertDate();
  return this.databaseObj
    .executeSql(
      `INSERT INTO ${this.tables.mutasidets} (mutasi_id,product_id,qty,lastupdate,lastuser) 
      VALUES ('${mutasi_id}','${product_id}','${qty}','${now}','1')`,
      []
    )
    .then(() => {
      return "Y";
    })
    .catch((e) => {
      if (e.code === 6) {
        return "Invoice is already exists";
      }

      return "error on Added Invoice " + JSON.stringify(e);
    });

  }

}


async addStodet(sto_id: number,product_id: number,qty:number) {

  var cek= await this.cekProductAddedSto(sto_id,product_id);


  if (cek=='N'){

    return "N";
  }
  else{

  var now= await this.convertDate();
  return this.databaseObj
    .executeSql(
      `INSERT INTO ${this.tables.stodets} (sto_id,product_id,qty,selisih,lastupdate,lastuser) 
      VALUES ('${sto_id}','${product_id}','${qty}',0,'${now}','1')`,
      []
    )
    .then(() => {
      return "Y";
    })
    .catch((e) => {
      if (e.code === 6) {
        return "Invoice is already exists";
      }

      return "error on Added Invoice " + JSON.stringify(e);
    });

  }

}


async editBkoutdet(terima_id: number,amount: number,detdesc: string, id: number,nostruk: string) {
  return this.databaseObj
    .executeSql(
      `UPDATE ${this.tables.bkoutdets} SET terima_id = ${terima_id},amount = ${amount},detdesc = '${detdesc}',nostruk = '${nostruk}' WHERE id = ${id}`,
      []
    )
    .then(() => {
      return "Detail updated";
    })
    .catch((e) => {
      if (e.code === 6) {
        return "Detail already exist";
      }

      return "error on updating Detail" + JSON.stringify(e);
    });
}


async editJoborderdet(id: number, product_raw_id: number, qty: number,harga: number) {
  return this.databaseObj
    .executeSql(
      `UPDATE ${this.tables.joborderdets} SET product_raw_id = ${product_raw_id},qty = ${qty},harga = '${harga}' WHERE id = ${id}`,
      []
    )
    .then(() => {
      return "Detail updated";
    })
    .catch((e) => {
      if (e.code === 6) {
        return "Detail already exist";
      }

      return "error on updating Detail" + JSON.stringify(e);
    });
}


async editMutasidet(id: number, product_id: number, qty: number) {
  return this.databaseObj
    .executeSql(
      `UPDATE ${this.tables.mutasidets} SET product_id = ${product_id},qty = ${qty} WHERE id = ${id}`,
      []
    )
    .then(() => {
      return "Detail updated";
    })
    .catch((e) => {
      if (e.code === 6) {
        return "Detail already exist";
      }

      return "error on updating Detail" + JSON.stringify(e);
    });
}


async editStodet(id: number, product_id: number, qty: number) {
  return this.databaseObj
    .executeSql(
      `UPDATE ${this.tables.stodets} SET product_id = ${product_id},qty = ${qty} WHERE id = ${id}`,
      []
    )
    .then(() => {
      return "Detail updated";
    })
    .catch((e) => {
      if (e.code === 6) {
        return "Detail already exist";
      }

      return "error on updating Detail" + JSON.stringify(e);
    });
}



async editBkindet(terima_id: number,amount: number,detdesc: string, id: number,nostruk: string) {
  return this.databaseObj
    .executeSql(
      `UPDATE ${this.tables.bkindets} SET kirim_id = ${terima_id},amount = ${amount},detdesc = '${detdesc}',nostruk = '${nostruk}' WHERE id = ${id}`,
      []
    )
    .then(() => {
      return "Detail updated";
    })
    .catch((e) => {
      if (e.code === 6) {
        return "Detail already exist";
      }

      return "error on updating Detail" + JSON.stringify(e);
    });
}


async plusHutang(id: number, amount: number) {
  return this.databaseObj
    .executeSql(`update ${this.tables.terimas} set paidht=paidht-${amount}  WHERE id = ${id}`, [])
    .then(() => {
      return "Payable was paid";
    })
    .catch((e) => {
      return "error on Update Payable " + JSON.stringify(e);
    });
}

async plusPiutang(id: number, amount: number) {
  return this.databaseObj
    .executeSql(`update ${this.tables.kirims} set paidpt=paidpt-${amount}  WHERE id = ${id}`, [])
    .then(() => {
      return "Receivable was paid";
    })
    .catch((e) => {
      return "error on Update Receivable " + JSON.stringify(e);
    });
}


async minusHutang(id: number, amount: number) {
  return this.databaseObj
    .executeSql(`update ${this.tables.terimas} set paidht=paidht+${amount}  WHERE id = ${id}`, [])
    .then(() => {
      return "Payable was paid";
    })
    .catch((e) => {
      return "error on Update Payable " + JSON.stringify(e);
    });
}

async minusPiutang(id: number, amount: number) {
  return this.databaseObj
    .executeSql(`update ${this.tables.kirims} set paidpt=paidpt+${amount}  WHERE id = ${id}`, [])
    .then(() => {
      return "Receivable was paid";
    })
    .catch((e) => {
      return "error on Update Receivable " + JSON.stringify(e);
    });
}


async moveStock(wh_id: number,wh_id2:number,kdproduct:string, qty: number,tp: string) {

  await this.updateStockFrom(wh_id,kdproduct,qty,tp);

  if (tp=='1'){

    return this.databaseObj
    .executeSql(`update ${this.tables.products} set qty=qty+${qty}  WHERE kdproduct='${kdproduct}' and wh_id = ${wh_id2}`, [])
    .then(() => {
      return "Product Moved";
    })
    .catch((e) => {
      return "error on Move Product " + JSON.stringify(e);
    });

  }
  else{

    return this.databaseObj
    .executeSql(`update ${this.tables.products} set qty=qty-${qty}  WHERE kdproduct='${kdproduct}' and wh_id = ${wh_id2}`, [])
    .then(() => {
      return "Product Moved";
    })
    .catch((e) => {
      return "error on Move Product " + JSON.stringify(e);
    });

  }

  
}

async updateStockFrom(wh_id: number,kdproduct:string, qty: number,tp: string) {

  if (tp=='1'){

    return this.databaseObj
    .executeSql(`update ${this.tables.products} set qty=qty-${qty}  WHERE kdproduct='${kdproduct}' and wh_id = ${wh_id}`, [])
    .then(() => {
      return "Product Moved";
    })
    .catch((e) => {
      return "error on Move Product " + JSON.stringify(e);
    });

  }
  else{

    return this.databaseObj
    .executeSql(`update ${this.tables.products} set qty=qty+${qty}  WHERE kdproduct='${kdproduct}' and wh_id = ${wh_id}`, [])
    .then(() => {
      return "Product Moved";
    })
    .catch((e) => {
      return "error on Move Product " + JSON.stringify(e);
    });

  }
}


async minusStock(id: number, qty: number) {
  return this.databaseObj
    .executeSql(`update ${this.tables.products} set qty=qty-${qty}  WHERE id = ${id} and categ_id<>2`, [])
    .then(() => {
      return "Product reverted";
    })
    .catch((e) => {
      return "error on Update Product " + JSON.stringify(e);
    });
}

async proDiff(id: number, product_id: number, qty: number) {

  var exist = await this.getSelisih(product_id);

  var selisih = exist-qty;

  return this.databaseObj
    .executeSql(`update ${this.tables.stodets} set selisih=${selisih}  WHERE id = ${id}`, [])
    .then(() => {
      return selisih;
    })
    .catch((e) => {
      return "error on Update Product " + JSON.stringify(e);
    });
}


async plusStockJob(id: number, qty: number, hrgjual: number,hrgbeli: number) {

  var sQuery=`update ${this.tables.products} set qty=qty+${qty},hargabeli=${hrgbeli},hargajual=${hrgjual}  WHERE id = ${id} and categ_id<>2`;
  return this.databaseObj
    .executeSql(sQuery, [])
    .then(() => {
      return sQuery;
    })
    .catch((e) => {
      return "error on Update Product " + JSON.stringify(e);
    });
}

async addBkoutJobcost() {

  var nobkout=  await this.maxBkout();
  var now= await this.convertDate();
  var bkoutdate= await this.convertDate();

        
        return this.databaseObj
        .executeSql(
          `INSERT INTO ${this.tables.bkouts} (nobkout,bkoutdate,vendor_id,stat,tp,pf,forex_id,kurs,rek_id,lastupdate,lastuser) 
          VALUES ('${nobkout}','${bkoutdate}', 2,'Approved','Cash','Expense',1,1,0,'${now}','1')`,
          []
          
        )
        .then(() => {
          return nobkout;
        })
        .catch((e) => {
          return "error on creating Transaction " + JSON.stringify(e);
        });

                  
  
 }



async plusStock(id: number, qty: number) {

  var sQ =`update ${this.tables.products} set qty=qty+${qty}  WHERE id = ${id} and categ_id<>2 `;
  return this.databaseObj
    .executeSql(sQ, [])
    .then(() => {
      return sQ;
    })
    .catch((e) => {
      return "error on Update Product " + JSON.stringify(e);
    });
}


//Product
async addProduct(kdproduct: string,nmproduct: string,spek: string,qty: number,min: number,hargabeli: number,
    hargajual: number,diskon: number,diskon2: number,unit_id: number,grup_id: number,categ_id: number,wh_id: number,uri: any) {


    var now= await this.convertDate();
    return this.databaseObj
      .executeSql(
        `INSERT INTO ${this.tables.products} (kdproduct,nmproduct,spek,qty,min,hargabeli,hargajual,diskon,diskon2,unit_id,grup_id,categ_id,wh_id,lastupdate,lastuser) 
        VALUES ('${kdproduct}','${nmproduct}','${spek}','${qty}','${min}','${hargabeli}','${hargajual}','${diskon}','${diskon2}','${unit_id}','${grup_id}'
        ,'${categ_id}','${wh_id}','${uri}','${now}','1')`,
        []
      )

      
      .then(() => {
        return "Product created";
      })
      .catch((e) => {
        if (e.code === 6) {
          return "Product already exists";
        }

        return "error on creating Product Group " + JSON.stringify(e);
      });
  }


  // await this.databaseObj.executeSql(
  //   `CREATE TABLE IF NOT EXISTS ${this.tables.products} (id INTEGER PRIMARY KEY AUTOINCREMENT, kdproduct VARCHAR(255) NOT NULL, 
  //   nmproduct VARCHAR(255) NOT NULL,spek VARCHAR(255) NOT NULL,qty integer not null,min integer not null, 
  //   hargabeli float not null, hargajual float not null,diskon float not null,diskon2 float not null, unit_id integer not null,
  //   grup_id INTEGER not null,categ_id INTEGER not null, wh_id INTEGER not null,lastupdate Date null,lastuser INTEGER null)`,
  //   []
  // );

  async rptProducts() {
    return this.databaseObj
      .executeSql(
        `SELECT a.kdproduct as [ID Product] ,a.nmproduct as [Product Name],a.spek as Spec,d.nmunit as Unit,e.nmgrup as [Product Group],
        a.hargabeli as [Buy Price],a.diskon as [Buy Discount],a.hargajual as [Sell Price],
        a.diskon2 as [Sell Discount],a.qty as Qty,a.min as [Min stock],b.nmwh as Warehouse,
        c.nmcategory as Category
        FROM ${this.tables.products} a 
        left join whs b on a.wh_id=b.id
        left join categories c on a.categ_id=c.id
        left join units d on a.unit_id=d.id
        left join grproducts e on a.grup_id=e.id
        ORDER BY a.id DESC`,
        []
      )
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return "error on getting Product " + JSON.stringify(e);
      });
  }

  

  async getProducts() {
    return this.databaseObj
      .executeSql(
        `SELECT a.*, '[' || kdproduct || '] ' || nmproduct || ' ' || spek 
        as nmfull,b.nmwh,c.nmunit  FROM ${this.tables.products} a left join whs b on a.wh_id=b.id
        left join units c on a.unit_id=c.id
        ORDER BY a.id DESC`,
        []
      )
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return "error on getting Product " + JSON.stringify(e);
      });
  }


  async getProductWhs(id: number, tp: string) {

    var wh_id: any;

    if (tp=='W'){
      wh_id=id;
    }
    else{
      wh_id = await this.getWhID(id);
    }

    return this.databaseObj
      .executeSql(
        `SELECT a.*, '[' || kdproduct || '] ' || nmproduct || ' ' || spek 
        as nmfull,b.nmwh  FROM ${this.tables.products} a left join whs b on a.wh_id=b.id
        where a.wh_id='${wh_id}'
        ORDER BY a.id DESC`,
        []
      )
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return "error on getting Product " + JSON.stringify(e);
      });
  }


  async deleteProduct(id: number) {

    var cek= await this.cekProduct(id);
    var cek2= await this.cekProduct2(id);
    var cek3= await this.cekProduct3(id);
    var cek4= await this.cekProduct4(id);
  
    if (cek=='N' || cek2=='N'  || cek3=='N'  || cek4=='N'){
  
      return "errMsg: Record can't be deleted, this Product is still being used in another transaction!";
  
    }
    else{


    return this.databaseObj
      .executeSql(`DELETE FROM ${this.tables.products} WHERE id = ${id}`, [])
      .then(() => {
        return "Product was deleted";
      })
      .catch((e) => {
        return "error on deleting Product " + JSON.stringify(e);
      });


    }
  }


  async editProduct(kdproduct: string,nmproduct: string,spek: string,qty: number,min: number,hargabeli: number,
    hargajual: number,diskon: number,diskon2: number,unit_id: number,grup_id: number,categ_id: number,wh_id:number, id: number, uri: any) {
    return this.databaseObj
      .executeSql(
        `UPDATE ${this.tables.products} SET kdproduct = '${kdproduct}',nmproduct = '${nmproduct}'
        ,spek = '${spek}',qty = ${qty},min = ${min}
        ,hargabeli = ${hargabeli},hargajual = ${hargajual},diskon = ${diskon},diskon2 = ${diskon2}
        ,unit_id = '${unit_id}',grup_id = ${grup_id},categ_id = '${categ_id}',wh_id = '${wh_id}',uri = '${uri}'
         WHERE id = ${id}`,
        []
      )
      .then(() => {
        return "Product updated";
      })
      .catch((e) => {
        if (e.code === 6) {
          return "Product already exist";
        }

        return "error on updating Product " + JSON.stringify(e);
      });
  }




 // GROUP PRODUCT

  async addGrproduct(name: string) {

    return this.databaseObj
      .executeSql(
        `INSERT INTO ${this.tables.grproducts} (nmgrup) VALUES ('${name}')`,
        []
      )
      .then(() => {
        return "Product Group created";
      })
      .catch((e) => {
        if (e.code === 6) {
          return "Product Group already exists";
        }

        return "error on creating Product Group " + JSON.stringify(e);
      });
  }


  async getGrproducts() {
    return this.databaseObj
      .executeSql(
        `SELECT * FROM ${this.tables.grproducts} ORDER BY nmgrup ASC`,
        []
      )
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return "error on getting Product Group " + JSON.stringify(e);
      });
  }


  async deleteGrproduct(id: number) {

    var cek= await this.cekGrproduct(id);


    if (cek=='N'){

      return "errMsg: Record can't be deleted, this Product Group is still being used in another transaction!";

    }
    else{
      return this.databaseObj
        .executeSql(`DELETE FROM ${this.tables.grproducts} WHERE id = ${id}`, [])
        .then(() => {
          return "Product Group deleted";
        })
        .catch((e) => {
          return "error on deleting Product Group " + JSON.stringify(e);
        });

    }

  }

  async cekGrproduct(id:number) {
    return this.databaseObj
      .executeSql(
        `SELECT grup_id FROM ${this.tables.products} where grup_id= ${id}`,
        []
      )
      .then((res) => {
       
        if (res.rows.item(0).grup_id==null){
            return "Y";
        }
        else{
            return "N";
        }
        
      })
      .catch((e) => {
        return "error on Delete Product Group " + JSON.stringify(e);
      });
  }


  async editGrproduct(name: string, id: number) {
    return this.databaseObj
      .executeSql(
        `UPDATE ${this.tables.grproducts} SET nmgrup = '${name}' WHERE id = ${id}`,
        []
      )
      .then(() => {
        return "Product Group updated";
      })
      .catch((e) => {
        if (e.code === 6) {
          return "Product Group already exist";
        }

        return "error on updating Product Group " + JSON.stringify(e);
      });
  }


  // GROUP FOREX

  async addForex(nmforex: string,descforex: string,simbol:string, df: string,kurs: number) {

    return this.databaseObj
      .executeSql(
        `INSERT INTO ${this.tables.forexs} (nmforex,descforex,simbol,df,kurs) VALUES ('${nmforex}','${descforex}','${simbol}','${df}',${kurs})`,
        []
      )
      .then(() => {
        return "Forex created";
      })
      .catch((e) => {
        if (e.code === 6) {
          return "Forex already exists";
        }

        return "error on creating Forex " + JSON.stringify(e);
      });
  }


  async getForexs() {
    return this.databaseObj
      .executeSql(
        `SELECT id,nmforex,simbol,descforex,kurs,df,case when df='Yes' then '['||'Default'||']' else '' end dfdesc FROM ${this.tables.forexs} ORDER BY id DESC`,
        []
      )
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return "error on getting Forexs " + JSON.stringify(e);
      });
  }


  async deleteForex(id: number) {

    var cek= await this.cekForex(id);


    if (cek=='N'){

      return "errMsg: Record can't be deleted, this Forex is still being used in another transaction!";

    }
    else{
      return this.databaseObj
        .executeSql(`DELETE FROM ${this.tables.forexs} WHERE id = ${id}`, [])
        .then(() => {
          return "Forex deleted";
        })
        .catch((e) => {
          return "error on deleting Forex " + JSON.stringify(e);
        });

    }

  }

  async cekForex(id:number) {
    return this.databaseObj
      .executeSql(
        `SELECT forex_id FROM ${this.tables.belis} where forex_id= ${id}`,
        []
      )
      .then((res) => {
       
        if (res.rows.item(0).forex_id==null){
            return "Y";
        }
        else{
            return "N";
        }
        
      })
      .catch((e) => {
        return "error on Check Forex " + JSON.stringify(e);
      });
  }


  async editForex(id:number,nmforex: string,descforex: string,simbol:string,df:string) {
    return this.databaseObj
      .executeSql(
        `UPDATE ${this.tables.forexs} SET nmforex = '${nmforex}',descforex = '${descforex}',simbol = '${simbol}',df = '${df}' WHERE id = ${id}`,
        []
      )
      .then(() => {
        return "Forex updated";
      })
      .catch((e) => {
        if (e.code === 6) {
          return "Forex already exist";
        }

        return "error on updating Forex " + JSON.stringify(e);
      });
  }

  async setForexNo() {
    return this.databaseObj
      .executeSql(
        `UPDATE ${this.tables.forexs} SET df = 'No'`,
        []
      )
      .then(() => {
        return "Forex updated";
      })
      .catch((e) => {
        if (e.code === 6) {
          return "Forex already exist";
        }

        return "error on updating Forex " + JSON.stringify(e);
      });
  }



  // UNIT
  async addUnit(name: string) {

    return this.databaseObj
      .executeSql(
        `INSERT INTO ${this.tables.units} (nmunit) VALUES ('${name}')`,
        []
      )
      .then(() => {
        return "Product Unit created";
      })
      .catch((e) => {
        if (e.code === 6) {
          return "Product Unit already exists";
        }

        return "error on creating Product Group " + JSON.stringify(e);
      });
  }


  async getUnits() {
    return this.databaseObj
      .executeSql(
        `SELECT * FROM ${this.tables.units} ORDER BY nmunit ASC`,
        []
      )
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return "error on getting Product Unit " + JSON.stringify(e);
      });
  }


  async deleteUnit(id: number) {

    var cek= await this.cekUnit(id);


    if (cek=='N'){

      return "errMsg: Record can't be deleted, this Unit is still being used in another transaction!";

    }
    else{
      return this.databaseObj
        .executeSql(`DELETE FROM ${this.tables.units} WHERE id = ${id}`, [])
        .then(() => {
          return "Unit deleted";
        })
        .catch((e) => {
          return "error on deleting Product Unit " + JSON.stringify(e);
        });

    }

  }


  async deleteUser(id: number) {

    var cek= await this.cekUser(id);


    if (cek=='N'){

      return "errMsg: Record can't be deleted, this Unit is still being used in another transaction!";

    }
    else{
      return this.databaseObj
        .executeSql(`DELETE FROM ${this.tables.users} WHERE id = ${id}`, [])
        .then(() => {
          return "User deleted";
        })
        .catch((e) => {
          return "error on deleting User " + JSON.stringify(e);
        });

    }

  }




  async cekUnit(id:number) {
    return this.databaseObj
      .executeSql(
        `SELECT unit_id FROM ${this.tables.products} where unit_id= ${id}`,
        []
      )
      .then((res) => {
       
        if (res.rows.item(0).unit_id==null){
            return "Y";
        }
        else{
            return "N";
        }
        
      })
      .catch((e) => {
        return "error on Delete Product Unit " + JSON.stringify(e);
      });
  }

  async cekUser(id:number) {
    return this.databaseObj
      .executeSql(
        `SELECT user_id FROM ${this.tables.simpans} where user_id= ${id}`,
        []
      )
      .then((res) => {
       
        if (res.rows.item(0).user_id==null){
            return "Y";
        }
        else{
            return "N";
        }
        
      })
      .catch((e) => {
        return "error on Delete User ID " + JSON.stringify(e);
      });
  }

  async editUnit(name: string, id: number) {
    return this.databaseObj
      .executeSql(
        `UPDATE ${this.tables.units} SET nmunit = '${name}' WHERE id = ${id}`,
        []
      )
      .then(() => {
        return "Product Unit updated";
      })
      .catch((e) => {
        if (e.code === 6) {
          return "Product Group already exist";
        }

        return "error on updating Product Group " + JSON.stringify(e);
      });
  }


  async editSetting(delim: string, bhs: string) {
    return this.databaseObj
      .executeSql(
        `UPDATE ${this.tables.settings} SET delim = '${delim}',bhs = '${bhs}'`,
        []
      )
      .then(() => {
        return "Setting updated";
      })
      .catch((e) => {
        if (e.code === 6) {
          return "Setting already exist";
        }

        return "error on updating Setting " + JSON.stringify(e);
      });
  }


  // REK
  async addRek(name: string,bank_id:number,branch: string, atasnama: string) {

    return this.databaseObj
      .executeSql(
        `INSERT INTO ${this.tables.reks} (norek,bank_id,branch,atasnama) VALUES ('${name}',${bank_id},'${branch}','${atasnama}')`,
        []
      )
      .then(() => {
        return "Rek was created";
      })
      .catch((e) => {
        if (e.code === 6) {
          return "Rek already exists";
        }

        return "error on creating Rek " + JSON.stringify(e);
      });
  }


  async getReks() {
    return this.databaseObj
      .executeSql(
        `SELECT a.id,a.norek,a.bank_id,b.nmbank,a.branch,a.atasnama FROM ${this.tables.reks} a inner join banks b on a.bank_id=b.id
         ORDER BY a.norek ASC`,
        []
      )
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return "error on getting Rek " + JSON.stringify(e);
      });
  }


  async deleteRek(id: number) {

    var cek= await this.cekRek(id);


    if (cek=='N'){

      return "errMsg: Record can't be deleted, this Unit is still being used in another transaction!";

    }
    else{
      return this.databaseObj
        .executeSql(`DELETE FROM ${this.tables.reks} WHERE id = ${id}`, [])
        .then(() => {
          return "Rek was deleted";
        })
        .catch((e) => {
          return "error on deleting Rek " + JSON.stringify(e);
        });

    }

  }

  async cekRek(id:number) {
    return this.databaseObj
      .executeSql(
        `SELECT rek_id FROM ${this.tables.bkouts} where rek_id= ${id}`,
        []
      )
      .then((res) => {
       
        if (res.rows.item(0).rek_id==null){
            return "Y";
        }
        else{
            return "N";
        }
        
      })
      .catch((e) => {
        return "error on Delete Rek " + JSON.stringify(e);
      });
  }


  async editRek(name: string,bank_id:number, branch: string,atasnama: string,id: number) {
    return this.databaseObj
      .executeSql(
        `UPDATE ${this.tables.reks} SET norek = '${name}',bank_id = ${bank_id},branch = '${branch}',atasnama = '${atasnama}' WHERE id = ${id}`,
        []
      )
      .then(() => {
        return "Rek was updated";
      })
      .catch((e) => {
        if (e.code === 6) {
          return "Rek is already exist";
        }

        return "error on updating Rek " + JSON.stringify(e);
      });
  }


  // BANK
  async addBank(name: string) {

    return this.databaseObj
      .executeSql(
        `INSERT INTO ${this.tables.banks} (nmbank) VALUES ('${name}')`,
        []
      )
      .then(() => {
        return "Bank was created";
      })
      .catch((e) => {
        if (e.code === 6) {
          return "Bank already exists";
        }

        return "error on creating Bank " + JSON.stringify(e);
      });
  }


  async getBanks() {
    return this.databaseObj
      .executeSql(
        `SELECT * FROM ${this.tables.banks} ORDER BY nmbank ASC`,
        []
      )
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return "error on getting Bank " + JSON.stringify(e);
      });
  }


  async deleteBank(id: number) {

    var cek= await this.cekBank(id);


    if (cek=='N'){

      return "errMsg: Record can't be deleted, this Unit is still being used in another transaction!";

    }
    else{
      return this.databaseObj
        .executeSql(`DELETE FROM ${this.tables.banks} WHERE id = ${id}`, [])
        .then(() => {
          return "Bank was deleted";
        })
        .catch((e) => {
          return "error on deleting Bank " + JSON.stringify(e);
        });

    }

  }

  async cekBank(id:number) {
    return this.databaseObj
      .executeSql(
        `SELECT bank_id FROM ${this.tables.reks} where bank_id= ${id}`,
        []
      )
      .then((res) => {
       
        if (res.rows.item(0).bank_id==null){
            return "Y";
        }
        else{
            return "N";
        }
        
      })
      .catch((e) => {
        return "error on Delete Bank " + JSON.stringify(e);
      });
  }


  async editBank(name: string, id: number) {
    return this.databaseObj
      .executeSql(
        `UPDATE ${this.tables.banks} SET nmbank = '${name}' WHERE id = ${id}`,
        []
      )
      .then(() => {
        return "Bank was updated";
      })
      .catch((e) => {
        if (e.code === 6) {
          return "Bank is already exist";
        }

        return "error on updating Bank " + JSON.stringify(e);
      });
  }


  // WHS
  async addWh(kdbr: string, name: string) {

    var branch_id= await this.getBranchID(kdbr);
    var now= await this.convertDate();
    var kd = await this.maxWh();


    return this.databaseObj
      .executeSql(
        `INSERT INTO ${this.tables.whs} (kdwh,nmwh,branch_id,lastupdate,lastuser) VALUES ('${kd}','${name}','${branch_id}','${now}',1)`,
        []
      )
      .then(() => {
        return "Wh created";
      })
      .catch((e) => {
        if (e.code === 6) {
          return "Wh already exists";
        }

        return "error on creating Wh " + JSON.stringify(e);
      });
  }



  async getBranchID(kdbr:string) {

    
    return this.databaseObj
      .executeSql(
        `SELECT id FROM ${this.tables.branches} where kdbranch= '${kdbr}'`,
        []
      )
      .then((res) => {
       
       return res.rows.item(0).id;
        
      })
      .catch((e) => {
        return "error on get Branch ID" + JSON.stringify(e);
      });
  }


  async getWhs() {
    return this.databaseObj
      .executeSql(
        `SELECT a.*,b.nmbranch FROM ${this.tables.whs} a inner join ${this.tables.branches} b on a.branch_id=b.id ORDER BY nmwh ASC`,
        []
      )
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return "error on getting Wh " + JSON.stringify(e);
      });
  }


  async deleteWh(id: number) {

    // var cek= await this.cekWh(id);


    // if (cek=='N'){

    //   return "errMsg: Record can't be deleted, this Unit is still being used in another transaction!";

    // }
    // else{
      return this.databaseObj
        .executeSql(`DELETE FROM ${this.tables.whs} WHERE branch_id = ${id}`, [])
        .then(() => {
          return "Wh deleted";
        })
        .catch((e) => {
          return "error on deleting Wh " + JSON.stringify(e);
        });

   // }

  }

  async cekWh(id:number) {
    return this.databaseObj
      .executeSql(
        `SELECT wh_id FROM ${this.tables.products} where wh_id= ${id}`,
        []
      )
      .then((res) => {
       
        if (res.rows.item(0).wh_id==null){
            return "Y";
        }
        else{
            return "N";
        }
        
      })
      .catch((e) => {
        return "error on Delete Wh " + JSON.stringify(e);
      });
  }


  async editWh(id: number,name: string ) {
    return this.databaseObj
      .executeSql(
        `UPDATE ${this.tables.whs} SET nmwh = '${name}' WHERE id = ${id}`,
        []
      )
      .then(() => {
        return "Product Wh updated";
      })
      .catch((e) => {
        if (e.code === 6) {
          return "Wh already exist";
        }

        return "error on updating Wh " + JSON.stringify(e);
      });
  }

  // BRANCH



  async addBranch(name: string, address: string ,city: string ,province: string ,zipcode: string
    ,phone: string ,pic: string) {

    var now= await this.convertDate();
    var kd = await this.maxBranch();

    return this.databaseObj
      .executeSql(
        `INSERT INTO ${this.tables.branches} (kdbranch , nmbranch , address ,city ,province ,zipcode 
          ,phone ,pic ,lastupdate ,lastuser) VALUES ('${kd}','${name}','${address}','${city}','${province}',
          '${zipcode}','${phone}','${pic}','${now}',1
          )`,
        []
      )
      .then(() => {
        return kd;
      })
      .catch((e) => {
        if (e.code === 6) {
          return "Branch already exists";
        }

        return "error on creating Branch " + JSON.stringify(e);
      });
  }


  async getBranches() {
    return this.databaseObj
      .executeSql(
        `SELECT * FROM ${this.tables.branches} ORDER BY nmbranch ASC`,
        []
      )
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return "error on getting Branches " + JSON.stringify(e);
      });
  }


  async deleteBranch(id: number) {

    var cek= await this.cekBranch(id);
    var cek2= await this.cekBranch2(id);


    if (cek=='N' || cek2=='N'){

      return "errMsg: Record can't be deleted, this Unit is still being used in another transaction!";

    }
    else{
      return this.databaseObj
        .executeSql(`DELETE FROM ${this.tables.branches} WHERE id = ${id}`, [])
        .then(() => {
          return "Branch deleted";
        })
        .catch((e) => {
          return "error on deleting Branch " + JSON.stringify(e);
        });

    }

  }

  async cekBranch(id:number) {
    return this.databaseObj
      .executeSql(
        `SELECT branch_id FROM ${this.tables.belis} where branch_id= ${id}`,
        []
      )
      .then((res) => {
       
        if (res.rows.item(0).branch_id==null){
            return "Y";
        }
        else{
            return "N";
        }
        
      })
      .catch((e) => {
        return "error on Delete Branch " + JSON.stringify(e);
      });
  }

  async cekBranch2(id:number) {
    return this.databaseObj
      .executeSql(
        `SELECT branch_id FROM ${this.tables.juals} where branch_id= ${id}`,
        []
      )
      .then((res) => {
       
        if (res.rows.item(0).branch_id==null){
            return "Y";
        }
        else{
            return "N";
        }
        
      })
      .catch((e) => {
        return "error on Delete Branch " + JSON.stringify(e);
      });
  }

  async editBranch(id: number, nmbranch: string,address: string,city: string,province: string,
    zipcode: string,phone: string,pic: string) {
    return this.databaseObj
      .executeSql(
        `UPDATE ${this.tables.branches} SET nmbranch = '${nmbranch}',address = '${address}',city = '${city}',
        province = '${province}',zipcode = '${zipcode}',phone = '${phone}', pic = '${pic}'
         WHERE id = ${id}`,
        []
      )
      .then(() => {
        return "Branch updated";
      })
      .catch((e) => {
        if (e.code === 6) {
          return "Branch already exist";
        }

        return "error on updating Branch " + JSON.stringify(e);
      });
  }


  // CATEG
  async addCategory(name: string) {

    return this.databaseObj
      .executeSql(
        `INSERT INTO ${this.tables.categories} (nmcategory) VALUES ('${name}')`,
        []
      )
      .then(() => {
        return "Category created";
      })
      .catch((e) => {
        if (e.code === 6) {
          return "Category already exists";
        }

        return "error on creating Category " + JSON.stringify(e);
      });
  }


  async getCategory() {
    return this.databaseObj
      .executeSql(
        `SELECT * FROM ${this.tables.categories} ORDER BY nmcategory ASC`,
        []
      )
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return "error on getting Category " + JSON.stringify(e);
      });
  }


  async deleteCategory(id: number) {

    var cek= await this.cekCategory(id);


    if (cek=='N'){

      return "errMsg: Record can't be deleted, this Unit is still being used in another transaction!";

    }
    else{
      return this.databaseObj
        .executeSql(`DELETE FROM ${this.tables.categories} WHERE id = ${id}`, [])
        .then(() => {
          return "Category deleted";
        })
        .catch((e) => {
          return "error on deleting Category " + JSON.stringify(e);
        });

    }

  }

  async cekCategory(id:number) {
    return this.databaseObj
      .executeSql(
        `SELECT tp FROM ${this.tables.products} where tp= ${id}`,
        []
      )
      .then((res) => {
       
        if (res.rows.item(0).tp==null){
            return "Y";
        }
        else{
            return "N";
        }
        
      })
      .catch((e) => {
        return "error on Delete Category " + JSON.stringify(e);
      });
  }


  async editCategory(name: string, id: number) {
    return this.databaseObj
      .executeSql(
        `UPDATE ${this.tables.categories} SET nmcategory = '${name}' WHERE id = ${id}`,
        []
      )
      .then(() => {
        return "Category updated";
      })
      .catch((e) => {
        if (e.code === 6) {
          return "Category already exist";
        }

        return "error on updating Category " + JSON.stringify(e);
      });
  }

 //Beli

 async addBeli(tr:string, podate: Date,vendor_id: number, tp:string,forex_id:number,kurs: number, branch_id: number) {

 
  var nopo=  await this.maxBeli(tr);
  var now= await this.convertDate();

  return this.databaseObj
    .executeSql(
      `INSERT INTO ${this.tables.belis} (nopo,podate,vendor_id,stat,tp,forex_id,kurs,jenis,branch_id,lastupdate,lastuser) VALUES ('${nopo}','${podate}', ${vendor_id},'Draft','${tp}',${forex_id},'${kurs}','${tr}','${branch_id}','${now}','1')`,
      []
      
    )
    .then(() => {
      return nopo;
    })
    .catch((e) => {
      return "error on creating Transaction " + JSON.stringify(e);
    });
}


async addSimpan(tr:string, simpandate: Date,user_id: number,amount: number) {

 
  var nosimpan=  await this.maxSimpan(tr);
  var now= await this.convertDate();

  return this.databaseObj
    .executeSql(
      `INSERT INTO ${this.tables.simpans} (nosimpan,simpandate,user_id,stat,tr,amount,lastupdate,lastuser) VALUES ('${nosimpan}','${simpandate}', ${user_id},'Draft','${tr}',${amount},'${now}','1')`,
      []
      
    )
    .then(() => {
      return nosimpan;
    })
    .catch((e) => {
      return "error on creating Transaction " + JSON.stringify(e);
    });
}

async addBkout(bkoutdate: Date,vendor_id: number, tp:string,pf: string,forex_id:number,kurs: number,rek_id: number) {

 
  var nobkout=  await this.maxBkout();
  var now= await this.convertDate();

  return this.databaseObj
    .executeSql(
      `INSERT INTO ${this.tables.bkouts} (nobkout,bkoutdate,vendor_id,stat,tp,pf,forex_id,kurs,rek_id,lastupdate,lastuser) VALUES ('${nobkout}','${bkoutdate}', ${vendor_id},'Draft','${tp}','${pf}',${forex_id},'${kurs}','${rek_id}','${now}','1')`,
      []
      
    )
    .then(() => {
       return nobkout;
    })
    .catch((e) => {
      return "error on creating Transaction " + JSON.stringify(e);
    });
 }


 async addBkin(bkindate: Date,cust_id: number, tp:string,pf: string,forex_id:number,kurs: number,rek_id: number) {

 
  var nobkin=  await this.maxBkin();
  var now= await this.convertDate();

  return this.databaseObj
    .executeSql(
      `INSERT INTO ${this.tables.bkins} (nobkin,bkindate,cust_id,stat,tp,pf,forex_id,kurs,rek_id,lastupdate,lastuser) VALUES ('${nobkin}','${bkindate}', ${cust_id},'Draft','${tp}','${pf}',${forex_id},'${kurs}','${rek_id}','${now}','1')`,
      []
      
    )
    .then(() => {
       return nobkin;
    })
    .catch((e) => {
      return "error on creating Transaction " + JSON.stringify(e);
    });
 }


 async addJoborder(jobdate: Date,wh_id: number, product_fg_id:number,target: number,final: number,reject: number,finishdate: Date, forex_id: number, kurs: number, hpp: number, hargajual: number,cs: string) {    
 
  var nojob=  await this.maxJob();
  var now= await this.convertDate();

  return this.databaseObj
    .executeSql(
      `INSERT INTO ${this.tables.joborders} (nojob,jobdate,wh_id,product_fg_id,target,final,reject,finishdate,status,forex_id,kurs,hpp,hargajual,cs,lastupdate,lastuser) 
      VALUES ('${nojob}','${jobdate}', ${wh_id},'${product_fg_id}','${target}',${final},'${reject}','${finishdate}','Draft','${forex_id}','${kurs}','${hpp}','${hargajual}','${cs}','${now}','1')`,
      []
      
    )
    .then(() => {
       return nojob;
    })
    .catch((e) => {
      return "error on creating Transaction " + JSON.stringify(e);
    });
 }


 async addMutasi(mutasidate: Date,wh_id: number, wh_id2:number) {    
 
  var nomutasi=  await this.maxMutasi();
  var now= await this.convertDate();

  return this.databaseObj
    .executeSql(
      `INSERT INTO ${this.tables.mutasis} (nomutasi,mutasidate,wh_id,wh_id2,status,lastupdate,lastuser) 
      VALUES ('${nomutasi}','${mutasidate}', ${wh_id},'${wh_id2}','Draft','${now}','1')`,
      []
      
    )
    .then(() => {
       return nomutasi;
    })
    .catch((e) => {
      return "error on creating Transaction " + JSON.stringify(e);
    });
 }


 
 async addSto(stodate: Date,wh_id: number) {    
 
  var nosto=  await this.maxSto();
  var now= await this.convertDate();

  return this.databaseObj
    .executeSql(
      `INSERT INTO ${this.tables.stos} (nosto,stodate,wh_id,status,lastupdate,lastuser) 
      VALUES ('${nosto}','${stodate}', ${wh_id},'Draft','${now}','1')`,
      []
      
    )
    .then(() => {
       return nosto;
    })
    .catch((e) => {
      return "error on creating Transaction " + JSON.stringify(e);
    });
 }



 async editJoborder(id: number,nojob: string, jobdate: Date,wh_id: number, product_fg_id:number,target: number,final: number,reject: number,finishdate: Date,hpp: number, hargajual: number,cs: string) {
  return this.databaseObj
    .executeSql(
      `UPDATE ${this.tables.joborders} SET nojob =  '${nojob}',jobdate= DATE('${jobdate}') , 
      wh_id = ${wh_id},product_fg_id='${product_fg_id}',target='${target}',final='${final}',
      reject=${reject},finishdate='${finishdate}',hpp='${hpp}',hargajual='${hargajual}',cs='${cs}' WHERE id = ${id}`,
      []
    )
    .then(() => {
      return "Transaction was updated ";
    })
    .catch((e) => {
      return "error on updating Transaction" + JSON.stringify(e);
    });
}


async editMutasi(id: number, mutasidate: Date,wh_id: number, wh_id2:number) {
  return this.databaseObj
    .executeSql(
      `UPDATE ${this.tables.mutasis} SET mutasidate= DATE('${mutasidate}') , 
      wh_id = ${wh_id},wh_id2='${wh_id2}' WHERE id = ${id}`,
      []
    )
    .then(() => {
      return "Transaction was updated ";
    })
    .catch((e) => {
      return "error on updating Transaction" + JSON.stringify(e);
    });
}


async editSto(id: number, stodate: Date,wh_id: number) {
  return this.databaseObj
    .executeSql(
      `UPDATE ${this.tables.stos} SET stodate= DATE('${stodate}') , 
      wh_id = ${wh_id} WHERE id = ${id}`,
      []
    )
    .then(() => {
      return "Transaction was updated ";
    })
    .catch((e) => {
      return "error on updating Transaction" + JSON.stringify(e);
    });
}


 async addBkoutCash(id: number) {

 
  var nobkout=  await this.maxBkout();
  var now= await this.convertDate();
  var bkoutdate= await this.convertDate();

  return this.databaseObj  
    .executeSql(
      `SELECT b.vendor_id,b.forex_id,b.kurs FROM ${this.tables.terimas}  a inner join ${this.tables.belis} b on a.beli_id=b.id
      where a.id= ${id}`,
      []
    )
    .then((res) => {

       var vendor_id= res.rows.item(0).vendor_id;
       var tp= 'Cash';
       var pf= 'Invoice';
       var forex_id= res.rows.item(0).forex_id;
       var kurs= res.rows.item(0).kurs;
       

        return this.databaseObj
        .executeSql(
          `INSERT INTO ${this.tables.bkouts} (nobkout,bkoutdate,vendor_id,stat,tp,pf,forex_id,kurs,rek_id,lastupdate,lastuser) VALUES ('${nobkout}','${bkoutdate}', ${vendor_id},'Approved','${tp}','${pf}',${forex_id},'${kurs}',0,'${now}','1')`,
          []
          
        )
        .then(() => {
          return nobkout;
        })
        .catch((e) => {
          return "error on creating Transaction " + JSON.stringify(e);
        });

                  
      
    })

  
 }

 async addBkinCash(id: number) {

 
  var nobkin=  await this.maxBkin();
  var now= await this.convertDate();
  var bkindate= await this.convertDate();

  return this.databaseObj  
    .executeSql(
      `SELECT b.cust_id,b.forex_id,b.kurs FROM ${this.tables.kirims}  a inner join ${this.tables.juals} b on a.jual_id=b.id
      where a.id= ${id}`,
      []
    )
    .then((res) => {

       var cust_id= res.rows.item(0).cust_id;
       var tp= 'Cash';
       var pf= 'Invoice';
       var forex_id= res.rows.item(0).forex_id;
       var kurs= res.rows.item(0).kurs;
       

        return this.databaseObj
        .executeSql(
          `INSERT INTO ${this.tables.bkins} (nobkin,bkindate,cust_id,stat,tp,pf,forex_id,kurs,rek_id,lastupdate,lastuser) VALUES ('${nobkin}','${bkindate}', ${cust_id},'Approved','${tp}','${pf}',${forex_id},'${kurs}',0,'${now}','1')`,
          []
          
        )
        .then(() => {
          return nobkin;
        })
        .catch((e) => {
          return "error on creating Transaction " + JSON.stringify(e);
        });

                  
      
    })

  
 }



 async addBkinCashPos(id: number) {

  var nobkin=  await this.maxBkin();
  var now= await this.convertDate();
  var bkindate= await this.convertDate();

  return this.databaseObj  
    .executeSql(
      `SELECT 2 as cust_id,a.forex_id,a.kurs FROM ${this.tables.juals}  a 
      where a.id= ${id}`,
      []
    )
    .then((res) => {

       var cust_id= res.rows.item(0).cust_id;
       var tp= 'Cash';
       var pf= 'Income';
       var forex_id= res.rows.item(0).forex_id;
       var kurs= res.rows.item(0).kurs;
       

        return this.databaseObj
        .executeSql(
          `INSERT INTO ${this.tables.bkins} (nobkin,bkindate,cust_id,stat,tp,pf,forex_id,kurs,rek_id,lastupdate,lastuser) VALUES ('${nobkin}','${bkindate}', ${cust_id},'Approved','${tp}','${pf}',${forex_id},'${kurs}',0,'${now}','1')`,
          []
          
        )
        .then(() => {
          return nobkin;
        })
        .catch((e) => {
          return "error on creating Transaction " + JSON.stringify(e);
        });

                  
      
    })

  
 }


 //bkout_id: number,terima_id: number,amount:number,detdesc: string,nostruk: string

 async getBkoutdet(nobkout: string) {
  return this.databaseObj.executeSql(`SELECT a.id
  FROM bkouts a 
  WHERE a.nobkout = '${nobkout}' 
  `, []).then(res => { 
    return {
      id: res.rows.item(0).id,
     
    }
  });
}



async getCekCategID(id: string) {
  return this.databaseObj.executeSql(`SELECT a.categ_id
  FROM products a 
  WHERE a.id = '${id}' 
  `, []).then(res => { 
    return {

      id: res.rows.item(0).categ_id,
     
    }
  });
}


async getBkindet(nobkin: string) {
  return this.databaseObj.executeSql(`SELECT a.id
  FROM bkins a 
  WHERE a.nobkin = '${nobkin}' 
  `, []).then(res => { 
    return {
      id: res.rows.item(0).id,
     
    }
  });
}


async getBkindetPos(nobkin: string) {
  return this.databaseObj.executeSql(`SELECT a.id
  FROM bkins a 
  WHERE a.nobkin = '${nobkin}' 
  `, []).then(res => { 
    return {
      id: res.rows.item(0).id,
     
    }
  });
}

async getFormBkout(nobkout: string) {
  return this.databaseObj.executeSql(`SELECT a.id,a.nobkout,a.bkoutdate,a.vendor_id,a.stat,a.tp,a.pf,a.forex_id,a.rek_id,
  a.kurs,d.kdpartner,d.nmpartner,d.address,d.city,d.zipcode, sum(b.amount*a.kurs) as total 
  FROM bkouts a 
  left join bkoutdets b on a.id=b.bkout_id  
  left join partners d on a.vendor_id=d.id
  WHERE a.nobkout = '${nobkout}' 
  group by a.id,a.nobkout,a.bkoutdate,a.vendor_id,a.stat,a.tp,a.pf,a.forex_id,a.rek_id,a.kurs,d.kdpartner,d.nmpartner,d.address,d.city,d.zipcode`, []).then(res => { 
    return {
      id: res.rows.item(0).id,
      nobkout: res.rows.item(0).nobkout,  
      bkoutdate: res.rows.item(0).bkoutdate,
      vendor_id: res.rows.item(0).vendor_id,
      stat: res.rows.item(0).stat,
      tp: res.rows.item(0).tp,
      pf: res.rows.item(0).pf,
      rek_id: res.rows.item(0).rek_id,
      forex_id: res.rows.item(0).forex_id,
      kurs: res.rows.item(0).kurs,
      total: res.rows.item(0).total,
      kdpartner: res.rows.item(0).kdpartner,
      nmpartner: res.rows.item(0).nmpartner,
      address: res.rows.item(0).address,
      city: res.rows.item(0).city,
      zipcode: res.rows.item(0).zipcode,
    }
  });
}


async getAllBankouts() {
  return this.databaseObj.executeSql(`SELECT sum(b.amount*a.kurs) as totbankout 
  FROM bkouts a 
  left join bkoutdets b on a.id=b.bkout_id`, []).then(res => { 
    return {
      totbankout: res.rows.item(0).totbankout,
      
    }
  });
}

async getAllBankins() {
  return this.databaseObj.executeSql(`SELECT sum(b.amount*a.kurs) as totbankin
  FROM bkins a 
  left join bkindets b on a.id=b.bkin_id`, []).then(res => { 
    return {
      totbankin: res.rows.item(0).totbankin,
      
    }
  });
}


async getFormBkin(nobkin: string) {
  return this.databaseObj.executeSql(`SELECT a.id,a.nobkin,a.bkindate,a.cust_id,a.stat,a.tp,a.pf,a.forex_id,a.rek_id,
  a.kurs,d.kdpartner,d.nmpartner,d.address,d.city,d.zipcode, sum(b.amount*a.kurs) as total 
  FROM bkins a 
  left join bkindets b on a.id=b.bkin_id  
  left join partners d on a.cust_id=d.id
  WHERE a.nobkin = '${nobkin}' 
  group by a.id,a.nobkin,a.bkindate,a.cust_id,a.stat,a.tp,a.pf,a.forex_id,a.rek_id,a.kurs,d.kdpartner,d.nmpartner,d.address,d.city,d.zipcode`, []).then(res => { 
    return {
      id: res.rows.item(0).id,
      nobkin: res.rows.item(0).nobkin,  
      bkindate: res.rows.item(0).bkindate,
      cust_id: res.rows.item(0).cust_id,
      stat: res.rows.item(0).stat,
      tp: res.rows.item(0).tp,
      pf: res.rows.item(0).pf,
      rek_id: res.rows.item(0).rek_id,
      forex_id: res.rows.item(0).forex_id,
      kurs: res.rows.item(0).kurs,
      total: res.rows.item(0).total,
      kdpartner: res.rows.item(0).kdpartner,
      nmpartner: res.rows.item(0).nmpartner,
      address: res.rows.item(0).address,
      city: res.rows.item(0).city,
      zipcode: res.rows.item(0).zipcode,
    }
  });
}


async getFormJoborder(nojob: string) {
  return this.databaseObj.executeSql(`SELECT a.id,a.nojob,a.jobdate,a.finishdate,a.wh_id,a.status,a.target,a.final,a.reject,a.forex_id,a.product_fg_id,
  a.kurs,a.hpp,a.hargajual,d.kdproduct,d.nmproduct,d.spek,e.nmunit,a.cs,sum(b.harga*a.kurs) as total 
  FROM joborders a 
  left join joborderdets b on a.id=b.job_id  
  left join products d on a.product_fg_id=d.id
  left join units e on d.unit_id=e.id
  WHERE a.nojob = '${nojob}' 
  group by a.id,a.nojob,a.jobdate,a.finishdate,a.wh_id,a.status,a.target,a.final,a.reject,a.forex_id,a.product_fg_id,
  a.kurs,a.hpp,a.hargajual,d.kdproduct,d.nmproduct,d.spek,e.nmunit,a.cs`, []).then(res => { 
    return {
      id: res.rows.item(0).id,
      nojob: res.rows.item(0).nojob,  
      jobdate: res.rows.item(0).jobdate,
      finishdate: res.rows.item(0).finishdate,
      wh_id: res.rows.item(0).wh_id,
      status: res.rows.item(0).status,
      target: res.rows.item(0).target,
      final: res.rows.item(0).final,
      reject: res.rows.item(0).reject,
      product_fg_id: res.rows.item(0).product_fg_id,
      forex_id: res.rows.item(0).forex_id,
      kurs: res.rows.item(0).kurs,
      total: res.rows.item(0).total,
      hpp: res.rows.item(0).hpp,
      hargajual: res.rows.item(0).hargajual,
      kdproduct: res.rows.item(0).kdproduct,
      nmproduct: res.rows.item(0).nmproduct,
      spek: res.rows.item(0).spek,
      nmunit: res.rows.item(0).nmunit,
      cs: res.rows.item(0).cs,
     
    }
  });
}


async getFormMutasi(nomutasi: string) {
  return this.databaseObj.executeSql(`SELECT a.id,a.nomutasi,a.mutasidate,a.wh_id,a.wh_id2,a.status,f.nmwh,g.nmwh as nmwh2
  FROM mutasis a 
  left join whs f on a.wh_id=f.id
  left join whs g on a.wh_id2=g.id
  WHERE a.nomutasi = '${nomutasi}'`, []).then(res => { 
    return {
      id: res.rows.item(0).id,
      nomutasi: res.rows.item(0).nomutasi,  
      mutasidate: res.rows.item(0).mutasidate,
      wh_id: res.rows.item(0).wh_id,
      wh_id2: res.rows.item(0).wh_id2,
      status: res.rows.item(0).status,
     
      nmwh: res.rows.item(0).nmwh,
      nmwh2: res.rows.item(0).nmwh2,

     
    }
  });
}

async getFormSto(nosto: string) {
  return this.databaseObj.executeSql(`SELECT a.id,a.nosto,a.stodate,a.wh_id,a.status,f.nmwh
  FROM stos a 
  left join whs f on a.wh_id=f.id
  WHERE a.nosto = '${nosto}'`, []).then(res => { 
    return {
      id: res.rows.item(0).id,
      nosto: res.rows.item(0).nosto,  
      stodate: res.rows.item(0).stodate,
      wh_id: res.rows.item(0).wh_id,
      status: res.rows.item(0).status,
     
      nmwh: res.rows.item(0).nmwh,
     
    }
  });
}

async getFormBeli(nopo: string) {
  return this.databaseObj.executeSql(`SELECT a.id,a.nopo,a.podate,a.vendor_id,a.stat,a.tp,a.forex_id,
  a.kurs,d.kdpartner,d.nmpartner,d.address,d.city,d.zipcode,a.branch_id,case when b.qty is null then 0 else 
  sum((b.qty*c.hargabeli*a.kurs)- (b.qty * c.diskon *a.kurs)) end total 
  FROM belis a 
  left join belidets b on a.id=b.beli_id  
  left join products c on b.product_id=c.id
  left join partners d on a.vendor_id=d.id
  WHERE a.nopo = '${nopo}' 
  group by a.id,a.nopo,a.podate,a.vendor_id,a.stat,a.tp,a.forex_id,a.kurs,d.kdpartner,d.nmpartner,d.address,d.city,d.zipcode,a.branch_id`, []).then(res => { 
    return {
      id: res.rows.item(0).id,
      nopo: res.rows.item(0).nopo,  
      podate: res.rows.item(0).podate,
      vendor_id: res.rows.item(0).vendor_id,
      stat: res.rows.item(0).stat,
      tp: res.rows.item(0).tp,
      forex_id: res.rows.item(0).forex_id,
      kurs: res.rows.item(0).kurs,
      total: res.rows.item(0).total,
      kdpartner: res.rows.item(0).kdpartner,
      nmpartner: res.rows.item(0).nmpartner,
      address: res.rows.item(0).address,
      city: res.rows.item(0).city,
      zipcode: res.rows.item(0).zipcode,
      branch_id: res.rows.item(0).branch_id,
    }
  });
}


async getFormSimpan(nosimpan: string) {
  return this.databaseObj.executeSql(`SELECT a.id,a.nosimpan,a.simpandate,a.user_id,a.stat,
  d.kduser,d.nmuser,d.address,d.city,d.zipcode,a.amount,a.tr
  FROM simpans a 
  left join users d on a.user_id=d.id
  WHERE a.nosimpan = '${nosimpan}'`, []).then(res => { 
    return {
      id: res.rows.item(0).id,
      nosimpan: res.rows.item(0).nosimpan,  
      simpandate: res.rows.item(0).simpandate,
      user_id: res.rows.item(0).user_id,
      stat: res.rows.item(0).stat,
      tr: res.rows.item(0).tr,
      amount: res.rows.item(0).amount,
      
      kduser: res.rows.item(0).kduser,
      nmuser: res.rows.item(0).nmuser,
      address: res.rows.item(0).address,
      city: res.rows.item(0).city,
      zipcode: res.rows.item(0).zipcode,

    }
  });
}

async getDefCur() {
  return this.databaseObj.executeSql(`SELECT id,kurs,simbol FROM forexs a 
  WHERE a.df = 'Yes'  
  `, []).then(res => { 
    return {
      id: res.rows.item(0).id,
      kurs: res.rows.item(0).kurs,
      simbol: res.rows.item(0).simbol,
    }
  });
}



async getTotalBeli(id: number) {
  return this.databaseObj.executeSql(`SELECT d.simbol,sum((b.qty*c.hargabeli*a.kurs)- (b.qty * c.diskon)) as total 
  FROM belis a 
  left join belidets b on a.id=b.beli_id  
  left join products c on b.product_id=c.id
  left join forexs d on a.forex_id=d.id
  WHERE a.id = ${id}  group by d.simbol
  `, []).then(res => { 
    return {
      total: res.rows.item(0).total,
      simbol: res.rows.item(0).simbol,
    }
  });
}


async getVendorDesc(id) {
  return this.databaseObj
    .executeSql(
      `SELECT a.id,a.kdpartner,a.nmpartner,a.address,a.city,a.zipcode 
      FROM ${this.tables.partners} a 
      where a.id='${id}'`,
      []
    )
    .then((res) => {
     
      if (res.rows.item(0).id==null){
        return {

          id: "",
          kdpartner: "", 
          nmpartner: "", 
          address: "",
          city: "",
          zipcode: "",
       
        
        }
      }
      else{

        return {

        id: res.rows.item(0).id,
        kdpartner: res.rows.item(0).kdpartner,  
        nmpartner: res.rows.item(0).nmpartner,
        address: res.rows.item(0).address,
        city: res.rows.item(0).city,
        zipcode: res.rows.item(0).zipcode,
        editMode: '1',
        }
      
    }
      
    })
    .catch((e) => {
      return "error on Get Vendor " + JSON.stringify(e);
    });
}


async getUserDesc(id) {
  return this.databaseObj
    .executeSql(
      `SELECT a.id,a.kduser,a.nmuser,a.address,a.city,a.zipcode 
      FROM ${this.tables.users} a 
      where a.id='${id}'`,
      []
    )
    .then((res) => {
     
      if (res.rows.item(0).id==null){
        return {

          id: "",
          kduser: "", 
          nmuser: "", 
          address: "",
          city: "",
          zipcode: "",
       
        
        }
      }
      else{

        return {

        id: res.rows.item(0).id,
        kduser: res.rows.item(0).kduser,  
        nmuser: res.rows.item(0).nmuser,
        address: res.rows.item(0).address,
        city: res.rows.item(0).city,
        zipcode: res.rows.item(0).zipcode,
        editMode: '1',
        }
      
    }
      
    })
    .catch((e) => {
      return "error on Get Vendor " + JSON.stringify(e);
    });
}


async getBankDesc(id) {
  return this.databaseObj
    .executeSql(
      `SELECT a.id,a.nmbank 
      FROM ${this.tables.banks} a 
      where a.id='${id}'`,
      []
    )
    .then((res) => {
     
      if (res.rows.item(0).id==null){
        return {

          id: "",
          nmbank: "",        
        
        }
      }
      else{

        return {

        id: res.rows.item(0).id,
        nmbank: res.rows.item(0).nmbank,  
        editMode: '1',
        }
      
    }
      
    })
    .catch((e) => {
      return "error on Get Bank " + JSON.stringify(e);
    });
}



async getCustDesc(id) {
  return this.databaseObj
    .executeSql(
      `SELECT a.id,a.kdpartner,a.nmpartner,a.address,a.city,a.zipcode,a.term 
      FROM ${this.tables.partners} a 
      where a.id='${id}'`,
      []
    )
    .then((res) => {
     
      if (res.rows.item(0).id==null){
        return {

          id: "",
          kdpartner: "", 
          nmpartner: "", 
          address: "",
          city: "",
          zipcode: "",
          term: "",
       
        
        }
      }
      else{

        return {

        id: res.rows.item(0).id,
        kdpartner: res.rows.item(0).kdpartner,  
        nmpartner: res.rows.item(0).nmpartner,
        address: res.rows.item(0).address,
        city: res.rows.item(0).city,
        zipcode: res.rows.item(0).zipcode,
        term: res.rows.item(0).term,
        editMode: '1',
        }
      
    }
      
    })
    .catch((e) => {
      return "error on Get Vendor " + JSON.stringify(e);
    });
}



async getWhDesc(id) {
  return this.databaseObj
    .executeSql(
      `SELECT a.nmwh
      FROM ${this.tables.whs} a 
      where a.id='${id}'`,
      []
    )
    .then((res) => {
     
      if (res.rows.item(0).nmwh==null){
        return {

          nmwh: "",
               
        
        }
      }
      else{

        return {

          nmwh: res.rows.item(0).nmwh,
       
        }
      
    }
      
    })
    .catch((e) => {
      return "error on Get Vendor " + JSON.stringify(e);
    });
}




async getFormCompany() {
  return this.databaseObj
    .executeSql(
      `SELECT * FROM ${this.tables.companies}`,
      []
    )
    .then((res) => {
     
      if (res.rows.item(0).id==null){
        return {

          id: "",
          name: "", 
          address: "",
          city: "", 
          province: "",
          zipcode: "",
          email: "",
          website: "",
          phone:"",
          dir: "",
          editMode: '0',
          npwp: "",
        
        }
      }
      else{

        return {

        id: res.rows.item(0).id,
        name: res.rows.item(0).name,  
        address: res.rows.item(0).address,
        city: res.rows.item(0).city,
        province: res.rows.item(0).province,
        zipcode: res.rows.item(0).zipcode,
        email: res.rows.item(0).email,
        website: res.rows.item(0).website,
        phone: res.rows.item(0).phone,
        dir: res.rows.item(0).dir,
        npwp: res.rows.item(0).npwp,
        editMode: '1',
        }
      
    }
      
    })
    .catch((e) => {
      return "error on Get Company " + JSON.stringify(e);
    });
}


async getProductID(kd) {
  return this.databaseObj
    .executeSql(
      `SELECT a.id
      FROM ${this.tables.products} a 
      where a.kdproduct='${kd}'`,
      []
    )
    .then((res) => {
     
      if (res.rows.item(0).id==null){
        return {

          id: "",
             
        
        }
      }
      else{

        return {

        id: res.rows.item(0).id,
        editMode: '1',
        }
      
    }
      
    })
    .catch((e) => {
      return "error on Get Product ID " + JSON.stringify(e);
    });
}


async getProductDesc(id) {
  return this.databaseObj
    .executeSql(
      `SELECT a.id,a.kdproduct,a.nmproduct,a.spek,b.nmunit,a.hargabeli
      FROM ${this.tables.products} a 
      inner join ${this.tables.units} b on a.unit_id=b.id
      where a.id='${id}'`,
      []
    )
    .then((res) => {
     
      if (res.rows.item(0).id==null){
        return {

          id: "",
          kdproduct: "", 
          nmproduct: "", 
          spek: "",
          hargabeli: "",
          nmunit: "",
       
        
        }
      }
      else{

        return {

        id: res.rows.item(0).id,
        kdproduct: res.rows.item(0).kdproduct,  
        nmproduct: res.rows.item(0).nmproduct,
        spek: res.rows.item(0).spek,
        hargabeli: res.rows.item(0).hargabeli,
        nmunit: res.rows.item(0).nmunit,
        editMode: '1',
        }
      
    }
      
    })
    .catch((e) => {
      return "error on Get Product " + JSON.stringify(e);
    });
}


async cekStok(id: number, qty: number) {
  return this.databaseObj
    .executeSql(
      `SELECT id
      FROM ${this.tables.products} where id=${id} and qty>=${qty}`,
      []
    )
    .then((res) => {
     
      if (res.rows.length>0){
       
        return "Y"
      
      }else{

        return "N"

      }
      
    })
    .catch((e) => {
      return "error on Get Product " + JSON.stringify(e);
    });
}



async cekSecurity() {
  return this.databaseObj
    .executeSql(
      `SELECT jumlah
      FROM ${this.tables.securities} where id=1`,
      []
    )
    .then((res) => {
     
     

        return {

          jumlah: res.rows.item(0).jumlah,
       
        }
      
    
      
    })
    .catch((e) => {
      return "error on Get Product " + JSON.stringify(e);
    });
}


async getHTDesc(id) {
  return this.databaseObj
    .executeSql(
      `SELECT a.id,a.noht,a.htdate,a.grandht,a.paidht,b.nmpartner 
      FROM ${this.tables.terimas} a 
      inner join ${this.tables.belis} x on a.beli_id=x.id
      inner join ${this.tables.partners} b on x.vendor_id=b.id
      where a.id='${id}'`,
      []
    )
    .then((res) => {
     
      if (res.rows.item(0).id==null){
        return {

          id: "",
          noht: "", 
          htdate: "", 
          grandht: "0",
          paidht: "0",
          nmpartner: "",
        
        }
      }
      else{

        return {

        id: res.rows.item(0).id,
        noht: res.rows.item(0).noht,  
        htdate: res.rows.item(0).htdate,
        grandht: res.rows.item(0).grandht,
        paidht: res.rows.item(0).paidht,
        nmpartner: res.rows.item(0).nmpartner,
        editMode: '1',
        }
      
    }
      
    })
    .catch((e) => {
      return "error on Get Product " + JSON.stringify(e);
    });
}


async getPTDesc(id) {
  return this.databaseObj
    .executeSql(
      `SELECT a.id,a.nopt,a.ptdate,a.grandpt,a.paidpt,b.nmpartner 
      FROM ${this.tables.kirims} a 
      inner join ${this.tables.juals} x on a.jual_id=x.id
      inner join ${this.tables.partners} b on x.cust_id=b.id
      where a.id='${id}'`,
      []
    )
    .then((res) => {
     
      if (res.rows.item(0).id==null){
        return {

          id: "",
          nopt: "", 
          ptdate: "", 
          grandpt: "0",
          paidpt: "0",
          nmpartner: "",
        
        }
      }
      else{

        return {

        id: res.rows.item(0).id,
        nopt: res.rows.item(0).nopt,  
        ptdate: res.rows.item(0).ptdate,
        grandpt: res.rows.item(0).grandpt,
        paidpt: res.rows.item(0).paidpt,
        nmpartner: res.rows.item(0).nmpartner,
        editMode: '1',
        }
      
    }
      
    })
    .catch((e) => {
      return "error on Get Product " + JSON.stringify(e);
    });
}


async getKirimID(id) {

 
  return this.databaseObj
    .executeSql(
      `SELECT id FROM ${this.tables.kirims} 
      where jual_id=${id}`,
      []
    )
    .then((res) => {

      return res.rows.item(0).id;
           
    })
    .catch((e) => {
      return "error on getting Transaction " + JSON.stringify(e);
    });
}



async getTerimaID(id) {

 
  return this.databaseObj
    .executeSql(
      `SELECT id FROM ${this.tables.terimas} 
      where beli_id=${id}`,
      []
    )
    .then((res) => {

      return res.rows.item(0).id;
           
    })
    .catch((e) => {
      return "error on getting Transaction " + JSON.stringify(e);
    });
}



async getWhID(id) {

 
  return this.databaseObj
    .executeSql(
      `SELECT id FROM ${this.tables.whs} 
      where branch_id=${id}`,
      []
    )
    .then((res) => {

      return res.rows.item(0).id;
           
    })
    .catch((e) => {
      return "error on getting Wh ID " + JSON.stringify(e);
    });
}


async getWHNo(id) {

  return this.databaseObj
    .executeSql(
      `SELECT nowhout FROM ${this.tables.whouts} 
      where id=${id}`,
      []
    )
    .then((res) => {

      return res.rows.item(0).nowhout;
           
    })
    .catch((e) => {
      return "error on getting Wh No. " + JSON.stringify(e);
    });
}


async getWHNi(id) {

  return this.databaseObj
    .executeSql(
      `SELECT nowhin FROM ${this.tables.whins} 
      where id=${id}`,
      []
    )
    .then((res) => {

      return res.rows.item(0).nowhin;
           
    })
    .catch((e) => {
      return "error on getting Wh No. " + JSON.stringify(e);
    });
}


async getWhoutID(nowhout) {

 
  return this.databaseObj
    .executeSql(
      `SELECT id FROM ${this.tables.whouts} 
      where nowhout='${nowhout}'`,
      []
    )
    .then((res) => {

      return res.rows.item(0).id;
           
    })
    .catch((e) => {
      return "error on getting Wh Out ID " + JSON.stringify(e);
    });
}


async getWhinID(nowhin) {

 
  return this.databaseObj
    .executeSql(
      `SELECT id FROM ${this.tables.whins} 
      where nowhin='${nowhin}'`,
      []
    )
    .then((res) => {

      return res.rows.item(0).id;
           
    })
    .catch((e) => {
      return "error on getting Wh In ID " + JSON.stringify(e);
    });
}

async getBkoutID(nobkout) {

 
  return this.databaseObj
    .executeSql(
      `SELECT id FROM ${this.tables.bkouts} 
      where nobkout='${nobkout}'`,
      []
    )
    .then((res) => {

      return res.rows.item(0).id;
           
    })
    .catch((e) => {
      return "error on getting Bkout ID " + JSON.stringify(e);
    });
}


async maxJob() {

  var tg = new Date();
 
  var tahun = tg.getFullYear();
  var bln = tg.getMonth()+1;
  var bulan;


  if (bln<10){
    bulan="0"+bln.toString();
  }else{
    bulan=bln.toString();
  }

  return this.databaseObj
    .executeSql(
      `SELECT max(substr(nojob,9,4)) as lastno,strftime('%m',jobdate) as mth FROM ${this.tables.joborders} 
      where strftime('%Y',jobdate)='${tahun}'  and strftime('%m',jobdate)='${bulan}'`,
      []
    )
    .then((res) => {

      
      var angka: string;
      var lth: number;
      var result: number;
      var prefix: string;

     
      result= parseInt(res.rows.item(0).lastno)+1;
      angka= result.toString();

      prefix="JB";
     

      if (res.rows.item(0).lastno==null || res.rows.item(0).lastno==''){
          return prefix+ tahun+bulan+"0001";
         
      }
      else{

        var str= "0";
        
        lth=angka.length;
        return prefix+ tahun + bulan + str.repeat(4-lth)+ result;
    
 
      }
      
    })
    .catch((e) => {
      return "error on getting Transaction " + JSON.stringify(e);
    });
}


async getSelisih(id) {


  return this.databaseObj
    .executeSql(
      `SELECT qty FROM ${this.tables.products} 
      where id='${id}'`,
      []
    )
    .then((res) => {

      
      if (res.rows.item(0).qty==null || res.rows.item(0).qty==''){
          return 0;
         
      }
      else{

        return res.rows.item(0).qty;
    
 
      }
      
    })
    .catch((e) => {
      return "error on getting Transaction " + JSON.stringify(e);
    });
}

async maxMutasi() {

  var tg = new Date();
 
  var tahun = tg.getFullYear();
  var bln = tg.getMonth()+1;
  var bulan;


  if (bln<10){
    bulan="0"+bln.toString();
  }else{
    bulan=bln.toString();
  }

  return this.databaseObj
    .executeSql(
      `SELECT max(substr(nomutasi,9,4)) as lastno,strftime('%m',mutasidate) as mth FROM ${this.tables.mutasis} 
      where strftime('%Y',mutasidate)='${tahun}'  and strftime('%m',mutasidate)='${bulan}'`,
      []
    )
    .then((res) => {

      
      var angka: string;
      var lth: number;
      var result: number;
      var prefix: string;

     
      result= parseInt(res.rows.item(0).lastno)+1;
      angka= result.toString();

      prefix="MU";
     

      if (res.rows.item(0).lastno==null || res.rows.item(0).lastno==''){
          return prefix+ tahun+bulan+"0001";
         
      }
      else{

        var str= "0";
        
        lth=angka.length;
        return prefix+ tahun + bulan + str.repeat(4-lth)+ result;
    
 
      }
      
    })
    .catch((e) => {
      return "error on getting Transaction " + JSON.stringify(e);
    });
}


async maxSto() {

  var tg = new Date();
 
  var tahun = tg.getFullYear();
  var bln = tg.getMonth()+1;
  var bulan;


  if (bln<10){
    bulan="0"+bln.toString();
  }else{
    bulan=bln.toString();
  }

  return this.databaseObj
    .executeSql(
      `SELECT max(substr(nosto,9,4)) as lastno,strftime('%m',stodate) as mth FROM ${this.tables.stos} 
      where strftime('%Y',stodate)='${tahun}'  and strftime('%m',stodate)='${bulan}'`,
      []
    )
    .then((res) => {

      
      var angka: string;
      var lth: number;
      var result: number;
      var prefix: string;

     
      result= parseInt(res.rows.item(0).lastno)+1;
      angka= result.toString();

      prefix="ST";
     

      if (res.rows.item(0).lastno==null || res.rows.item(0).lastno==''){
          return prefix+ tahun+bulan+"0001";
         
      }
      else{

        var str= "0";
        
        lth=angka.length;
        return prefix+ tahun + bulan + str.repeat(4-lth)+ result;
    
 
      }
      
    })
    .catch((e) => {
      return "error on getting Transaction " + JSON.stringify(e);
    });
}

async maxBkout() {

  var tg = new Date();
 
  var tahun = tg.getFullYear();
  var bln = tg.getMonth()+1;
  var bulan;


  if (bln<10){
    bulan="0"+bln.toString();
  }else{
    bulan=bln.toString();
  }

  return this.databaseObj
    .executeSql(
      `SELECT max(substr(nobkout,9,4)) as lastno,strftime('%m',bkoutdate) as mth FROM ${this.tables.bkouts} 
      where strftime('%Y',bkoutdate)='${tahun}'  and strftime('%m',bkoutdate)='${bulan}'`,
      []
    )
    .then((res) => {

      
      var angka: string;
      var lth: number;
      var result: number;
      var prefix: string;

     
      result= parseInt(res.rows.item(0).lastno)+1;
      angka= result.toString();

      prefix="BO";
     

      if (res.rows.item(0).lastno==null || res.rows.item(0).lastno==''){
          return prefix+ tahun+bulan+"0001";
         
      }
      else{

        var str= "0";
        
        lth=angka.length;
        return prefix+ tahun + bulan + str.repeat(4-lth)+ result;
    
 
      }
      
    })
    .catch((e) => {
      return "error on getting Transaction " + JSON.stringify(e);
    });
}



async maxBkin() {

  var tg = new Date();
 
  var tahun = tg.getFullYear();
  var bln = tg.getMonth()+1;
  var bulan;


  if (bln<10){
    bulan="0"+bln.toString();
  }else{
    bulan=bln.toString();
  }

  return this.databaseObj
    .executeSql(
      `SELECT max(substr(nobkin,9,4)) as lastno,strftime('%m',bkindate) as mth FROM ${this.tables.bkins} 
      where strftime('%Y',bkindate)='${tahun}'  and strftime('%m',bkindate)='${bulan}'`,
      []
    )
    .then((res) => {

      
      var angka: string;
      var lth: number;
      var result: number;
      var prefix: string;

     
      result= parseInt(res.rows.item(0).lastno)+1;
      angka= result.toString();

      prefix="BI";
     

      if (res.rows.item(0).lastno==null || res.rows.item(0).lastno==''){
          return prefix+ tahun+bulan+"0001";
         
      }
      else{

        var str= "0";
        
        lth=angka.length;
        return prefix+ tahun + bulan + str.repeat(4-lth)+ result;
    
 
      }
      
    })
    .catch((e) => {
      return "error on getting Transaction " + JSON.stringify(e);
    });
}



async maxBeli(tr) {

  var tg = new Date();
 
  var tahun = tg.getFullYear();
  var bln = tg.getMonth()+1;
  var bulan;


  if (bln<10){
    bulan="0"+bln.toString();
  }else{
    bulan=bln.toString();
  }

  return this.databaseObj
    .executeSql(
      `SELECT max(substr(nopo,9,4)) as lastno,strftime('%m',podate) as mth FROM ${this.tables.belis} 
      where strftime('%Y',podate)='${tahun}'  and strftime('%m',podate)='${bulan}' and jenis='${tr}'`,
      []
    )
    .then((res) => {

      
      var angka: string;
      var lth: number;
      var result: number;
      var prefix: string;

     
      result= parseInt(res.rows.item(0).lastno)+1;
      angka= result.toString();

      if (tr=='po'){
        prefix="PO";
      }
      else{
        prefix="RT";
      }

      if (res.rows.item(0).lastno==null || res.rows.item(0).lastno==''){
          return prefix+ tahun+bulan+"0001";
         
      }
      else{

        var str= "0";
        
        lth=angka.length;
        return prefix+ tahun + bulan + str.repeat(4-lth)+ result;
 
      }
      
    })
    .catch((e) => {
      return "error on getting Transaction " + JSON.stringify(e);
    });
}

async maxSimpan(tr) {

  var tg = new Date();
 
  var tahun = tg.getFullYear();
  var bln = tg.getMonth()+1;
  var bulan;


  if (bln<10){
    bulan="0"+bln.toString();
  }else{
    bulan=bln.toString();
  }

  return this.databaseObj
    .executeSql(
      `SELECT max(substr(nosimpan,9,4)) as lastno,strftime('%m',simpandate) as mth FROM ${this.tables.simpans} 
      where strftime('%Y',simpandate)='${tahun}'  and strftime('%m',simpandate)='${bulan}' and tr='${tr}'`,
      []
    )
    .then((res) => {

      
      var angka: string;
      var lth: number;
      var result: number;
      var prefix: string;

     
      result= parseInt(res.rows.item(0).lastno)+1;
      angka= result.toString();

      if (tr=='Pokok'){
        prefix="PK";
      }
      else if (tr=='Wajib'){
        prefix="WJ";
      }
      else if (tr=='Sukarela'){
        prefix="SR";
      }

      if (res.rows.item(0).lastno==null || res.rows.item(0).lastno==''){
          return prefix+ tahun+bulan+"0001";
         
      }
      else{

        var str= "0";
        
        lth=angka.length;
        return prefix+ tahun + bulan + str.repeat(4-lth)+ result;
 
      }
      
    })
    .catch((e) => {
      return "error on getting Transaction " + JSON.stringify(e);
    });
}


async grandHT(id:number) {

  return this.databaseObj
    .executeSql(
      `SELECT case when b.qty is null then 0 else sum(b.qty*x.kurs*b.harga-b.diskon) + (sum(b.qty*x.kurs*b.harga-b.diskon) * (0.11)) end total 
      FROM ${this.tables.terimas} a 
      inner join ${this.tables.belis} x on a.beli_id=x.id
      inner join ${this.tables.terimadets} b on a.id=b.terima_id
      inner join ${this.tables.products} c on b.product_id=c.id
      inner join ${this.tables.partners} d on x.vendor_id=d.id
      where a.id=${id}`,
      []
    )
    .then((res) => {

          
      return parseInt(res.rows.item(0).total);
     
      
    })
    .catch((e) => {
      return "error on getting Transaction " + JSON.stringify(e);
    });
}



async grandPT(id:number) {

  return this.databaseObj
    .executeSql(
      `SELECT case when b.qty is null then 0 else sum(b.qty*x.kurs*b.harga-b.diskon) + (sum(b.qty*x.kurs*b.harga-b.diskon) * (0.11)) end total 
      FROM ${this.tables.kirims} a 
      inner join ${this.tables.juals} x on a.jual_id=x.id
      inner join ${this.tables.kirimdets} b on a.id=b.kirim_id
      inner join ${this.tables.products} c on b.product_id=c.id
      inner join ${this.tables.partners} d on x.cust_id=d.id
      where a.id=${id}`,
      []
    )
    .then((res) => {

          
      return parseInt(res.rows.item(0).total);
     
      
    })
    .catch((e) => {
      return "error on getting Transaction " + JSON.stringify(e);
    });
}




async maxPiutang() {

  var tg = new Date();
 
  var tahun = tg.getFullYear();
  var bln = tg.getMonth()+1;
  var bulan;


  if (bln<10){
    bulan="0"+bln.toString();
  }else{
    bulan=bln.toString();
  }

  return this.databaseObj
  .executeSql(
      `SELECT max(substr(nopt,9,4)) as lastno,strftime('%m',ptdate) as mth FROM ${this.tables.kirims} 
      where strftime('%Y',ptdate)='${tahun}'  and strftime('%m',ptdate)='${bulan}'`,
      []
  )
    .then((res) => {

      
      var angka: string;
      var lth: number;
      var result: number;
      var prefix: string;

     
      result= parseInt(res.rows.item(0).lastno)+1;
      angka= result.toString();

      prefix="IR";
     
      if (res.rows.item(0).lastno==null || res.rows.item(0).lastno==''){
          return prefix+ tahun+bulan+"0001";
         
      }
      else{

        var str= "0";
        
        lth=angka.length;
        return prefix+ tahun + bulan + str.repeat(4-lth)+ result;
    
 
      }
      
    })
    .catch((e) => {
      return "error on getting Transaction " + JSON.stringify(e);
    });
}




async maxHutang() {

  var tg = new Date();
 
  var tahun = tg.getFullYear();
  var bln = tg.getMonth()+1;
  var bulan;


  if (bln<10){
    bulan="0"+bln.toString();
  }else{
    bulan=bln.toString();
  }

  return this.databaseObj
    .executeSql(
      `SELECT max(substr(a.noht,9,4)) as lastno,strftime('%m',a.htdate) as mth 
      FROM ${this.tables.terimas} a 
      inner join ${this.tables.belis} b on a.beli_id=b.id
      where strftime('%Y',a.htdate)='${tahun}'  
      and strftime('%m',a.htdate)='${bulan}' and b.tp='Cash'`,
      []
    )
    .then((res) => {

      
      var angka: string;
      var lth: number;
      var result: number;
      var prefix: string;

     
      result= parseInt(res.rows.item(0).lastno)+1;
      angka= result.toString();

      prefix="IP";
     
      if (res.rows.item(0).lastno==null || res.rows.item(0).lastno==''){
          return prefix+ tahun+bulan+"0001";
         
      }
      else{

        var str= "0";
        
        lth=angka.length;
        return prefix+ tahun + bulan + str.repeat(4-lth)+ result;
    
 
      }
      
    })
    .catch((e) => {
      return "error on getting Transaction " + JSON.stringify(e);
    });
}

async totJobHpp(nojob) {

  
  return this.databaseObj
    .executeSql(
      `SELECT sum(b.harga * a.kurs * b.qty) as total  FROM ${this.tables.joborders} a inner join  ${this.tables.joborderdets} b
      on a.id=b.job_id
      where a.nojob='${nojob}'`,
      []
    )
    .then((res) => {
     
      if (res.rows.item(0).total==null || res.rows.item(0).total==''){
          return 0;
         
      }
      else{

         return parseInt(res.rows.item(0).total);
    
 
      }
      
    })
    .catch((e) => {
      return "error on getting Transaction " + JSON.stringify(e);
    });
}



async maxTerima() {

  var tg = new Date();
 
  var tahun = tg.getFullYear();
  var bln = tg.getMonth()+1;
  var bulan;


  if (bln<10){
    bulan="0"+bln.toString();
  }else{
    bulan=bln.toString();
  }

  return this.databaseObj
    .executeSql(
      `SELECT max(substr(nogr,9,4)) as lastno,strftime('%m',grdate) as mth FROM ${this.tables.terimas} 
      where strftime('%Y',grdate)='${tahun}'  and strftime('%m',grdate)='${bulan}'`,
      []
    )
    .then((res) => {

      
      var angka: string;
      var lth: number;
      var result: number;
      var prefix: string;

     
      result= parseInt(res.rows.item(0).lastno)+1;
      angka= result.toString();

      prefix="GR";
     
      if (res.rows.item(0).lastno==null || res.rows.item(0).lastno==''){
          return prefix+ tahun+bulan+"0001";
         
      }
      else{

        var str= "0";
        
        lth=angka.length;
        return prefix+ tahun + bulan + str.repeat(4-lth)+ result;
    
 
      }
      
    })
    .catch((e) => {
      return "error on getting Transaction " + JSON.stringify(e);
    });
}


async maxKirim() {

  var tg = new Date();
 
  var tahun = tg.getFullYear();
  var bln = tg.getMonth()+1;
  var bulan;


  if (bln<10){
    bulan="0"+bln.toString();
  }else{
    bulan=bln.toString();
  }

  return this.databaseObj
    .executeSql(
      `SELECT max(substr(nodo,9,4)) as lastno,strftime('%m',dodate) as mth FROM ${this.tables.kirims} 
      where strftime('%Y',dodate)='${tahun}'  and strftime('%m',dodate)='${bulan}'`,
      []
    )
    .then((res) => {

      
      var angka: string;
      var lth: number;
      var result: number;
      var prefix: string;

     
      result= parseInt(res.rows.item(0).lastno)+1;
      angka= result.toString();

      prefix="DO";
     
      if (res.rows.item(0).lastno==null || res.rows.item(0).lastno==''){
          return prefix+ tahun+bulan+"0001";
         
      }
      else{

        var str= "0";
        
        lth=angka.length;
        return prefix+ tahun + bulan + str.repeat(4-lth)+ result;
    
 
      }
      
    })
    .catch((e) => {
      return "error on getting Transaction " + JSON.stringify(e);
    });
}



async getListBelis(stat:string) {
  return this.databaseObj

    // where a.stat like  '%${stat}%' and a.jenis='po'
    // strftime ('%Y-%m-%d', a.podate) as podate 
    .executeSql(
      `SELECT a.id, a.nopo, strftime ('%Y-%m-%d', a.podate) as podate , a.vendor_id,
      b.kdpartner,b.nmpartner,b.address,b.city,b.zipcode,a.stat,a.tp ,a.forex_id,a.kurs,
      e.simbol,a.branch_id,case when c.qty is null then 0 else sum(c.qty*a.kurs*d.hargabeli) end total
      FROM belis a
      INNER JOIN partners b ON b.id = a.vendor_id 
      LEFT JOIN belidets c on a.id=c.beli_id 
      LEFT JOIN products d on c.product_id=d.id 
      left JOIN forexs e ON a.forex_id = e.id 
      left JOIN terimas f ON a.id = f.beli_id 
      where a.stat='${stat}' and f.beli_id is null
      group by a.id,a.nopo,a.podate , a.vendor_id,
      b.kdpartner,b.nmpartner,b.address,b.city,b.zipcode,a.stat,a.tp ,a.forex_id,a.kurs,e.simbol,a.branch_id
      ORDER BY a.id DESC`,
      []
    )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return "error on getting Data " + JSON.stringify(e);
    });
}



 async getBkouts(startdate: any, enddate: any) {
  return this.databaseObj
   
    .executeSql(
      `SELECT a.id, a.nobkout,strftime('%Y-%m-%d', a.bkoutdate) as bkoutdate , a.vendor_id,
      b.kdpartner,b.nmpartner,b.address,b.city,b.zipcode,a.stat,a.tp, a.pf,a.rek_id,f.norek
       ,a.forex_id,a.kurs,e.simbol,c.bkout_id,
      case when c.amount is null then 0 else sum(c.amount*a.kurs) end total
      FROM bkouts a
      INNER JOIN partners b ON b.id = a.vendor_id 
      LEFT JOIN bkoutdets c on a.id=c.bkout_id 
      left JOIN forexs e ON a.forex_id = e.id 
      left JOIN reks f ON a.rek_id = f.id 
      where strftime('%Y-%m-%d', a.bkoutdate) between '${startdate}' and '${enddate}'
      group by a.id,a.nobkout,a.bkoutdate , a.vendor_id,
      b.kdpartner,b.nmpartner,b.address,b.city,b.zipcode,a.stat,a.tp,a.pf,a.rek_id,f.norek ,a.forex_id,a.kurs,e.simbol,c.bkout_id
      ORDER BY a.id DESC`,
      []
    )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return "error on getting Data " + JSON.stringify(e);
    });
}


async rptSumBankouts(startdate: any, enddate: any) {
  return this.databaseObj
   
    .executeSql(
      `SELECT a.nobkout as [BK No.],strftime('%Y-%m-%d', a.bkoutdate) as [BK Date] ,b.kdpartner as [ID Vendor],
      b.nmpartner as [Vendor Name],b.address || ' ' || b.city || ' ' || b.zipcode as Address,a.stat as Status,
      a.tp as Source, a.pf as Type,f.norek as [Rek No.],
      case when c.amount is null then 0 else sum(c.amount*a.kurs) end Total
      FROM bkouts a
      INNER JOIN partners b ON b.id = a.vendor_id 
      LEFT JOIN bkoutdets c on a.id=c.bkout_id 
      left JOIN forexs e ON a.forex_id = e.id 
      left JOIN reks f ON a.rek_id = f.id 
      where strftime('%Y-%m-%d', a.bkoutdate) between '${startdate}' and '${enddate}'
      group by a.nobkout,a.bkoutdate ,b.kdpartner,b.nmpartner,b.address,b.city,b.zipcode,a.stat,a.tp,a.pf,f.norek 
      ORDER BY a.id DESC`,
      []
    )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return "error on getting Data " + JSON.stringify(e);
    });
}

async getBkins(startdate: any, enddate: any) {
  return this.databaseObj
   
    .executeSql(
      `SELECT a.id, a.nobkin,strftime('%Y-%m-%d', a.bkindate) as bkindate,a.cust_id,
      b.kdpartner,b.nmpartner,b.address,b.city,b.zipcode,a.stat,a.tp, a.pf,a.rek_id,f.norek
       ,a.forex_id,a.kurs,e.simbol,c.bkin_id,
      case when c.amount is null then 0 else sum(c.amount*a.kurs) end total
      FROM bkins a
      INNER JOIN partners b ON b.id = a.cust_id 
      LEFT JOIN bkindets c on a.id=c.bkin_id 
      left JOIN forexs e ON a.forex_id = e.id 
      left JOIN reks f ON a.rek_id = f.id 
      where strftime('%Y-%m-%d', a.bkindate) between '${startdate}' and '${enddate}'
      group by a.id,a.nobkin,a.bkindate, a.cust_id,
      b.kdpartner,b.nmpartner,b.address,b.city,b.zipcode,a.stat,a.tp,a.pf,a.rek_id,f.norek ,a.forex_id,a.kurs,e.simbol,c.bkin_id
      ORDER BY a.id DESC`,
      []
    )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return "error on getting Data " + JSON.stringify(e);
    });
}


async rptSumBankins(startdate: any, enddate: any) {
  return this.databaseObj
   
    .executeSql(
      `SELECT a.nobkin as [BK No],strftime('%Y-%m-%d', a.bkindate) as [BK Date],b.kdpartner as [ID Cust]
      ,b.nmpartner as [Cust Name],b.address || ' ' || b.city || ' ' || b.zipcode as Address, a.stat as Status ,a.tp as Source, a.pf as Type,
      f.norek as [Rek No.],case when c.amount is null then 0 else sum(c.amount*a.kurs) end Total
      FROM bkins a
      INNER JOIN partners b ON b.id = a.cust_id 
      LEFT JOIN bkindets c on a.id=c.bkin_id 
      left JOIN forexs e ON a.forex_id = e.id 
      left JOIN reks f ON a.rek_id = f.id 
      where strftime('%Y-%m-%d', a.bkindate) between '${startdate}' and '${enddate}'
      group by a.nobkin,a.bkindate,b.kdpartner,b.nmpartner,b.address,b.city,b.zipcode,a.stat,a.tp,a.pf,f.norek       
      ORDER BY a.id DESC`,
      []
    )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return "error on getting Data " + JSON.stringify(e);
    });
}



async getJoborders(startdate: any, enddate: any) {
  return this.databaseObj
   
    .executeSql(
      `SELECT a.id, a.nojob,strftime('%Y-%m-%d', a.jobdate) as jobdate,a.wh_id,b.nmwh,
      a.status,a.product_fg_id,f.kdproduct,f.nmproduct,a.target,a.final,a.reject
       ,a.forex_id,a.kurs,e.simbol,a.finishdate,a.hpp,a.hargajual,a.status,a.cs
      FROM joborders a
      INNER JOIN whs b ON b.id = a.wh_id 
      left JOIN forexs e ON a.forex_id = e.id 
      left JOIN products f ON a.product_fg_id = f.id 
      where strftime('%Y-%m-%d', a.jobdate) between '${startdate}' and '${enddate}'
      ORDER BY a.id DESC`,
      []
    )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return "error on getting Data " + JSON.stringify(e);
    });
}


async getMutasis(startdate: any, enddate: any) {
  return this.databaseObj
   
    .executeSql(
      `SELECT a.id, a.nomutasi,strftime('%Y-%m-%d', a.mutasidate) as mutasidate,a.wh_id,b.nmwh,a.wh_id2,f.nmwh as nmwh2,
      a.status,a.forex_id,a.kurs,e.simbol
      FROM mutasis a
      INNER JOIN whs b ON b.id = a.wh_id 
      left JOIN forexs e ON a.forex_id = e.id 
      left JOIN whs f ON a.wh_id2 = f.id 
      where strftime('%Y-%m-%d', a.mutasidate) between '${startdate}' and '${enddate}'
      ORDER BY a.id DESC`,
      []
    )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return "error on getting Data " + JSON.stringify(e);
    });
}


async getStos(startdate: any, enddate: any) {
  return this.databaseObj
   
    .executeSql(
      `SELECT a.id, a.nosto,strftime('%Y-%m-%d', a.stodate) as stodate,a.wh_id,b.nmwh,
      a.status,case when sum(c.selisih)>0 then 'Difference' else 'Complete' end ket
      FROM stos a
      INNER JOIN whs b ON b.id = a.wh_id 
      left JOIN stodets c ON a.id = c.sto_id 
      where strftime('%Y-%m-%d', a.stodate) between '${startdate}' and '${enddate}'
      group by a.id, a.nosto, a.stodate,a.wh_id,b.nmwh,a.status
      ORDER BY a.id DESC`,
      []
    )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return "error on getting Data " + JSON.stringify(e);
    });
}



async getBelis(stat:string,tr: string, startdate: any, enddate: any) {
  return this.databaseObj

    // where a.stat like  '%${stat}%' and a.jenis='po'
    //strftime ('%Y-%m-%d', a.podate) as 
    .executeSql(
      `SELECT a.id, a.nopo,strftime('%Y-%m-%d', a.podate) as podate , a.vendor_id,
      b.kdpartner,b.nmpartner,b.address,b.city,b.zipcode,a.stat,a.tp ,a.forex_id,a.kurs,
      e.simbol,f.beli_id,a.branch_id,g.nmbranch,case when c.qty is null then 0 else 
      sum(c.qty*a.kurs*(d.hargabeli-d.diskon)) end total
      FROM belis a
      INNER JOIN partners b ON b.id = a.vendor_id 
      LEFT JOIN belidets c on a.id=c.beli_id 
      LEFT JOIN products d on c.product_id=d.id 
      left JOIN forexs e ON a.forex_id = e.id 
      left JOIN terimas f ON a.id = f.beli_id 
      left JOIN branches g ON a.branch_id = g.id 
      where a.jenis='${tr}' and strftime('%Y-%m-%d', a.podate) between '${startdate}' and '${enddate}'
      group by a.id,a.nopo,a.podate , a.vendor_id,
      b.kdpartner,b.nmpartner,b.address,b.city,b.zipcode,a.stat,a.tp ,a.forex_id,a.kurs,
      e.simbol,f.beli_id,a.branch_id,g.nmbranch
      ORDER BY a.id DESC`,
      []
    )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return "error on getting Data " + JSON.stringify(e);
    });
}


async getSimpans(tr: string, startdate: any, enddate: any) {
  return this.databaseObj


    .executeSql(
      `SELECT a.id, a.nosimpan,strftime('%Y-%m-%d', a.simpandate) as simpandate , a.user_id,
      b.kduser,b.nmuser,b.address,b.city,b.zipcode,b.phone,b.ktp,b.npwp,b.email,amount,a.stat,a.tr
      FROM simpans a
      INNER JOIN users b ON b.id = a.user_id 
      where a.tr='${tr}' and strftime('%Y-%m-%d', a.simpandate) between '${startdate}' and '${enddate}'
       ORDER BY a.id DESC`,
      []
    )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return "error on getting Data " + JSON.stringify(e);
    });
}



async rptSumBelis(startdate: any, enddate: any,tr: string) {
  return this.databaseObj

    .executeSql(
      `SELECT a.id, a.nopo as [No.PO],strftime('%Y-%m-%d', a.podate) as [Po Date] ,b.kdpartner as [ID Vendor],b.nmpartner as [Vendor Name],
      b.address || ' ' || b.city || ' ' || b.zipcode as Address,a.stat as [Status],g.nmbranch as [Branch],case when c.qty is null then 0 else 
      sum(c.qty*a.kurs*(d.hargabeli-d.diskon)) + (sum(c.qty*a.kurs*(d.hargabeli-d.diskon)) * 0.11) end Total
      FROM belis a
      INNER JOIN partners b ON b.id = a.vendor_id 
      LEFT JOIN belidets c on a.id=c.beli_id 
      LEFT JOIN products d on c.product_id=d.id 
      left JOIN forexs e ON a.forex_id = e.id 
      left JOIN terimas f ON a.id = f.beli_id 
      left JOIN branches g ON a.branch_id = g.id 
      where strftime('%Y-%m-%d', a.podate) between '${startdate}' and '${enddate}' and a.jenis='${tr}'
      group by a.id,a.nopo,a.podate,b.kdpartner,b.nmpartner,b.address,b.city,b.zipcode,a.stat,g.nmbranch
      ORDER BY a.id DESC`,
      []
    )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return "error on getting Data " + JSON.stringify(e);
    });
}


async rptSumSimpans(startdate: any, enddate: any,tr: string) {
  return this.databaseObj

    .executeSql(
      `SELECT a.id, a.nosimpan as [Nomor],strftime('%Y-%m-%d', a.simpandate) as [Date] ,b.kduser as [ID User],b.nmuser as [Member Name],
      b.address || ' ' || b.city || ' ' || b.zipcode as Address,a.tr,a.stat as [Status],a.amount
      FROM simpans a
      INNER JOIN users b ON b.id = a.user_id 
      where strftime('%Y-%m-%d', a.simpandate) between '${startdate}' and '${enddate}' and a.tr='${tr}'
      ORDER BY a.id DESC`,
      []
    )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return "error on getting Data " + JSON.stringify(e);
    });
}


    
async addPOHeader(id: number, branch_id: number) {

  var nogr = await this.maxTerima();
  var now= await this.convertDate();
  var wh_id= await this.getWhID(branch_id);



  return this.databaseObj
    .executeSql(`insert into terimas (nogr,grdate,beli_id,stat,noht,htdate,wh_id,refno,penerima,nowhin,lastupdate,lastuser) VALUES ('${nogr}','${now}', ${id},'Draft','INV00-','${now}','${wh_id}','-','-','None','${now}',1)`, [])
    .then(() => {
      return "Receved was Approved";
    })
    .catch((e) => {
      return "error on Approving Transaction " + JSON.stringify(e);
    });
}


async addSOHeader(id: number, branch_id: number) {

  var nodo = await this.maxKirim();
  var now= await this.convertDate();
  var wh_id= await this.getWhID(branch_id);

  return this.databaseObj
    .executeSql(`insert into kirims (nodo,dodate,jual_id,stat,wh_id,refno,pengirim,nowhout,lastupdate,lastuser) VALUES ('${nodo}','${now}', ${id},'Draft','${wh_id}','-','-','None','${now}',1)`, [])
    .then(() => {
      return "Delivery order was Approved";
    })
    .catch((e) => {
      return "error on Approving Transaction " + JSON.stringify(e);
    });
}




async addPODetail(id: number) {

  var terima_id = await this.getTerimaID(id);
  var now= await this.convertDate();

  return this.databaseObj
    .executeSql(`insert into terimadets (terima_id,product_id,qty,harga,diskon,lastupdate,lastuser) 
    select ${terima_id} as terima_id,a.product_id,a.qty,b.hargabeli,b.diskon,'${now}' as lastupdate,1 as lastuser 
    from belidets a 
    inner join products b on a.product_id=b.id
    where a.beli_id=${id}`, [])
    .then(() => {
      return "Received was Approved";
    })
    .catch((e) => {
      return "error on Transaction " + JSON.stringify(e);
    });
}





async addSODetail(id: number) {

  var kirim_id = await this.getKirimID(id);
  var now= await this.convertDate();

  return this.databaseObj
    .executeSql(`insert into kirimdets (kirim_id,product_id,qty,harga,diskon,lastupdate,lastuser) 
    select ${kirim_id} as kirim_id,a.product_id,a.qty,b.hargajual,b.diskon2,'${now}' as lastupdate,1 as lastuser 
    from jualdets a 
    inner join products b on a.product_id=b.id
    where a.jual_id=${id}`, [])
    .then(() => {
      return "Delivery Detail was Approved";
    })
    .catch((e) => {
      return "error on Transaction " + JSON.stringify(e);
    });
}


async addPTHeader(id: number,tp: string) {

  var nopt = await this.maxPiutang();
  var now= await this.convertDate();
  var grandpt = await this.grandPT(id);


  var paidpt: any;

  if (tp=='Cash'){
    var stinv='Approved';
    paidpt=grandpt;
  }
  else{
    var stinv='Draft';
    paidpt=0;
  }

  return this.databaseObj
    .executeSql(`update kirims set nopt='${nopt}',ptdate='${now}',grandpt=${grandpt},paidpt=${paidpt},stinv='${stinv}' where id=${id}`, [])
    .then(() => {
      return "SO was created invoice!";
    })
    .catch((e) => {
      return "error on Processing Transaction " + JSON.stringify(e);
    });
}


async addWOHeader(wh_id: number,pic: string, refno: string,dodate: Date) {

   
  var nowhout = await this.maxWhout();
  var now= await this.convertDate();

  return this.databaseObj
    .executeSql(`insert into whouts (nowhout,whoutdate,stat,refno,pic,wh_id,dodate,lastupdate,lastuser) 
    values('${nowhout}','${now}','Draft','${refno}','${pic}',${wh_id},'${dodate}','${now}',1)`, [])
    .then(() => {
      return nowhout;
    })
    .catch((e) => {
      return "error on Processing Transaction " + JSON.stringify(e);
    });


}


async addWIHeader(wh_id: number,pic: string, refno: string,grdate: Date) {

   
  var nowhin = await this.maxWhin();
  var now= await this.convertDate();

  return this.databaseObj
    .executeSql(`insert into whins (nowhin,whindate,stat,refno,pic,wh_id,grdate,lastupdate,lastuser) 
    values('${nowhin}','${now}','Draft','${refno}','${pic}',${wh_id},'${grdate}','${now}',1)`, [])
    .then(() => {
      return nowhin;
    })
    .catch((e) => {
      return "error on Processing Transaction " + JSON.stringify(e);
    });


}





async addWODetail(nowhout: string) {

  var id = await this.getWhoutID(nowhout);
  var now= await this.convertDate();

  var sQ=`insert into whoutdets (whout_id,product_id,qty,lastupdate,lastuser) 
  select `+ id + ` as whout_id,b.product_id,sum(qty) as qty,'${now}',1  
  from kirims a 
  inner join kirimdets b on a.id=b.kirim_id
  where a.nowhout='${nowhout}'
  group by b.product_id `;

  return this.databaseObj
    .executeSql(sQ, [])
    .then(() => {
      return id;
    })
    .catch((e) => {
      return "error on Processing Transaction " + sQ + JSON.stringify(e);
    });


}


async addWIDetail(nowhin: string) {

  var id = await this.getWhinID(nowhin);
  var now= await this.convertDate();

  var sQ=`insert into whindets (whin_id,product_id,qty,lastupdate,lastuser) 
  select `+ id + ` as whin_id,b.product_id,sum(qty) as qty,'${now}',1  
  from terimas a 
  inner join terimadets b on a.id=b.terima_id
  where a.nowhin='${nowhin}'
  group by b.product_id `;

  return this.databaseObj
    .executeSql(sQ, [])
    .then(() => {
      return id;
    })
    .catch((e) => {
      return "error on Processing Transaction " + sQ + JSON.stringify(e);
    });


}



async addHTHeader(id: number,tp: string) {


  var now= await this.convertDate();
  var grandht = await this.grandHT(id);

  var paidht: any;

  if (tp=='Cash'){
    var stinv='Approved';
    paidht=grandht;
  }
  else{
    var stinv='Draft';
    paidht=0;
  }

  
  return this.databaseObj
    .executeSql(`update ${this.tables.terimas} 
    set grandht=${grandht},paidht=${paidht},stinv='${stinv}',lastupdate='${now}' where id=${id}`, [])
    .then(() => {
      return true;
    })
    .catch((e) => {
      return "error on Processing Transaction " + JSON.stringify(e);
    });
}


async getTotalTerima(id: number) {
  return this.databaseObj.executeSql(`SELECT d.simbol,sum((b.qty*b.harga*x.kurs)- (b.qty *b.diskon * x.kurs)) as total 
  FROM terimas a 
  left join belis x on a.beli_id=x.id
  left join terimadets b on a.id=b.terima_id  
  left join products c on b.product_id=c.id
  left join forexs d on x.forex_id=d.id
  WHERE a.id = ${id}  
  group by d.simbol
  `, []).then(res => { 
    return {
      total: res.rows.item(0).total,
      simbol: res.rows.item(0).simbol,
    }
  });
}


async getTotalKirim(id: number) {
  return this.databaseObj.executeSql(`SELECT d.simbol,sum((b.qty*b.harga*x.kurs)- (b.qty *b.diskon * x.kurs)) as total 
  FROM kirims a 
  left join juals x on a.jual_id=x.id
  left join kirimdets b on a.id=b.kirim_id  
  left join products c on b.product_id=c.id
  left join forexs d on x.forex_id=d.id
  WHERE a.id = ${id}  
  group by d.simbol
  `, []).then(res => { 
    return {
      total: res.rows.item(0).total,
      simbol: res.rows.item(0).simbol,
    }
  });
}


async getTerimaHeader(id: number) {
  return this.databaseObj.executeSql(`SELECT a.id,a.nogr,a.grdate,a.stat,e.tp,e.vendor_id,e.forex_id,e.kurs,
  f.simbol,f.nmforex,d.kdpartner,d.nmpartner,d.address,d.city,d.zipcode,a.noht,a.htdate,a.grandht,
  a.paidht,a.wh_id,e.nopo,a.refno,a.penerima,case when b.qty is null then 0 else sum((b.qty*b.harga*e.kurs)- (b.qty * b.diskon * e.kurs)) end total 
  FROM terimas a 
  left join terimadets b on a.id=b.terima_id  
  left join products c on b.product_id=c.id
  left join belis e on a.beli_id=e.id
  left join partners d on e.vendor_id=d.id
  left join forexs f on e.forex_id=f.id
  WHERE a.beli_id = ${id}
  group by a.id,a.nogr,a.grdate,a.stat,e.tp,e.vendor_id,e.forex_id,e.kurs,f.simbol,f.nmforex,
  d.kdpartner,d.nmpartner,d.address,d.city,d.zipcode,a.noht,a.htdate,a.grandht,a.paidht,a.wh_id,e.nopo,a.refno,a.penerima`, []).then(res => { 
    return {
      id: res.rows.item(0).id,
      nogr: res.rows.item(0).nogr,  
      grdate: res.rows.item(0).grdate,
      vendor_id: res.rows.item(0).vendor_id,
      forex_id: res.rows.item(0).forex_id,
      tp: res.rows.item(0).tp,
      kurs: res.rows.item(0).kurs,
      simbol: res.rows.item(0).simbol,
      nmforex: res.rows.item(0).nmforex,
      stat: res.rows.item(0).stat,
      total: res.rows.item(0).total,
      kdpartner: res.rows.item(0).kdpartner,
      nmpartner: res.rows.item(0).nmpartner,
      address: res.rows.item(0).address,
      city: res.rows.item(0).city,
      zipcode: res.rows.item(0).zipcode,
      noht: res.rows.item(0).noht,
      htdate: res.rows.item(0).htdate,
      grandht: res.rows.item(0).grandht,
      wh_id: res.rows.item(0).wh_id,
      nopo: res.rows.item(0).nopo,
      refno: res.rows.item(0).refno,
      penerima: res.rows.item(0).penerima,
    }
  });
}

async getKirimHeader(id: number) {
  return this.databaseObj.executeSql(`SELECT a.id,a.nodo,a.dodate,a.stat,e.tp,e.cust_id,e.forex_id,e.kurs,
  f.simbol,f.nmforex,d.kdpartner,d.nmpartner,d.address,d.city,d.zipcode,a.wh_id,a.refno,a.pengirim,
  case when b.qty is null then 0 else sum(b.qty*e.kurs*b.harga-b.diskon) + (sum(b.qty*e.kurs*b.harga-b.diskon) * 0.11) end total 
  FROM kirims a 
  left join kirimdets b on a.id=b.kirim_id  
  left join products c on b.product_id=c.id
  left join juals e on a.jual_id=e.id
  left join partners d on e.cust_id=d.id
  left join forexs f on e.forex_id=f.id
  WHERE a.jual_id = ${id}
  group by a.id,a.nodo,a.dodate,a.stat,e.tp,e.cust_id,e.forex_id,e.kurs,f.simbol,
  f.nmforex,d.kdpartner,d.nmpartner,d.address,d.city,d.zipcode,a.wh_id,a.refno,a.pengirim`, []).then(res => { 
    return {
      id: res.rows.item(0).id,
      nodo: res.rows.item(0).nodo,  
      dodate: res.rows.item(0).dodate,
      cust_id: res.rows.item(0).cust_id,
      forex_id: res.rows.item(0).forex_id,
      tp: res.rows.item(0).tp,
      kurs: res.rows.item(0).kurs,
      simbol: res.rows.item(0).simbol,
      nmforex: res.rows.item(0).nmforex,
      stat: res.rows.item(0).stat,
      total: res.rows.item(0).total,
      kdpartner: res.rows.item(0).kdpartner,
      nmpartner: res.rows.item(0).nmpartner,
      address: res.rows.item(0).address,
      city: res.rows.item(0).city,
      zipcode: res.rows.item(0).zipcode,
      wh_id: res.rows.item(0).wh_id,
      refno: res.rows.item(0).refno,
      pengirim: res.rows.item(0).pengirim,
    }
  });
}




async getFormTerima(nogr: string) {
  return this.databaseObj.executeSql(`SELECT a.id,a.nogr,a.grdate,a.stat,e.tp,e.vendor_id,e.forex_id,e.kurs,
  f.simbol,f.nmforex,d.kdpartner,d.nmpartner,d.address,d.city,d.zipcode,a.noht,a.htdate,
  case when a.grandht is null then 0 else a.grandht end grandht,a.paidht,a.wh_id,e.nopo,a.refno,a.penerima,
  case when b.qty is null then 0 else sum((b.qty*c.hargabeli*e.kurs)- (b.qty * c.diskon * e.kurs)) end total 
  FROM terimas a 
  left join terimadets b on a.id=b.terima_id  
  left join products c on b.product_id=c.id
  left join belis e on a.beli_id=e.id
  left join partners d on e.vendor_id=d.id
  left join forexs f on e.forex_id=f.id
  WHERE a.nogr = '${nogr}'
  group by a.id,a.nogr,a.grdate,a.stat,e.tp,e.vendor_id,e.forex_id,e.kurs,f.simbol,f.nmforex,
  d.kdpartner,d.nmpartner,d.address,d.city,d.zipcode,a.noht,a.htdate,a.grandht,a.paidht,a.wh_id,e.nopo,a.refno,a.penerima`, []).then(res => { 
    return {
      id: res.rows.item(0).id,
      nogr: res.rows.item(0).nogr,  
      grdate: res.rows.item(0).grdate,
      vendor_id: res.rows.item(0).vendor_id,
      forex_id: res.rows.item(0).forex_id,
      tp: res.rows.item(0).tp,
      kurs: res.rows.item(0).kurs,
      simbol: res.rows.item(0).simbol,
      nmforex: res.rows.item(0).nmforex,
      stat: res.rows.item(0).stat,
      total: res.rows.item(0).total,
      kdpartner: res.rows.item(0).kdpartner,
      nmpartner: res.rows.item(0).nmpartner,
      address: res.rows.item(0).address,
      city: res.rows.item(0).city,
      zipcode: res.rows.item(0).zipcode,
      noht: res.rows.item(0).noht,
      htdate: res.rows.item(0).htdate,
      grandht: res.rows.item(0).grandht,
      wh_id: res.rows.item(0).wh_id,
      nopo: res.rows.item(0).nopo,
      refno: res.rows.item(0).refno,
      penerima: res.rows.item(0).penerima,
    }
  });
}


async getFormKirim(nodo: string) {
  return this.databaseObj.executeSql(`SELECT a.id,a.nodo,a.dodate,a.stat,e.tp,e.cust_id,e.forex_id,e.kurs,
  f.simbol,f.nmforex,d.kdpartner,d.nmpartner,d.address,d.city,d.zipcode,a.wh_id,a.refno,a.pengirim,e.noso,
  case when b.qty is null then 0 else sum((b.qty*c.hargajual*e.kurs)- (b.qty * c.diskon * e.kurs)) end total 
  FROM kirims a 
  left join kirimdets b on a.id=b.kirim_id  
  left join products c on b.product_id=c.id
  left join juals e on a.jual_id=e.id
  left join partners d on e.cust_id=d.id
  left join forexs f on e.forex_id=f.id
  WHERE a.nodo = '${nodo}'
  group by a.id,a.nodo,a.dodate,a.stat,e.tp,e.cust_id,e.forex_id,e.kurs,f.simbol,f.nmforex,
  d.kdpartner,d.nmpartner,d.address,d.city,d.zipcode,a.wh_id,a.refno,a.pengirim,e.noso`, []).then(res => { 
    return {
      id: res.rows.item(0).id,
      nodo: res.rows.item(0).nodo,  
      dodate: res.rows.item(0).dodate,
      cust_id: res.rows.item(0).cust_id,
      forex_id: res.rows.item(0).forex_id,
      tp: res.rows.item(0).tp,
      kurs: res.rows.item(0).kurs,
      simbol: res.rows.item(0).simbol,
      nmforex: res.rows.item(0).nmforex,
      stat: res.rows.item(0).stat,
      total: res.rows.item(0).total,
      kdpartner: res.rows.item(0).kdpartner,
      nmpartner: res.rows.item(0).nmpartner,
      address: res.rows.item(0).address,
      city: res.rows.item(0).city,
      zipcode: res.rows.item(0).zipcode,
      wh_id: res.rows.item(0).wh_id,
      noso: res.rows.item(0).noso,
      refno: res.rows.item(0).refno,
      pengirim: res.rows.item(0).pengirim,
    }
  });
}



async getFormWhout(nowhout: string) {

  var sQ=`SELECT a.id,a.nowhout,a.whoutdate,a.stat,
  a.wh_id,b.nmwh,a.refno,a.pic,a.dodate
  FROM whouts a 
  left join whs b on a.wh_id=b.id
  WHERE a.nowhout = '${nowhout}'`;


  return this.databaseObj.executeSql(sQ, []).then(res => { 
    return {

      id: res.rows.item(0).id,
      nowhout: res.rows.item(0).nowhout,  
      whoutdate: res.rows.item(0).whoutdate,
      wh_id: res.rows.item(0).wh_id,
      nmwh: res.rows.item(0).nmwh,
      stat: res.rows.item(0).stat,
      refno: res.rows.item(0).refno,
      pic: res.rows.item(0).pic,
      dodate: res.rows.item(0).dodate,
    }
  }).catch((e) => {
    return "error on getting Adding " + sQ + JSON.stringify(e);
  });
}


async getFormWhin(nowhin: string) {

  var sQ=`SELECT a.id,a.nowhin,a.whindate,a.stat,
  a.wh_id,b.nmwh,a.refno,a.pic,a.grdate
  FROM whins a 
  left join whs b on a.wh_id=b.id
  WHERE a.nowhin = '${nowhin}'`;


  return this.databaseObj.executeSql(sQ, []).then(res => { 
    return {

      id: res.rows.item(0).id,
      nowhin: res.rows.item(0).nowhin,  
      whindate: res.rows.item(0).whindate,
      wh_id: res.rows.item(0).wh_id,
      nmwh: res.rows.item(0).nmwh,
      stat: res.rows.item(0).stat,
      refno: res.rows.item(0).refno,
      pic: res.rows.item(0).pic,
      grdate: res.rows.item(0).grdate,
    }
  }).catch((e) => {
    return "error on getting Adding " + sQ + JSON.stringify(e);
  });
}


async cekHTAddedBkout(bkout_id:number,terima_id:number) {
  return this.databaseObj
    .executeSql(
      `SELECT terima_id FROM ${this.tables.bkoutdets} 
      where bkout_id= ${bkout_id} and terima_id= ${terima_id}`,
      []
    )
    .then((res) => {
     
      if (res.rows.item(0).terima_id==null){
          return "Y";
      }
      else{
          return "N";
      }
      
    })
    .catch((e) => {
      return "error on getting Adding " + JSON.stringify(e);
    });
}


async cekProductAddedJoborder(job_id:number,product_raw_id:number) {
  return this.databaseObj
    .executeSql(
      `SELECT product_raw_id FROM ${this.tables.joborderdets} 
      where job_id= ${job_id} and product_raw_id= ${product_raw_id}`,
      []
    )
    .then((res) => {
     
      if (res.rows.item(0).product_raw_id==null){
          return "Y";
      }
      else{
          return "N";
      }
      
    })
    .catch((e) => {
      return "error on getting Adding " + JSON.stringify(e);
    });
}



async cekProductAddedTerima(terima_id:number,product_id:number) {
  return this.databaseObj
    .executeSql(
      `SELECT product_id FROM ${this.tables.terimadets} 
      where terima_id= ${terima_id} and product_id= ${product_id}`,
      []
    )
    .then((res) => {
     
      if (res.rows.item(0).product_id==null){
          return "Y";
      }
      else{
          return "N";
      }
      
    })
    .catch((e) => {
      return "error on getting Adding " + JSON.stringify(e);
    });
}



async cekProductAddedKirim(kirim_id:number,product_id:number) {
  return this.databaseObj
    .executeSql(
      `SELECT product_id FROM ${this.tables.kirimdets} 
      where kirim_id= ${kirim_id} and product_id= ${product_id}`,
      []
    )
    .then((res) => {
     
      if (res.rows.item(0).product_id==null){
          return "Y";
      }
      else{
          return "N";
      }
      
    })
    .catch((e) => {
      return "error on getting Adding " + JSON.stringify(e);
    });
}


async addKirimdet(kirim_id: number,product_id: number,qty:number) {

  var cek= await this.cekProductAddedKirim(kirim_id,product_id);


  if (cek=='N'){

    return "N";
  }
  else{

  var now= await this.convertDate();
  return this.databaseObj
    .executeSql(
      `INSERT INTO ${this.tables.kirimdets} (kirim_id,product_id,qty,lastupdate,lastuser) VALUES ('${kirim_id}','${product_id}','${qty}','${now}','1')`,
      []
    )
    .then(() => {
      return "Y";
    })
    .catch((e) => {
      if (e.code === 6) {
        return "Product is already exists";
      }

      return "error on Added Product " + JSON.stringify(e);
    });

  }
}


async addKirim(dodate: Date,cust_id: number, tp:string,forex_id:number,kurs: number,branch_id: number,refno: string,pengirim: string) {

  var nodo=  await this.maxKirim();
  var now= await this.convertDate();
  var wh_id= await this.getWhID(branch_id);

  return this.databaseObj
    .executeSql(
      `INSERT INTO ${this.tables.kirims} (nodo,dodate,cust_id,stat,tp,forex_id,kurs,wh_id,refno,pengirim,lastupdate,lastuser) 
      VALUES ('${nodo}','${dodate}', ${cust_id},'Draft','${tp}',${forex_id},'${kurs}','${wh_id}','${refno}','${pengirim}','${now}','1')`,
      []
      
    )
    .then(() => {
      return nodo;
    })
    .catch((e) => {
      return "error on creating Transaction " + JSON.stringify(e);
    });
}







async addTerima(grdate: Date,vendor_id: number, tp:string,forex_id:number,kurs: number) {

  var nogr=  await this.maxTerima();
  var now= await this.convertDate();

  return this.databaseObj
    .executeSql(
      `INSERT INTO ${this.tables.terimas} (nogr,grdate,vendor_id,stat,tp,forex_id,kurs,refno,penerima,lastupdate,lastuser) VALUES ('${nogr}','${grdate}', ${vendor_id},'Draft','${tp}',${forex_id},'${kurs}','-','-','${now}','1')`,
      []
      
    )
    .then(() => {
      return nogr;
    })
    .catch((e) => {
      return "error on creating Transaction " + JSON.stringify(e);
    });
}


async addTerimadet(terima_id: number,product_id: number,qty:number) {

  var cek= await this.cekProductAddedTerima(terima_id,product_id);


  if (cek=='N'){

    return "N";
  }
  else{

  var now= await this.convertDate();
  return this.databaseObj
    .executeSql(
      `INSERT INTO ${this.tables.terimadets} (terima_id,product_id,qty,lastupdate,lastuser) VALUES ('${terima_id}','${product_id}','${qty}','${now}','1')`,
      []
    )
    .then(() => {
      return "Y";
    })
    .catch((e) => {
      if (e.code === 6) {
        return "Product is already exists";
      }

      return "error on Added Product " + JSON.stringify(e);
    });

  }
}

async editTerimadet(product_id: number,qty: number, id: number) {
  return this.databaseObj
    .executeSql(
      `UPDATE ${this.tables.terimadets} SET product_id = ${product_id},qty = ${qty} WHERE id = ${id}`,
      []
    )
    .then(() => {
      return "Detail updated";
    })
    .catch((e) => {
      if (e.code === 6) {
        return "Detail already exist";
      }

      return "error on updating Detail" + JSON.stringify(e);
    });
}


async editKirimdet(product_id: number,qty: number, id: number) {
  return this.databaseObj
    .executeSql(
      `UPDATE ${this.tables.kirimdets} SET product_id = ${product_id},qty = ${qty} WHERE id = ${id}`,
      []
    )
    .then(() => {
      return "Detail updated";
    })
    .catch((e) => {
      if (e.code === 6) {
        return "Detail already exist";
      }

      return "error on updating Detail" + JSON.stringify(e);
    });
}

async approveTerima(id: number,noht:string,htdate: Date,grdate: Date,refno: string, penerima: string) {
  return this.databaseObj
    .executeSql(`update ${this.tables.terimas} set stat='Approved',noht='${noht}',htdate='${htdate}',grdate='${grdate}',refno='${refno}',penerima='${penerima}' WHERE id = ${id}`, [])
    .then(() => {
      return "Transaction was Approved";
    })
    .catch((e) => {
      return "error on Approving Transaction " + JSON.stringify(e);
    });
}


async approveKirim(id: number,refno: string,pengirim: string) {
  return this.databaseObj
    .executeSql(`update ${this.tables.kirims} set stat='Approved',refno='${refno}',pengirim='${pengirim}' WHERE id = ${id}`, [])
    .then(() => {
      return "Transaction was Approved";
    })
    .catch((e) => {
      return "error on Approving Transaction " + JSON.stringify(e);
    });
}


async approveWhout(id: number,refno: string,pic: string) {
  return this.databaseObj
    .executeSql(`update ${this.tables.whouts} set stat='Approved',refno='${refno}',pic='${pic}' WHERE id = ${id}`, [])
    .then(() => {
      return "Transaction was Approved";
    })
    .catch((e) => {
      return "error on Approving Transaction " + JSON.stringify(e);
    });
}


async approveWhin(id: number,refno: string,pic: string) {
  return this.databaseObj
    .executeSql(`update ${this.tables.whins} set stat='Approved',refno='${refno}',pic='${pic}' WHERE id = ${id}`, [])
    .then(() => {
      return "Transaction was Approved";
    })
    .catch((e) => {
      return "error on Approving Transaction " + JSON.stringify(e);
    });
}
    

async approveBeli(id: number,podate: Date,branch_id: number) {
  return this.databaseObj
    .executeSql(`update ${this.tables.belis} set stat='Approved',podate='${podate}',branch_id='${branch_id}' WHERE id = ${id}`, [])
    .then(() => {
      return "Transaction was Approved";
    })
    .catch((e) => {
      return "error on Approving Transaction " + JSON.stringify(e);
    });
}


async approveSimpan(id: number,simpandate: Date) {
  return this.databaseObj
    .executeSql(`update ${this.tables.simpans} set stat='Approved',simpandate='${simpandate}' WHERE id = ${id}`, [])
    .then(() => {
      return "Transaction was Approved";
    })
    .catch((e) => {
      return "error on Approving Transaction " + JSON.stringify(e);
    });
}




async revertBkout(id: number) {
  return this.databaseObj
    .executeSql(`update ${this.tables.bkouts} set stat='Draft' WHERE id = ${id}`, [])
    .then(() => {
      return "Transaction was Reverted";
    })
    .catch((e) => {
      return "error on Reverting " + JSON.stringify(e);
    });
}


async revertBkin(id: number) {
  return this.databaseObj
    .executeSql(`update ${this.tables.bkins} set stat='Draft' WHERE id = ${id}`, [])
    .then(() => {
      return "Transaction was Reverted";
    })
    .catch((e) => {
      return "error on Reverting " + JSON.stringify(e);
    });
}


async revertJoborder(id: number) {
  return this.databaseObj
    .executeSql(`update ${this.tables.joborders} set status='Draft' WHERE id = ${id}`, [])
    .then(() => {
      return "Transaction was Reverted";
    })
    .catch((e) => {
      return "error on Reverting " + JSON.stringify(e);
    });
}


async revertMutasi(id: number) {
  return this.databaseObj
    .executeSql(`update ${this.tables.mutasis} set status='Draft' WHERE id = ${id}`, [])
    .then(() => {
      return "Transaction was Reverted";
    })
    .catch((e) => {
      return "error on Reverting " + JSON.stringify(e);
    });
}

async revertBeli(id: number,tr: string) {
  return this.databaseObj
    .executeSql(`update ${this.tables.belis} set stat='Draft' WHERE id = ${id}`, [])
    .then(() => {
      return "Transaction was Reverted";
    })
    .catch((e) => {
      return "error on Reverting " + JSON.stringify(e);
    });
}

async revertSimpan(id: number,tr: string) {
  return this.databaseObj
    .executeSql(`update ${this.tables.simpans} set stat='Draft' WHERE id = ${id}`, [])
    .then(() => {
      return "Transaction was Reverted";
    })
    .catch((e) => {
      return "error on Reverting " + JSON.stringify(e);
    });
}



async updateHpp(nojob: string,final: number) {

var totjob = await this.totJobHpp(nojob);

if (final==0){
  var sQ=`update ${this.tables.joborders} set hpp=${totjob}/${final} WHERE nojob = '${nojob}'`;
}
else{
  var sQ=`update ${this.tables.joborders} set hpp=${totjob}/${final} WHERE nojob = '${nojob}'`;
}

  return this.databaseObj
    .executeSql(sQ, [])
    .then(() => {
      return "Transaction was updated";
    })
    .catch((e) => {
      return "error on Updating " + JSON.stringify(e);
    });
}


async makeDiff(id: number) {
  


 
  
  return this.databaseObj
    .executeSql(` UPDATE stodets 
    SET selisih =
        (SELECT products.qty-stodets.qty
         FROM products
         WHERE (stodets.product_id) = (products.id))
    WHERE (product_id) IN (SELECT id FROM products) and sto_id=${id}`, [])
    .then(() => {
      return "Success";
    })
    .catch((e) => {
      return "error on updating " + JSON.stringify(e);
    });
}



async deleteBkin(id: number) {
  return this.databaseObj
    .executeSql(`DELETE FROM ${this.tables.bkins} WHERE id = ${id}`, [])
    .then(() => {
      return "Transaction was deleted";
    })
    .catch((e) => {
      return "error on deleting " + JSON.stringify(e);
    });
}

async deleteJoborder(id: number) {
  return this.databaseObj
    .executeSql(`DELETE FROM ${this.tables.joborders} WHERE id = ${id}`, [])
    .then(() => {
      return "Transaction was deleted";
    })
    .catch((e) => {
      return "error on deleting " + JSON.stringify(e);
    });
}

async deleteMutasi(id: number) {
  return this.databaseObj
    .executeSql(`DELETE FROM ${this.tables.mutasis} WHERE id = ${id}`, [])
    .then(() => {
      return "Transaction was deleted";
    })
    .catch((e) => {
      return "error on deleting " + JSON.stringify(e);
    });
}


async deleteSto(id: number) {
  return this.databaseObj
    .executeSql(`DELETE FROM ${this.tables.stos} WHERE id = ${id}`, [])
    .then(() => {
      return "Transaction was deleted";
    })
    .catch((e) => {
      return "error on deleting " + JSON.stringify(e);
    });
}



async deleteBkout(id: number) {
  return this.databaseObj
    .executeSql(`DELETE FROM ${this.tables.bkouts} WHERE id = ${id}`, [])
    .then(() => {
      return "Transaction was deleted";
    })
    .catch((e) => {
      return "error on deleting " + JSON.stringify(e);
    });
}


async deleteBeli(id: number) {
  return this.databaseObj
    .executeSql(`DELETE FROM ${this.tables.belis} WHERE id = ${id}`, [])
    .then(() => {
      return "Transaction was deleted";
    })
    .catch((e) => {
      return "error on deleting " + JSON.stringify(e);
    });
}


async deleteSimpan(id: number) {
  return this.databaseObj
    .executeSql(`DELETE FROM ${this.tables.simpans} WHERE id = ${id}`, [])
    .then(() => {
      return "Transaction was deleted";
    })
    .catch((e) => {
      return "error on deleting " + JSON.stringify(e);
    });
}

async deleteBelidetAll(id: number) {
  return this.databaseObj
    .executeSql(`DELETE FROM ${this.tables.belidets} WHERE beli_id = ${id}`, [])
    .then(() => {
      return "Data was deleted";
    })
    .catch((e) => {
      return "error on deleting Detail " + JSON.stringify(e);
    });
}


async deleteBkoutdetAll(id: number) {
  return this.databaseObj
    .executeSql(`DELETE FROM ${this.tables.bkoutdets} WHERE bkout_id = ${id}`, [])
    .then(() => {
      return "Data was deleted";
    })
    .catch((e) => {
      return "error on deleting Detail " + JSON.stringify(e);
    });
}

async deleteJoborderdetAll(id: number) {
  return this.databaseObj
    .executeSql(`DELETE FROM ${this.tables.joborderdets} WHERE job_id = ${id}`, [])
    .then(() => {
      return "Data was deleted";
    })
    .catch((e) => {
      return "error on deleting Detail " + JSON.stringify(e);
    });
}


async deleteMutasidetAll(id: number) {
  return this.databaseObj
    .executeSql(`DELETE FROM ${this.tables.joborderdets} WHERE mutasi_id = ${id}`, [])
    .then(() => {
      return "Data was deleted";
    })
    .catch((e) => {
      return "error on deleting Detail " + JSON.stringify(e);
    });
}


async deleteStodetAll(id: number) {
  return this.databaseObj
    .executeSql(`DELETE FROM ${this.tables.stodets} WHERE sto_id = ${id}`, [])
    .then(() => {
      return "Data was deleted";
    })
    .catch((e) => {
      return "error on deleting Detail " + JSON.stringify(e);
    });
}

async deleteBkindetAll(id: number) {
  return this.databaseObj
    .executeSql(`DELETE FROM ${this.tables.bkindets} WHERE bkin_id = ${id}`, [])
    .then(() => {
      return "Data was deleted";
    })
    .catch((e) => {
      return "error on deleting Detail " + JSON.stringify(e);
    });
}

async editBeli(id: number,nopo: string,podate: Date,vendor_id: number, stat: string, tp: string,forex_id:number,kurs: number, branch_id: number) {
  return this.databaseObj
    // .executeSql(
    //   `UPDATE ${this.tables.belis} SET nopo =  '${nopo}',podate= DATE('${podate}','+1 Day') , vendor_id = ${vendor_id},stat='${stat}',tp='${tp}',forex_id=${forex_id},kurs='${kurs}',branch_id='${branch_id}' WHERE id = ${id}`,
    //   []
    // )
    .executeSql(
      `UPDATE ${this.tables.belis} SET nopo =  '${nopo}',podate= DATE('${podate}') , vendor_id = ${vendor_id},stat='${stat}',tp='${tp}',forex_id=${forex_id},kurs='${kurs}',branch_id='${branch_id}' WHERE id = ${id}`,
      []
    )
    .then(() => {
      return "Transaction was updated " + podate;
    })
    .catch((e) => {
      return "error on updating Transaction" + JSON.stringify(e);
    });
}


async editSimpan(id: number,nosimpan: string,simpandate: Date,user_id: number, stat: string, tr: string,amount: number) {
  return this.databaseObj

    .executeSql(
      `UPDATE ${this.tables.simpans} SET nosimpan =  '${nosimpan}',simpandate= DATE('${simpandate}') , user_id = ${user_id},stat='${stat}',tr='${tr}',amount=${amount} WHERE id = ${id}`,
      []
    )
    .then(() => {
      return "Transaction was updated " + simpandate;
    })
    .catch((e) => {
      return "error on updating Transaction" + JSON.stringify(e);
    });
}

async editBkout(id: number,nobkout: string,bkoutdate: Date,vendor_id: number, stat: string, tp: string,pf: string,forex_id:number,kurs: number, rek_id: number) {
  return this.databaseObj
    .executeSql(
      `UPDATE ${this.tables.bkouts} SET nobkout =  '${nobkout}',bkoutdate= DATE('${bkoutdate}','+1 Day') , vendor_id = ${vendor_id},stat='${stat}',tp='${tp}',pf='${pf}',forex_id=${forex_id},kurs='${kurs}',rek_id='${rek_id}' WHERE id = ${id}`,
      []
    )
    .then(() => {
      return "Transaction was updated ";
    })
    .catch((e) => {
      return "error on updating Transaction" + JSON.stringify(e);
    });
}



async editBkin(id: number,nobkin: string,bkindate: Date,cust_id: number, stat: string, tp: string,pf: string,forex_id:number,kurs: number, rek_id: number) {
  return this.databaseObj
    .executeSql(
      `UPDATE ${this.tables.bkins} SET nobkin =  '${nobkin}',bkoutdate= DATE('${bkindate}','+1 Day') , vendor_id = ${cust_id},stat='${stat}',tp='${tp}',pf='${pf}',forex_id=${forex_id},kurs='${kurs}',rek_id='${rek_id}' WHERE id = ${id}`,
      []
    )
    .then(() => {
      return "Transaction was updated ";
    })
    .catch((e) => {
      return "error on updating Transaction" + JSON.stringify(e);
    });
}

async deleteBelidet(id: number) {
  return this.databaseObj
    .executeSql(`DELETE FROM ${this.tables.belidets} WHERE id = ${id}`, [])
    .then(() => {
      return "Detail was deleted";
    })
    .catch((e) => {
      return "error on deleting Detail " + JSON.stringify(e);
    });
}


async deleteBkoutdet(id: number) {
  return this.databaseObj
    .executeSql(`DELETE FROM ${this.tables.bkoutdets} WHERE id = ${id}`, [])
    .then(() => {
      return "Detail was deleted";
    })
    .catch((e) => {
      return "error on deleting Detail " + JSON.stringify(e);
    });
}

async deleteBkindet(id: number) {
  return this.databaseObj
    .executeSql(`DELETE FROM ${this.tables.bkindets} WHERE id = ${id}`, [])
    .then(() => {
      return "Detail was deleted";
    })
    .catch((e) => {
      return "error on deleting Detail " + JSON.stringify(e);
    });
}


async deleteJoborderdet(id: number) {
  return this.databaseObj
    .executeSql(`DELETE FROM ${this.tables.joborderdets} WHERE id = ${id}`, [])
    .then(() => {
      return "Detail was deleted";
    })
    .catch((e) => {
      return "error on deleting Detail " + JSON.stringify(e);
    });
}


async deleteMutasidet(id: number) {
  return this.databaseObj
    .executeSql(`DELETE FROM ${this.tables.mutasidets} WHERE id = ${id}`, [])
    .then(() => {
      return "Detail was deleted";
    })
    .catch((e) => {
      return "error on deleting Detail " + JSON.stringify(e);
    });
}

async deleteStodet(id: number) {
  return this.databaseObj
    .executeSql(`DELETE FROM ${this.tables.stodets} WHERE id = ${id}`, [])
    .then(() => {
      return "Detail was deleted";
    })
    .catch((e) => {
      return "error on deleting Detail " + JSON.stringify(e);
    });
}

//HUTANG

async getListTerimas(stat:string) {
  return this.databaseObj

    // where a.stat like  '%${stat}%' and a.jenis='po'
    // strftime ('%Y-%m-%d', a.podate) as podate 
    .executeSql(
      `SELECT a.id,a.nogr,strftime ('%Y-%m-%d', a.grdate) as grdate,a.stat,x.vendor_id,x.nopo,
      x.tp,x.forex_id,x.kurs,b.kdpartner,b.nmpartner,b.address,b.city,b.zipcode,d.simbol,d.nmforex,
      case when c.qty is null then 0 else sum(c.qty*x.kurs*c.harga-c.diskon) + sum((c.qty*x.kurs*c.harga-c.diskon)*0.11) end total
      FROM terimas a
      LEFT JOIN belis x on a.beli_id=x.id 
      LEFT JOIN terimadets c on a.id=c.terima_id 
      INNER JOIN partners b ON b.id = x.vendor_id 
      LEFT JOIN forexs d on x.forex_id=d.id
      where a.stat='${stat}'  and a.stinv is null
      group by a.id,a.nogr,a.grdate,a.stat,x.vendor_id,x.nopo,
      x.tp,x.forex_id,x.kurs,b.kdpartner,b.nmpartner,b.address,b.city,b.zipcode,d.simbol,d.nmforex
      ORDER BY a.id DESC`,
      []
    )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return "error on getting Data " + JSON.stringify(e);
    });
}




async getHutangs(startdate: any, enddate: any) {
  return this.databaseObj
     
    .executeSql(
      `SELECT a.id,a.noht,strftime('%Y-%m-%d', a.htdate) as htdate,a.stat,x.vendor_id,x.nopo,
      x.tp,x.forex_id,x.kurs,b.kdpartner,b.nmpartner,b.address,b.city,b.zipcode,d.simbol,d.nmforex,
      a.grandht as total, a.paidht,a.stinv,e.terima_id
      FROM terimas a
      LEFT JOIN belis x on a.beli_id=x.id 
      INNER JOIN partners b ON b.id = x.vendor_id 
      LEFT JOIN forexs d on x.forex_id=d.id
      LEFT JOIN bkoutdets e on a.id=e.terima_id
      where a.noht<>'' and a.stinv is not null and strftime('%Y-%m-%d', a.htdate) between '${startdate}' and '${enddate}'
      ORDER BY a.id DESC`,
      []
    )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return "error on getting Payable" + JSON.stringify(e);
    });
}


async rptSumHutangs(startdate: any, enddate: any) {
  return this.databaseObj

  // where a.noht<>'' and a.stinv is not null and strftime('%Y-%m-%d', a.htdate) between '${startdate}' and '${enddate}'
     
    .executeSql(
      `SELECT a.noht as [No.Invoice],strftime('%Y-%m-%d', a.htdate) as [Invoice Date],a.stat as Status,x.nopo as [No. PO],
      x.tp as [Type],b.kdpartner as [ID Vendor],b.nmpartner as [Vendor Name],b.address || ' ' || b.city || ' ' || b.zipcode as Address,
      a.grandht as Total, a.paidht as [Paid AP],a.stinv as Status
      FROM terimas a
      LEFT JOIN belis x on a.beli_id=x.id 
      INNER JOIN partners b ON b.id = x.vendor_id 
      LEFT JOIN forexs d on x.forex_id=d.id
      LEFT JOIN bkoutdets e on a.id=e.terima_id
     
      ORDER BY a.noht DESC`,
      []
    )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return "error on getting Payable" + JSON.stringify(e);
    });
}




//and a.stinv='Approved' and a.paidht<=a.grandht
async getHTBkouts() {
  return this.databaseObj
     
    .executeSql(
      `SELECT a.id,a.noht,strftime ('%Y-%m-%d', a.htdate) as htdate,a.stat,x.vendor_id,x.nopo,
      x.tp,x.forex_id,x.kurs,b.kdpartner,b.nmpartner,b.address,b.city,b.zipcode,d.simbol,d.nmforex,
      a.grandht as total, a.paidht,a.stinv,'[' || a.noht || '] ' || a.htdate || ' ' || b.nmpartner || ' ' || a.grandht as nmht
      FROM terimas a
      LEFT JOIN belis x on a.beli_id=x.id 
      INNER JOIN partners b ON b.id = x.vendor_id 
      LEFT JOIN forexs d on x.forex_id=d.id
      where a.noht<>'' and a.noht is not null and a.stinv='Approved' and a.paidht<a.grandht
      ORDER BY a.id DESC`,
      []
    )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return "error on getting Payable" + JSON.stringify(e);
    });
}


async getPTBkins() {
  return this.databaseObj
     
    .executeSql(
      `SELECT a.id,a.nopt,strftime ('%Y-%m-%d', a.ptdate) as ptdate,a.stat,x.cust_id,x.noso,
      x.tp,x.forex_id,x.kurs,b.kdpartner,b.nmpartner,b.address,b.city,b.zipcode,d.simbol,d.nmforex,
      a.grandpt as total, a.paidpt,a.stinv,'[' || a.nopt || '] ' || a.ptdate || ' ' || b.nmpartner || ' ' || a.grandpt as nmpt
      FROM kirims a
      LEFT JOIN juals x on a.jual_id=x.id 
      INNER JOIN partners b ON b.id = x.cust_id 
      LEFT JOIN forexs d on x.forex_id=d.id
      where a.nopt<>'' and a.nopt is not null and a.stinv='Approved' and a.paidpt<a.grandpt
      ORDER BY a.id DESC`,
      []
    )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return "error on getting Receivable" + JSON.stringify(e);
    });
}



async getTotalBkout(id: number) {
  return this.databaseObj.executeSql(`SELECT d.simbol,sum(b.amount*a.kurs) as total 
  FROM bkouts a 
  left join bkoutdets b on a.id=b.bkout_id  
  left join forexs d on a.forex_id=d.id
  WHERE a.id = ${id}  
  group by d.simbol
  `, []).then(res => { 
    return {
      total: res.rows.item(0).total,
      simbol: res.rows.item(0).simbol,
    }
  });
}



async getTotalBkin(id: number) {
  return this.databaseObj.executeSql(`SELECT d.simbol,sum(b.amount*a.kurs) as total 
  FROM bkins a 
  left join bkindets b on a.id=b.bkin_id  
  left join forexs d on a.forex_id=d.id
  WHERE a.id = ${id}  
  group by d.simbol
  `, []).then(res => { 
    return {
      total: res.rows.item(0).total,
      simbol: res.rows.item(0).simbol,
    }
  });
}


async getTotalJoborder(id: number) {
  return this.databaseObj.executeSql(`SELECT d.simbol,sum(b.harga*a.kurs*b.qty) as total 
  FROM joborders a 
  left join joborderdets b on a.id=b.job_id  
  left join forexs d on a.forex_id=d.id
  WHERE a.id = ${id}  
  group by d.simbol
  `, []).then(res => { 
    return {
      total: res.rows.item(0).total,
      simbol: res.rows.item(0).simbol,
    }
  });
}


async getProfitLoss() {

  var sQ=`SELECT sum(b.harga*b.qty) as jual, sum(c.hargabeli*b.qty) as beli,sum(b.harga*b.qty) - sum(c.hargabeli*b.qty) as rl
  FROM kirimdets b 
  inner join products c on b.product_id=c.id  
  `;

  return this.databaseObj.executeSql(sQ, []).then(res => { 
    return {
      rl: res.rows.item(0).rl,
    }
  });
}


async getCountCust() {

  var sQ=`SELECT count(id) as jml FROM partners a where tp='2' 
  `;

  return this.databaseObj.executeSql(sQ, []).then(res => { 
    return {
      jml: res.rows.item(0).jml,
    }
  });
}


async getCountVendor() {

  var sQ=`SELECT count(id) as jml FROM partners a where tp='1' 
  `;

  return this.databaseObj.executeSql(sQ, []).then(res => { 
    return {
      jml: res.rows.item(0).jml,
    }
  });
}




async getTotalHutang(id: number) {
  return this.databaseObj.executeSql(`SELECT d.simbol,sum((b.qty*b.harga*x.kurs)- (b.qty *b.diskon * x.kurs)) as toti, sum((b.qty*b.harga*x.kurs)- (b.qty *b.diskon * x.kurs)) + (sum((b.qty*b.harga*x.kurs)- (b.qty *b.diskon * x.kurs)) * 0.11) as total 
  FROM terimas a 
  left join belis x on a.beli_id=x.id
  left join terimadets b on a.id=b.terima_id  
  left join products c on b.product_id=c.id
  left join forexs d on x.forex_id=d.id
  WHERE a.id = ${id}  
  group by d.simbol
  `, []).then(res => { 
    return {
      total: res.rows.item(0).total,
      toti: res.rows.item(0).toti,
      simbol: res.rows.item(0).simbol,
    }
  });
}


async getHutangHeader(id: number) {
  return this.databaseObj.executeSql(`SELECT a.id,a.noht,a.htdate,a.stat,e.tp,e.vendor_id,e.forex_id,e.kurs,
  f.simbol,f.nmforex,d.kdpartner,d.nmpartner,d.address,d.city,d.zipcode,a.grandht as total, a.paidht,a.stinv
  FROM terimas a 
  left join belis e on a.beli_id=e.id
  left join partners d on e.vendor_id=d.id
  left join forexs f on e.forex_id=f.id
  WHERE a.id = ${id}`, []).then(res => { 
    return {
      id: res.rows.item(0).id,
      noht: res.rows.item(0).noht,  
      htdate: res.rows.item(0).htdate,
      vendor_id: res.rows.item(0).vendor_id,
      forex_id: res.rows.item(0).forex_id,
      tp: res.rows.item(0).tp,
      kurs: res.rows.item(0).kurs,
      simbol: res.rows.item(0).simbol,
      nmforex: res.rows.item(0).nmforex,
      stat: res.rows.item(0).stat,
      total: res.rows.item(0).total,
      kdpartner: res.rows.item(0).kdpartner,
      nmpartner: res.rows.item(0).nmpartner,
      address: res.rows.item(0).address,
      city: res.rows.item(0).city,
      zipcode: res.rows.item(0).zipcode,
      paidht: res.rows.item(0).paidht,
      stinv: res.rows.item(0).stinv,
    }
  });
}


async getFormHutang(noht: string) {
  return this.databaseObj.executeSql(`SELECT a.id,a.noht,a.htdate,a.stat,e.tp,e.vendor_id,e.forex_id,e.kurs,
  f.simbol,f.nmforex,d.kdpartner,d.nmpartner,d.address,d.city,d.zipcode,a.grandht as  total ,a.paidht,a.stinv
  FROM terimas a 
  left join belis e on a.beli_id=e.id
  left join partners d on e.vendor_id=d.id
  left join forexs f on e.forex_id=f.id
  WHERE a.noht = '${noht}'`, []).then(res => { 
    return {
      id: res.rows.item(0).id,
      noht: res.rows.item(0).noht,  
      htdate: res.rows.item(0).htdate,
      vendor_id: res.rows.item(0).vendor_id,
      forex_id: res.rows.item(0).forex_id,
      tp: res.rows.item(0).tp,
      kurs: res.rows.item(0).kurs,
      simbol: res.rows.item(0).simbol,
      nmforex: res.rows.item(0).nmforex,
      stat: res.rows.item(0).stat,
      total: res.rows.item(0).total,
      kdpartner: res.rows.item(0).kdpartner,
      nmpartner: res.rows.item(0).nmpartner,
      address: res.rows.item(0).address,
      city: res.rows.item(0).city,
      zipcode: res.rows.item(0).zipcode,
      paidht: res.rows.item(0).paidht,
      stinv: res.rows.item(0).stinv,
    }
  })
  // .catch((e) => {
  //   return "error on getting Adding " + JSON.stringify(e);
  // });
}


async getAllHutangs() {
  return this.databaseObj.executeSql(`SELECT sum(a.grandht)  as tothutang ,sum(a.paidht) as tothutpaid
  FROM terimas a where stat='Approved'`, []).then(res => { 
    return {
      tothutang: res.rows.item(0).tothutang,
      tothutpaid: res.rows.item(0).tothutpaid,  
     
    }
  })

}

async getAllPiutangs() {
  return this.databaseObj.executeSql(`SELECT sum(a.grandpt)  as totpiutang ,sum(a.paidpt) as totpiutpaid
  FROM kirims a where stat='Approved'`, []).then(res => { 
    return {
      totpiutang: res.rows.item(0).totpiutang,
      totpiutpaid: res.rows.item(0).totpiutpaid,  
     
    }
  })

}

async cekProductAddedHutang(terima_id:number,product_id:number) {
  return this.databaseObj
    .executeSql(
      `SELECT product_id FROM ${this.tables.terimadets} 
      where terima_id= ${terima_id} and product_id= ${product_id}`,
      []
    )
    .then((res) => {
     
      if (res.rows.item(0).product_id==null){
          return "Y";
      }
      else{
          return "N";
      }
      
    })
    .catch((e) => {
      return "error on getting Adding " + JSON.stringify(e);
    });
}

async addHutang(grdate: Date,vendor_id: number, tp:string,forex_id:number,kurs: number) {

  var nogr=  await this.maxTerima();
  var now= await this.convertDate();

  return this.databaseObj
    .executeSql(
      `INSERT INTO ${this.tables.terimas} (nogr,grdate,vendor_id,stat,tp,forex_id,kurs,lastupdate,lastuser) VALUES ('${nogr}','${grdate}', ${vendor_id},'Draft','${tp}',${forex_id},'${kurs}','${now}','1')`,
      []
      
    )
    .then(() => {
      return nogr;
    })
    .catch((e) => {
      return "error on creating Transaction " + JSON.stringify(e);
    });
}


async addHutangdet(terima_id: number,product_id: number,qty:number) {

  var cek= await this.cekProductAddedHutang(terima_id,product_id);


  if (cek=='N'){

    return "N";
  }
  else{

  var now= await this.convertDate();
  return this.databaseObj
    .executeSql(
      `INSERT INTO ${this.tables.terimadets} (terima_id,product_id,qty,lastupdate,lastuser) VALUES ('${terima_id}','${product_id}','${qty}','${now}','1')`,
      []
    )
    .then(() => {
      return "Y";
    })
    .catch((e) => {
      if (e.code === 6) {
        return "Product is already exists";
      }

      return "error on Added Product " + JSON.stringify(e);
    });

  }
}


async editHutang(id: number,htdate: Date,noht: string) {
  return this.databaseObj
    .executeSql(
      `UPDATE ${this.tables.terimas} SET htdate=DATE('${htdate}','+1 day'),noht='${noht}' WHERE id = ${id}`,
      []
    )
    .then(() => {
      return "Transaction was updated";
    })
    .catch((e) => {
      return "error on updating Transaction" + JSON.stringify(e);
    });
}


async editHutangdet(harga: number,diskon: number, id: number) {
  return this.databaseObj
    .executeSql(
      `UPDATE ${this.tables.terimadets} SET harga = ${harga},diskon = ${diskon} WHERE id = ${id}`,
      []
    )
    .then(() => {
      return "Detail updated";
    })
    .catch((e) => {
      if (e.code === 6) {
        return "Detail already exist";
      }

      return "error on updating Detail" + JSON.stringify(e);
    });
}


async approveBkout(id: number) {
  return this.databaseObj
    .executeSql(`update ${this.tables.bkouts} set stat='Approved' WHERE id = ${id}`, [])
    .then(() => {
      return "Transaction was Approved";
    })
    .catch((e) => {
      return "error on Approving Transaction " + JSON.stringify(e);
    });
}

async approveBkin(id: number) {
  return this.databaseObj
    .executeSql(`update ${this.tables.bkins} set stat='Approved' WHERE id = ${id}`, [])
    .then(() => {
      return "Transaction was Approved";
    })
    .catch((e) => {
      return "error on Approving Transaction " + JSON.stringify(e);
    });
}

//this.id,this.jobdate,this.finishdate,this.wh_id,this.product_fg_id,this.newhg)
//jobdate Date NOT NULL, finishdate Date NULL,target INTEGER UNSIGNED NOT NULL,final INTEGER UNSIGNED NOT NULL, reject INTEGER UNSIGNED NOT NULL,status char(10) NOT NULL,wh_id integer not null,product_fg_id integer not null,forex_id integer,kurs FLOAT NULL,hpp float,hargajual float,cs char(3) null, lastupdate Date null,lastuser INTEGER null)`,

async approveJoborder(id: number,jobdate: Date, finishdate: Date,wh_id: number,product_fg_id: number,hargajual: number,cs: string,target: number,final:number, reject: number,hpp: number) {
  return this.databaseObj
    .executeSql(`update ${this.tables.joborders} set status='Approved',jobdate='${jobdate}',finishdate='${finishdate}'
    ,wh_id='${wh_id}',product_fg_id='${product_fg_id}',hargajual=${hargajual},cs='${cs}',target=${target},final=${final},reject=${reject},hpp=${hpp} WHERE id = ${id}`, [])
    .then(() => {
      return "Transaction was Approved";
    })
    .catch((e) => {
      return "error on Approving Transaction " + JSON.stringify(e);
    });
}


async approveMutasi(id: number,mutasidate: Date, wh_id: number,wh_id2: number) {
  return this.databaseObj
    .executeSql(`update ${this.tables.mutasis} set status='Approved',mutasidate='${mutasidate}',wh_id='${wh_id}'
    ,wh_id2='${wh_id2}' WHERE id = ${id}`, [])
    .then(() => {
      return "Transaction was Approved";
    })
    .catch((e) => {
      return "error on Approving Transaction " + JSON.stringify(e);
    });
}


async approveSto(id: number,stodate: Date, wh_id: number) {
  return this.databaseObj
    .executeSql(`update ${this.tables.stos} set status='Approved',stodate='${stodate}',wh_id='${wh_id}'
     WHERE id = ${id}`, [])
    .then(() => {
      return "Transaction was Approved";
    })
    .catch((e) => {
      return "error on Approving Transaction " + JSON.stringify(e);
    });
}

async approveHutang(id: number) {
  return this.databaseObj
    .executeSql(`update ${this.tables.terimas} set stinv='Approved' WHERE id = ${id}`, [])
    .then(() => {
      return "Transaction was Approved";
    })
    .catch((e) => {
      return "error on Approving Transaction " + JSON.stringify(e);
    });
}


// PIUTANG

async getListKirims(stat:string) {
  return this.databaseObj


    .executeSql(
      `SELECT a.id,a.nodo,strftime ('%Y-%m-%d', a.dodate) as dodate,a.stat,x.cust_id,x.noso,
      x.tp,x.forex_id,x.kurs,b.kdpartner,b.nmpartner,b.address,b.city,b.zipcode,d.simbol,d.nmforex,
      case when c.qty is null then 0 else sum(c.qty*x.kurs*c.harga-c.diskon) end total
      FROM kirims a
      LEFT JOIN juals x on a.jual_id=x.id 
      LEFT JOIN kirimdets c on a.id=c.kirim_id 
      INNER JOIN partners b ON b.id = x.cust_id 
      LEFT JOIN forexs d on x.forex_id=d.id
      where a.stat='${stat}'  and a.nopt is null
      group by a.id,a.nodo,a.dodate,a.stat,x.cust_id,x.noso,
      x.tp,x.forex_id,x.kurs,b.kdpartner,b.nmpartner,b.address,b.city,b.zipcode,d.simbol,d.nmforex
      ORDER BY a.id DESC`,
      []
    )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return "error on getting Data " + JSON.stringify(e);
    });
}


async getListDo(stat:string,dodate: any) {
  return this.databaseObj
    .executeSql(
      `SELECT a.id,a.nodo,strftime ('%Y-%m-%d', a.dodate) as dodate,a.stat,x.cust_id,x.noso,
      x.tp,x.forex_id,x.kurs,b.kdpartner,b.nmpartner,b.address,b.city,b.zipcode,d.simbol,d.nmforex,
      case when c.qty is null then 0 else sum(c.qty*x.kurs*c.harga-c.diskon) end total
      FROM kirims a
      LEFT JOIN juals x on a.jual_id=x.id 
      LEFT JOIN kirimdets c on a.id=c.kirim_id 
      INNER JOIN partners b ON b.id = x.cust_id 
      LEFT JOIN forexs d on x.forex_id=d.id
      where a.stat='${stat}' and strftime('%Y-%m-%d', a.dodate)='${dodate}' and a.nowhout='None'
      group by a.id,a.nodo,a.dodate,a.stat,x.cust_id,x.noso,
      x.tp,x.forex_id,x.kurs,b.kdpartner,b.nmpartner,b.address,b.city,b.zipcode,d.simbol,d.nmforex
      ORDER BY a.id DESC`,
      []
    )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return "error on getting Data " + JSON.stringify(e);
    });
}

async getListGr(stat:string,grdate: any) {
  return this.databaseObj
    .executeSql(
      `SELECT a.id,a.nogr,strftime ('%Y-%m-%d', a.grdate) as grdate,a.stat,x.vendor_id,x.nopo,
      x.tp,x.forex_id,x.kurs,b.kdpartner,b.nmpartner,b.address,b.city,b.zipcode,d.simbol,d.nmforex,
      case when c.qty is null then 0 else sum(c.qty*x.kurs*c.harga-c.diskon) end total
      FROM terimas a
      LEFT JOIN belis x on a.beli_id=x.id 
      LEFT JOIN terimadets c on a.id=c.terima_id 
      INNER JOIN partners b ON b.id = x.vendor_id 
      LEFT JOIN forexs d on x.forex_id=d.id
      where a.stat='${stat}' and strftime('%Y-%m-%d', a.grdate)='${grdate}' and a.nowhin='None'
      group by a.id,a.nogr,a.grdate,a.stat,x.vendor_id,x.nopo,
      x.tp,x.forex_id,x.kurs,b.kdpartner,b.nmpartner,b.address,b.city,b.zipcode,d.simbol,d.nmforex
      ORDER BY a.id DESC`,
      []
    )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return "error on getting Data " + JSON.stringify(e);
    });
}

async getPiutangs(startdate: any, enddate: any) {
  return this.databaseObj
     
    .executeSql(
      `SELECT a.id,a.nopt,strftime ('%Y-%m-%d', a.ptdate) as ptdate,a.stat,x.cust_id,x.noso,
      x.tp,x.forex_id,x.kurs,b.kdpartner,b.nmpartner,b.address,b.city,b.zipcode,d.simbol,d.nmforex,
      a.grandpt as total, a.paidpt,a.stinv,e.kirim_id
      FROM kirims a
      LEFT JOIN juals x on a.jual_id=x.id 
      INNER JOIN partners b ON b.id = x.cust_id 
      LEFT JOIN forexs d on x.forex_id=d.id
      LEFT JOIN bkindets e on a.id=e.kirim_id
      where a.nopt<>'' and a.nopt is not null and strftime('%Y-%m-%d', a.ptdate) between '${startdate}' and '${enddate}'
      ORDER BY a.id DESC`,
      []
    )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return "error on getting Receivable" + JSON.stringify(e);
    });
}


async rptSumPiutangs(startdate: any, enddate: any) {
  return this.databaseObj
     
    .executeSql(
      `SELECT a.nopt as [Invoice AR No],strftime ('%Y-%m-%d', a.ptdate) as [Invoice AR Date],a.stat as Status,x.noso as [SO No],
      x.tp as Type,b.kdpartner as [ID Cust],b.nmpartner as [Cust Name],b.address || ' ' || b.city || ' ' || b.zipcode as Address, 
      a.grandpt as Total, a.paidpt as [AR Paid],a.stinv as [AR Status]
      FROM kirims a
      LEFT JOIN juals x on a.jual_id=x.id 
      INNER JOIN partners b ON b.id = x.cust_id 
      LEFT JOIN forexs d on x.forex_id=d.id
      LEFT JOIN bkindets e on a.id=e.kirim_id
      where a.nopt<>'' and a.nopt is not null and strftime('%Y-%m-%d', a.ptdate) between '${startdate}' and '${enddate}'
      ORDER BY a.nopt DESC`,
      []
    )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return "error on getting Receivable" + JSON.stringify(e);
    });
}


async getTotalPiutang(id: number) {
  return this.databaseObj.executeSql(`SELECT d.simbol,sum((b.qty*b.harga*x.kurs)- (b.qty *b.diskon * x.kurs)) as toti,sum((b.qty*b.harga*x.kurs)- (b.qty *b.diskon * x.kurs)) + (sum((b.qty*b.harga*x.kurs)- (b.qty *b.diskon * x.kurs)) * 0.11) as total 
  FROM kirims a 
  left join juals x on a.jual_id=x.id
  left join kirimdets b on a.id=b.kirim_id  
  left join products c on b.product_id=c.id
  left join forexs d on x.forex_id=d.id
  WHERE a.id = ${id}  
  group by d.simbol
  `, []).then(res => { 
    return {
      total: res.rows.item(0).total,
      toti: res.rows.item(0).toti,
      simbol: res.rows.item(0).simbol,
    }
  });
}


async getPiutangHeader(id: number) {
  return this.databaseObj.executeSql(`SELECT a.id,a.nopt,a.ptdate,a.stat,e.tp,e.cust_id,e.forex_id,e.kurs,
  f.simbol,f.nmforex,d.kdpartner,d.nmpartner,d.address,d.city,d.zipcode,a.grandpt as total, a.paidpt,a.stinv
  FROM kirims a 
  left join juals e on a.jual_id=e.id
  left join partners d on e.cust_id=d.id
  left join forexs f on e.forex_id=f.id
  WHERE a.id = ${id}`, []).then(res => { 
    return {
      id: res.rows.item(0).id,
      nopt: res.rows.item(0).nopt,  
      ptdate: res.rows.item(0).ptdate,
      cust_id: res.rows.item(0).cust_id,
      forex_id: res.rows.item(0).forex_id,
      tp: res.rows.item(0).tp,
      kurs: res.rows.item(0).kurs,
      simbol: res.rows.item(0).simbol,
      nmforex: res.rows.item(0).nmforex,
      stat: res.rows.item(0).stat,
      total: res.rows.item(0).total,
      kdpartner: res.rows.item(0).kdpartner,
      nmpartner: res.rows.item(0).nmpartner,
      address: res.rows.item(0).address,
      city: res.rows.item(0).city,
      zipcode: res.rows.item(0).zipcode,
      paidpt: res.rows.item(0).paidpt,
      stinv: res.rows.item(0).stinv,
    }
  });
}


async getFormPiutang(nopt: string) {
  return this.databaseObj.executeSql(`SELECT a.id,a.nopt,a.ptdate,a.stat,e.tp,e.cust_id,e.forex_id,e.kurs,
  f.simbol,f.nmforex,d.kdpartner,d.nmpartner,d.address,d.city,d.zipcode,a.grandpt as  total ,a.paidpt,a.stinv
  FROM kirims a 
  left join juals e on a.jual_id=e.id
  left join partners d on e.cust_id=d.id
  left join forexs f on e.forex_id=f.id
  WHERE a.nopt = '${nopt}'`, []).then(res => { 
    return {
      id: res.rows.item(0).id,
      nopt: res.rows.item(0).nopt,  
      ptdate: res.rows.item(0).ptdate,
      cust_id: res.rows.item(0).cust_id,
      forex_id: res.rows.item(0).forex_id,
      tp: res.rows.item(0).tp,
      kurs: res.rows.item(0).kurs,
      simbol: res.rows.item(0).simbol,
      nmforex: res.rows.item(0).nmforex,
      stat: res.rows.item(0).stat,
      total: res.rows.item(0).total,
      kdpartner: res.rows.item(0).kdpartner,
      nmpartner: res.rows.item(0).nmpartner,
      address: res.rows.item(0).address,
      city: res.rows.item(0).city,
      zipcode: res.rows.item(0).zipcode,
      paidpt: res.rows.item(0).paidpt,
      stinv: res.rows.item(0).stinv,
    }
  });
}


async cekProductAddedPiutang(kirim_id:number,product_id:number) {
  return this.databaseObj
    .executeSql(
      `SELECT product_id FROM ${this.tables.kirimdets} 
      where kirim_id= ${kirim_id} and product_id= ${product_id}`,
      []
    )
    .then((res) => {
     
      if (res.rows.item(0).product_id==null){
          return "Y";
      }
      else{
          return "N";
      }
      
    })
    .catch((e) => {
      return "error on getting Adding " + JSON.stringify(e);
    });
}

async addPiutang(dodate: Date,cust_id: number, tp:string,forex_id:number,kurs: number) {

  var nodo=  await this.maxKirim();
  var now= await this.convertDate();

  return this.databaseObj
    .executeSql(
      `INSERT INTO ${this.tables.kirims} (nodo,dodate,cust_id,stat,tp,forex_id,kurs,lastupdate,lastuser) VALUES ('${nodo}','${dodate}', ${cust_id},'Draft','${tp}',${forex_id},'${kurs}','${now}','1')`,
      []
      
    )
    .then(() => {
      return nodo;
    })
    .catch((e) => {
      return "error on creating Transaction " + JSON.stringify(e);
    });
}


async addPiutangdet(kirim_id: number,product_id: number,qty:number) {

  var cek= await this.cekProductAddedPiutang(kirim_id,product_id);


  if (cek=='N'){

    return "N";
  }
  else{

  var now= await this.convertDate();
  return this.databaseObj
    .executeSql(
      `INSERT INTO ${this.tables.kirimdets} (kirim_id,product_id,qty,lastupdate,lastuser) VALUES ('${kirim_id}','${product_id}','${qty}','${now}','1')`,
      []
    )
    .then(() => {
      return "Y";
    })
    .catch((e) => {
      if (e.code === 6) {
        return "Product is already exists";
      }

      return "error on Added Product " + JSON.stringify(e);
    });

  }
}


async updateLoaded(id: number, nowhin: string) {
  return this.databaseObj
    .executeSql(
      `UPDATE ${this.tables.terimas} SET nowhin='${nowhin}' WHERE id = ${id}`,
      []
    )
    .then(() => {
      return "Transaction was updated";
    })
    .catch((e) => {
      return "error on updating Transaction" + JSON.stringify(e);
    });
}

async updateUnLoaded(id: number, nowhout: string) {
  return this.databaseObj
    .executeSql(
      `UPDATE ${this.tables.kirims} SET nowhout='${nowhout}' WHERE id = ${id}`,
      []
    )
    .then(() => {
      return "Transaction was updated";
    })
    .catch((e) => {
      return "error on updating Transaction" + JSON.stringify(e);
    });
}


async editPiutang(id: number,ptdate: Date,nopt: string) {
  return this.databaseObj
    .executeSql(
      `UPDATE ${this.tables.kirims} SET ptdate=DATE('${ptdate}','+1 day'),nopt='${nopt}' WHERE id = ${id}`,
      []
    )
    .then(() => {
      return "Transaction was updated";
    })
    .catch((e) => {
      return "error on updating Transaction" + JSON.stringify(e);
    });
}


async editPiutangdet(harga: number,diskon: number, id: number) {
  return this.databaseObj
    .executeSql(
      `UPDATE ${this.tables.kirimdets} SET harga = ${harga},diskon = ${diskon} WHERE id = ${id}`,
      []
    )
    .then(() => {
      return "Detail updated";
    })
    .catch((e) => {
      if (e.code === 6) {
        return "Detail already exist";
      }

      return "error on updating Detail" + JSON.stringify(e);
    });
}



async approvePiutang(id: number) {
  return this.databaseObj
    .executeSql(`update ${this.tables.kirims} set stinv='Approved' WHERE id = ${id}`, [])
    .then(() => {
      return "Transaction was Approved";
    })
    .catch((e) => {
      return "error on Approving Transaction " + JSON.stringify(e);
    });
}



async maxWhout() {

  var tg = new Date();
 
  var tahun = tg.getFullYear();
  var bln = tg.getMonth()+1;
  var bulan;

  if (bln<10){
    bulan="0"+bln.toString();
  }else{
    bulan=bln.toString();
  }

  return this.databaseObj
    .executeSql(
      `SELECT max(substr(nowhout,9,4)) as lastno,strftime('%m',whoutdate) as mth FROM ${this.tables.whouts} 
      where strftime('%Y',whoutdate)='${tahun}'  and strftime('%m',whoutdate)='${bulan}'`,
      []
    )
    .then((res) => {

      
      var angka: string;
      var lth: number;
      var result: number;

      var prefix: string;

     
      result= parseInt(res.rows.item(0).lastno)+1;
      angka= result.toString();

     
      prefix="WO";
      

      if (res.rows.item(0).lastno==null || res.rows.item(0).lastno==''){
          return prefix+ tahun+bulan+"0001";

      }
      else{

        var str= "0";
        
        lth=angka.length;
        return prefix + tahun + bulan + str.repeat(4-lth)+ result;
   
 
      }
      
    })
    .catch((e) => {
      return "error on getting Sales Order " + JSON.stringify(e);
    });
}


async maxWhin() {

  var tg = new Date();
 
  var tahun = tg.getFullYear();
  var bln = tg.getMonth()+1;
  var bulan;

  if (bln<10){
    bulan="0"+bln.toString();
  }else{
    bulan=bln.toString();
  }

  return this.databaseObj
    .executeSql(
      `SELECT max(substr(nowhin,9,4)) as lastno,strftime('%m',whindate) as mth FROM ${this.tables.whins} 
      where strftime('%Y',whindate)='${tahun}'  and strftime('%m',whindate)='${bulan}'`,
      []
    )
    .then((res) => {

      
      var angka: string;
      var lth: number;
      var result: number;

      var prefix: string;

     
      result= parseInt(res.rows.item(0).lastno)+1;
      angka= result.toString();

     
      prefix="WI";
      

      if (res.rows.item(0).lastno==null || res.rows.item(0).lastno==''){
          return prefix+ tahun+bulan+"0001";

      }
      else{

        var str= "0";
        
        lth=angka.length;
        return prefix + tahun + bulan + str.repeat(4-lth)+ result;
   
 
      }
      
    })
    .catch((e) => {
      return "error on getting Sales Order " + JSON.stringify(e);
    });
}


//============== JUAL =====================


async maxJual(tr) {

  var tg = new Date();
 
  var tahun = tg.getFullYear();
  var bln = tg.getMonth()+1;
  var bulan;

  if (bln<10){
    bulan="0"+bln.toString();
  }
  else{
    bulan=bln.toString();
  }

  return this.databaseObj
    .executeSql(
      `SELECT max(substr(noso,9,4)) as lastno,strftime('%m',sodate) as mth FROM ${this.tables.juals} 
      where strftime('%Y',sodate)='${tahun}'  and strftime('%m',sodate)='${bulan}' and jenis='${tr}'`,
      []
    )
    .then((res) => {

      
      var angka: string;
      var lth: number;
      var result: number;

      var prefix: string;

     
      result= parseInt(res.rows.item(0).lastno)+1;
      angka= result.toString();

      if (tr=='so'){
        prefix="SO";
      }
      else if (tr=='sb'){
        prefix="SB";
      }
      else{
        prefix="PS";
      }

      if (res.rows.item(0).lastno==null || res.rows.item(0).lastno==''){
          return prefix+ tahun+bulan+"0001";
         // return res.rows.item(0).lastno;
      }
      else{

        var str= "0";
        
        lth=angka.length;
        return prefix + tahun + bulan + str.repeat(4-lth)+ result;
       //return res.rows.item(0).mth;
 
      }
      
    })
    .catch((e) => {
      return "error on getting Sales Order " + JSON.stringify(e);
    });
}



async getListJuals(stat:string) {
  return this.databaseObj

    .executeSql(
      `SELECT a.id, a.noso, strftime ('%Y-%m-%d', a.sodate) as sodate , a.cust_id,
      b.kdpartner,b.nmpartner,b.address,b.city,b.zipcode,a.stat,a.tp ,a.forex_id,a.kurs,
      e.simbol,a.branch_id,g.nmbranch,case when c.qty is null then 0 else sum(c.qty*a.kurs*d.hargajual) end total
      FROM juals a
      INNER JOIN partners b ON b.id = a.cust_id 
      LEFT JOIN jualdets c on a.id=c.jual_id 
      LEFT JOIN products d on c.product_id=d.id 
      left JOIN forexs e ON a.forex_id = e.id 
      left JOIN kirims f ON a.id = f.jual_id 
      left JOIN branches g ON a.branch_id = g.id 
      where a.stat='${stat}' and f.jual_id is null
      group by a.id,a.noso,a.sodate , a.cust_id,
      b.kdpartner,b.nmpartner,b.address,b.city,b.zipcode,a.stat,a.tp ,a.forex_id,a.kurs,e.simbol,a.branch_id,g.nmbranch
      ORDER BY a.id DESC`,
      []
    )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return "error on getting Data " + JSON.stringify(e);
    });
}





async getPos(startdate: any, enddate: any) {

  return this.databaseObj

    .executeSql(
      `SELECT a.id, a.noso, strftime ('%Y-%m-%d', a.sodate) as sodate , a.cust_id,
      b.kdpartner,b.nmpartner,b.address,b.city,b.zipcode,a.stat,a.tp ,a.forex_id,a.kurs,
      e.simbol,f.jual_id,a.branch_id,g.nmbranch,
      case when c.qty is null then 0 else sum(c.qty*a.kurs*(d.hargajual-d.diskon2)) end total
      FROM juals a
      INNER JOIN partners b ON b.id = a.cust_id 
      LEFT JOIN jualdets c on a.id=c.jual_id 
      LEFT JOIN products d on c.product_id=d.id 
      left JOIN forexs e ON a.forex_id = e.id 
      left JOIN kirims f ON a.id = f.jual_id 
      left JOIN branches g ON a.branch_id = g.id 
      where a.jenis='ps' and a.sodate between '${startdate}' and '${enddate}'
      group by a.id,a.noso,a.sodate , a.cust_id,
      b.kdpartner,b.nmpartner,b.address,b.city,b.zipcode,a.stat,a.tp ,a.forex_id,a.kurs,e.simbol,f.jual_id,a.branch_id,g.nmbranch
      ORDER BY a.id DESC`,
      []
    )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return "error on getting Sales Order " + JSON.stringify(e);
    });
}


async getJuals(stat:string, tr:string,startdate: any, enddate: any) {

  //      where a.stat like  '%${stat}%'
  var sQ= `SELECT a.id, a.noso, strftime ('%Y-%m-%d', a.sodate) as sodate , a.cust_id,
  b.kdpartner,b.nmpartner,b.address,b.city,b.zipcode,a.stat,a.tp ,
  a.forex_id,a.kurs,e.simbol,f.jual_id,a.branch_id,g.nmbranch,
  case when c.qty is null then 0 else sum(c.qty*a.kurs*(d.hargajual-d.diskon2)) end total
  FROM juals a
  INNER JOIN partners b ON b.id = a.cust_id 
  LEFT JOIN jualdets c on a.id=c.jual_id 
  LEFT JOIN products d on c.product_id=d.id 
  left JOIN forexs e ON a.forex_id = e.id 
  left JOIN kirims f ON a.id = f.jual_id 
  left JOIN branches g ON a.branch_id = g.id 
  where a.jenis= '${tr}' and strftime('%Y-%m-%d', a.sodate) between '${startdate}' and '${enddate}'
  group by a.id,a.noso,a.sodate , a.cust_id,
  b.kdpartner,b.nmpartner,b.address,b.city,b.zipcode,a.stat,a.tp ,a.forex_id,a.kurs,e.simbol,f.jual_id,a.branch_id,g.nmbranch
  ORDER BY a.id DESC`;

  return this.databaseObj
    .executeSql(
     sQ,
      []
    )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return "error on getting Sales Order " + JSON.stringify(e);
    });
}

//   where a.jenis= '${tr}' and strftime('%Y-%m-%d', a.sodate) between '${startdate}' and '${enddate}'
  


async rptSumJuals(startdate: any, enddate: any,tr: string) {

  return this.databaseObj

    .executeSql(
      `SELECT a.noso as [No SO], strftime ('%Y-%m-%d', a.sodate) as [SO Date] , b.kdpartner as [ID Cust],b.nmpartner as [Cust Name],
      b.address || ' ' || b.city || ' ' || b.zipcode as Address,a.stat as Status,a.tp as Type 
      ,g.nmbranch as [Branch Name],case when c.qty is null then 0 else sum(c.qty*a.kurs*(d.hargajual-d.diskon2)) end Total
      FROM juals a
      INNER JOIN partners b ON b.id = a.cust_id 
      LEFT JOIN jualdets c on a.id=c.jual_id 
      LEFT JOIN products d on c.product_id=d.id 
      left JOIN forexs e ON a.forex_id = e.id 
      left JOIN kirims f ON a.id = f.jual_id 
      left JOIN branches g ON a.branch_id = g.id 
      where a.jenis= '${tr}' and strftime('%Y-%m-%d', a.sodate) between '${startdate}' and '${enddate}'
      group by a.noso,a.sodate , b.kdpartner,b.nmpartner,b.address,b.city,b.zipcode,a.stat,a.tp ,g.nmbranch
      ORDER BY a.noso DESC`,
      []
    )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return "error on getting Sales Order " + JSON.stringify(e);
    });
}



async addJual( tr: string,sodate: Date,cust_id: number, tp:string,forex_id:number,kurs: number,branch_id: number) {

  var noso=  await this.maxJual(tr);
  var now= await this.convertDate();

  return this.databaseObj
    .executeSql(
      `INSERT INTO ${this.tables.juals} (noso,sodate,cust_id,stat,tp,forex_id,kurs,jenis,branch_id,lastupdate,lastuser) VALUES ('${noso}','${sodate}', ${cust_id},'Draft','${tp}',${forex_id},${kurs},'${tr}','${branch_id}','${now}','1')`,
      []
      
    )
    .then(() => {
      return noso;
    })
    .catch((e) => {
      return "error on creating Transaction " + JSON.stringify(e);
    });

  

}

async addPos(cust_id: number, tp:string,forex_id:number,kurs: number) {

  var noso=  await this.maxJual('ps');
  var now= await this.convertDate();

  return this.databaseObj
    .executeSql(
      `INSERT INTO ${this.tables.juals} (noso,sodate,cust_id,stat,tp,forex_id,kurs,jenis,branch_id,uang,kembali,lastupdate,lastuser) VALUES ('${noso}','${now}', ${cust_id},'Draft','${tp}',${forex_id},${kurs},'ps','1',1,1,'${now}','1')`,
      []
      
    )
    .then(() => {
      return noso;
    })
    .catch((e) => {
      return "error on creating Transaction " + JSON.stringify(e);
    });

  

}

async approveJual(id: number,sodate: Date) {
  return this.databaseObj
    .executeSql(`update ${this.tables.juals} set stat='Approved',sodate='${sodate}' WHERE id = ${id}`, [])
    .then(() => {
      return "Transaction was Approved";
    })
    .catch((e) => {
      return "error on Approving Transaction " + JSON.stringify(e);
    });
}



async approvePos(id: number,uang: number,kembali: number) {
  return this.databaseObj
    .executeSql(`update ${this.tables.juals} set stat='Approved',uang=${uang},kembali=${kembali} WHERE id = ${id}`, [])
    .then(() => {
      return "Transaction was Approved";
    })
    .catch((e) => {
      return "error on Approving Transaction " + JSON.stringify(e);
    });
}


async revertJual(id: number, tr: string) {
  return this.databaseObj
    .executeSql(`update ${this.tables.juals} set stat='Draft' WHERE id = ${id}`, [])
    .then(() => {

      return "Transaction was Reverted";

        
    })
    .catch((e) => {
      return "error on Reverting Transaction " + JSON.stringify(e);
    });

    
}

async revertPos(id: number) {
  return this.databaseObj
    .executeSql(`update ${this.tables.juals} set stat='Draft' WHERE id = ${id}`, [])
    .then(() => {

      return "Transaction was Reverted";

        
    })
    .catch((e) => {
      return "error on Reverting Transaction " + JSON.stringify(e);
    });

    
}

//var app=  this.updateProduct(id,tr,'revert');
updateProduct(id: number,tr:string,act: string) {

  if (tr=='po' && act=='approve'){
    var sql = "update jualdets a inner join products b on a.product_id=b.id set b.qty=b.qty+a.qty where a.beli_id=${id}";
  }
  else if (tr=='po' && act=='revert'){
    var sql = "update jualdets a inner join products b on a.product_id=b.id set b.qty=b.qty-a.qty where a.beli_id=${id}";
  }


  return this.databaseObj
    .executeSql(sql, [])
    .then(() => {
      return "Sales Order Detail deleted";
    })
    .catch((e) => {
      return "error on deleting Sales Order Detail " + JSON.stringify(e);
    });
}


async deleteJual(id: number) {
  return this.databaseObj
    .executeSql(`DELETE FROM ${this.tables.juals} WHERE id = ${id}`, [])
    .then(() => {
      return "Sales Order deleted";
    })
    .catch((e) => {
      return "error on deleting Sales Order " + JSON.stringify(e);
    });
}


async deletePos(id: number) {
  return this.databaseObj
    .executeSql(`DELETE FROM ${this.tables.juals} WHERE id = ${id}`, [])
    .then(() => {
      return "Point of Sale deleted";
    })
    .catch((e) => {
      return "error on deleting Point of Sale " + JSON.stringify(e);
    });
}


async deletePosdetAll(id: number) {
  return this.databaseObj
    .executeSql(`DELETE FROM ${this.tables.jualdets} WHERE jual_id = ${id}`, [])
    .then(() => {
      return "Data was deleted";
    })
    .catch((e) => {
      return "error on deleting Sales Order Detail " + JSON.stringify(e);
    });
}



async deleteJualdetAll(id: number) {
  return this.databaseObj
    .executeSql(`DELETE FROM ${this.tables.jualdets} WHERE jual_id = ${id}`, [])
    .then(() => {
      return "Data was deleted";
    })
    .catch((e) => {
      return "error on deleting Sales Order Detail " + JSON.stringify(e);
    });
}

async editJual(id: number,noso: string,sodate: Date,cust_id: number, stat: string, tp: string,forex_id:number,kurs: number, branch_id: number) {
  return this.databaseObj
    // .executeSql(
    //   `UPDATE ${this.tables.juals} SET noso = '${noso}',sodate=DATE('${sodate}','+1 day'), cust_id = ${cust_id},stat='${stat}',tp='${tp}',forex_id='${forex_id}',kurs='${kurs}',branch_id='${branch_id}' WHERE id = ${id}`,
    //   []
    // )
    .executeSql(
      `UPDATE ${this.tables.juals} SET noso = '${noso}',sodate=DATE('${sodate}'), cust_id = ${cust_id},stat='${stat}',tp='${tp}',forex_id='${forex_id}',kurs='${kurs}',branch_id='${branch_id}' WHERE id = ${id}`,
      []
    )
    .then(() => {
      return "Sales Order was updated";
    })
    .catch((e) => {
      return "error on updating Sales Order " + JSON.stringify(e);
    });
}

async editPos(id: number,noso: string,sodate: Date,cust_id: number, stat: string, tp: string,forex_id:number,kurs: number) {
  return this.databaseObj
    .executeSql(
      `UPDATE ${this.tables.juals} SET sodate='${sodate}' WHERE id = ${id}`,
      []
    )
    .then(() => {
      return "Sales Order was updated";
    })
    .catch((e) => {
      return "error on updating Sales Order " + JSON.stringify(e);
    });
}

async deleteJualdet(id: number) {
  return this.databaseObj
    .executeSql(`DELETE FROM ${this.tables.jualdets} WHERE id = ${id}`, [])
    .then(() => {
      return "Sales Order Detail deleted";
    })
    .catch((e) => {
      return "error on deleting Sales Order Detail " + JSON.stringify(e);
    });
}

async deletePosdet(id: number) {
  return this.databaseObj
    .executeSql(`DELETE FROM ${this.tables.jualdets} WHERE id = ${id}`, [])
    .then(() => {
      return "Sales Order Detail deleted";
    })
    .catch((e) => {
      return "error on deleting Sales Order Detail " + JSON.stringify(e);
    });
}


async getJualdets(id:number) {
  return this.databaseObj

    .executeSql(
      `SELECT c.id,c.jual_id,c.product_id, a.stat,d.kdproduct,d.nmproduct,f.nmunit,d.spek,c.qty,d.hargajual,d.diskon2,a.kurs,e.simbol,
      (c.qty * (d.hargajual-d.diskon2) * a.kurs) as total
      FROM juals a
      LEFT JOIN jualdets c on a.id=c.jual_id 
      LEFT JOIN products d on c.product_id=d.id 
      left JOIN forexs e ON a.forex_id = e.id 
      left JOIN units f ON d.unit_id = f.id 
      where a.id= ${id} and c.product_id is not null
      ORDER BY c.id DESC`,
      []
    )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return "error on getting Detail Sales Order " + JSON.stringify(e);
    });
}

async rptDetJuals(startdate:Date, enddate: Date, tr: string) {
  return this.databaseObj

    .executeSql(
      `SELECT a.noso as [No.PO], strftime ('%Y-%m-%d', a.sodate) as [SO Date],d.kdproduct as [ID Product],d.nmproduct as [Product Name],f.nmunit as Unit,d.spek as Spec,c.qty as Qty,
      d.hargajual as Price,d.diskon2 as Discount,(c.qty * (d.hargajual-d.diskon2) * a.kurs) as Total
      FROM juals a
      LEFT JOIN jualdets c on a.id=c.jual_id 
      LEFT JOIN products d on c.product_id=d.id 
      left JOIN forexs e ON a.forex_id = e.id 
      left JOIN units f ON d.unit_id = f.id 
      where strftime ('%Y-%m-%d', a.sodate) between '${startdate}' and '${enddate}' and c.product_id is not null
      ORDER BY a.sodate DESC`,
      []
    )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return "error on getting Detail Sales Order " + JSON.stringify(e);
    });
}


async getPosdets(id:number) {
  return this.databaseObj

    .executeSql(
      `SELECT c.id,c.jual_id,c.product_id, a.stat,d.kdproduct,d.nmproduct,f.nmunit,d.spek,c.qty,d.hargajual,d.diskon2,a.kurs,e.simbol,
      (c.qty * (d.hargajual-d.diskon2) * a.kurs) as total
      FROM juals a
      LEFT JOIN jualdets c on a.id=c.jual_id 
      LEFT JOIN products d on c.product_id=d.id 
      left JOIN forexs e ON a.forex_id = e.id 
      left JOIN units f ON d.unit_id = f.id 
      where a.id= ${id} and c.product_id is not null
      ORDER BY c.id DESC`,
      []
    )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return "error on getting Detail Point of Sales " + JSON.stringify(e);
    });
}

async getTotalJual(id: number) {
  return this.databaseObj.executeSql(`SELECT sum((b.qty*c.hargajual*a.kurs)- (b.qty * c.diskon)) as total 
  FROM juals a 
  left join jualdets b on a.id=b.jual_id  
  left join products c on b.product_id=c.id
  WHERE a.id = ${id} 
  `, []).then(res => { 
    return {
      total: res.rows.item(0).total,
    }
  });
}

async getTotalPos(id: number) {
  return this.databaseObj.executeSql(`SELECT sum((b.qty*c.hargajual*a.kurs)- (b.qty * c.diskon)) as total 
  FROM juals a 
  left join jualdets b on a.id=b.jual_id  
  left join products c on b.product_id=c.id
  WHERE a.id = ${id} 
  `, []).then(res => { 
    return {
      total: res.rows.item(0).total,
    }
  });
}


async getFormJual(noso: string) {
  return this.databaseObj.executeSql(`SELECT a.id,a.noso,a.sodate,a.cust_id,a.stat,a.tp,a.forex_id,
  a.kurs,d.kdpartner,d.nmpartner,d.address,d.city,d.zipcode,a.branch_id,
  case when b.qty is null then 0 else sum((b.qty*c.hargajual*a.kurs)- (b.qty * c.diskon)) end total 
  FROM juals a 
  left join jualdets b on a.id=b.jual_id  
  left join products c on b.product_id=c.id
  left join partners d on a.cust_id=d.id
  WHERE a.noso = '${noso}' 
  group by a.id,a.noso,a.sodate,a.cust_id,a.stat,a.tp,a.forex_id,a.kurs,d.kdpartner,d.nmpartner,d.address,d.city,d.zipcode,a.branch_id`, []).then(res => { 
    return {
      id: res.rows.item(0).id,
      noso: res.rows.item(0).noso,  
      sodate: res.rows.item(0).sodate,
      cust_id: res.rows.item(0).cust_id,
      stat: res.rows.item(0).stat,
      tp: res.rows.item(0).tp,
      forex_id: res.rows.item(0).forex_id,
      kurs: res.rows.item(0).kurs,
      total: res.rows.item(0).total,
      kdpartner: res.rows.item(0).kdpartner,
      nmpartner: res.rows.item(0).nmpartner,
      address: res.rows.item(0).address,
      city: res.rows.item(0).city,
      zipcode: res.rows.item(0).zipcode,
      branch_id: res.rows.item(0).branch_id,
    }
  });
}

async getFormPos(noso: string) {
  return this.databaseObj.executeSql(`SELECT a.id,a.noso,a.sodate,a.cust_id,a.stat,a.tp,a.forex_id,
  a.kurs,d.kdpartner,d.nmpartner,d.address,d.city,d.zipcode,a.branch_id,case when b.qty is null then 0 else sum((b.qty*c.hargajual*a.kurs)- (b.qty * c.diskon)) end total 
  FROM juals a 
  left join jualdets b on a.id=b.jual_id  
  left join products c on b.product_id=c.id
  left join partners d on a.cust_id=d.id
  WHERE a.noso = '${noso}' 
  group by a.id,a.noso,a.sodate,a.cust_id,a.stat,a.tp,a.forex_id,a.kurs,d.kdpartner,d.nmpartner,d.address,d.city,d.zipcode,a.branch_id`, []).then(res => { 
    return {
      id: res.rows.item(0).id,
      noso: res.rows.item(0).noso,  
      sodate: res.rows.item(0).sodate,
      cust_id: res.rows.item(0).cust_id,
      stat: res.rows.item(0).stat,
      tp: res.rows.item(0).tp,
      forex_id: res.rows.item(0).forex_id,
      kurs: res.rows.item(0).kurs,
      total: res.rows.item(0).total,
      branch_id: res.rows.item(0).branch_id,
    }
  });
}

async cekProductAddedJual(jual_id:number,product_id:number) {
  return this.databaseObj
    .executeSql(
      `SELECT product_id FROM ${this.tables.jualdets} 
      where jual_id= ${jual_id} and product_id= ${product_id}`,
      []
    )
    .then((res) => {
     
      if (res.rows.item(0).product_id==null){
          return "Y";
      }
      else{
          return "N";
      }
      
    })
    .catch((e) => {
      return "error on getting partner " + JSON.stringify(e);
    });
}

async cekProductAddedPos(jual_id:number,product_id:number) {
  return this.databaseObj
    .executeSql(
      `SELECT product_id FROM ${this.tables.jualdets} 
      where jual_id= ${jual_id} and product_id= ${product_id}`,
      []
    )
    .then((res) => {
     
      if (res.rows.item(0).product_id==null){
          return "Y";
      }
      else{
          return "N";
      }
      
    })
    .catch((e) => {
      return "error on getting partner " + JSON.stringify(e);
    });
}


async addJualdet(jual_id: number,product_id: number,qty:number) {


  var cek= await this.cekProductAddedJual(jual_id,product_id);


  if (cek=='N'){

    return "N";
  }
  else{

  var now= await this.convertDate();
  return this.databaseObj
    .executeSql(
      `INSERT INTO ${this.tables.jualdets} (jual_id,product_id,qty,lastupdate,lastuser) VALUES ('${jual_id}','${product_id}','${qty}','${now}','1')`,
      []
    )
    .then(() => {
      return "Add Detail Barang created";
    })
    .catch((e) => {
      if (e.code === 6) {
        return "Detail Barang already exists";
      }

      return "error on creating Forex " + JSON.stringify(e);
    });

  }
}

async addPosdet(jual_id: number,product_id: number,qty:number) {


  var cek= await this.cekProductAddedJual(jual_id,product_id);


  if (cek=='N'){

    return "N";
  }
  else{

  var now= await this.convertDate();
  return this.databaseObj
    .executeSql(
      `INSERT INTO ${this.tables.jualdets} (jual_id,product_id,qty,lastupdate,lastuser) VALUES ('${jual_id}','${product_id}','${qty}','${now}','1')`,
      []
    )
    .then(() => {
      return "Add Detail Barang created";
    })
    .catch((e) => {
      if (e.code === 6) {
        return "Detail Barang already exists";
      }

      return "error on creating Forex " + JSON.stringify(e);
    });

  }
}

async editJualdet(product_id: number,qty: number, id: number) {
  return this.databaseObj
    .executeSql(
      `UPDATE ${this.tables.jualdets} SET product_id = ${product_id},qty = ${qty} WHERE id = ${id}`,
      []
    )
    .then(() => {
      return "Detail SO updated";
    })
    .catch((e) => {
      if (e.code === 6) {
        return "Detail SO already exist";
      }

      return "error on updating Detail SO " + JSON.stringify(e);
    });
}


async editPosdet(product_id: number,qty: number, id: number) {
  return this.databaseObj
    .executeSql(
      `UPDATE ${this.tables.jualdets} SET product_id = ${product_id},qty = ${qty} WHERE id = ${id}`,
      []
    )
    .then(() => {
      return "Detail SO updated";
    })
    .catch((e) => {
      if (e.code === 6) {
        return "Detail SO already exist";
      }

      return "error on updating Detail SO " + JSON.stringify(e);
    });
}


//Kirim

async getKirims(startdate: any, enddate: any) {
  return this.databaseObj
     
    .executeSql(
      `SELECT a.id,a.nodo,strftime ('%Y-%m-%d', a.dodate) as dodate,a.stat,x.cust_id,x.noso,
      x.tp,x.forex_id,x.kurs,b.kdpartner,b.nmpartner,b.address,b.city,b.zipcode,
      d.simbol,d.nmforex,a.nopt,a.wh_id,e.nmwh,a.refno,a.pengirim,
      case when c.qty is null then 0 else sum(c.qty*x.kurs*c.harga-c.diskon) + (sum(c.qty*x.kurs*c.harga-c.diskon) * 0.11) end total
      FROM kirims a
      LEFT JOIN juals x on a.jual_id=x.id 
      LEFT JOIN kirimdets c on a.id=c.kirim_id 
      INNER JOIN partners b ON b.id = x.cust_id 
      LEFT JOIN forexs d on x.forex_id=d.id
      LEFT JOIN whs e on a.wh_id=e.id
      where strftime ('%Y-%m-%d', a.dodate) between '${startdate}' and '${enddate}'
      group by a.id,a.nodo,a.dodate,a.stat,x.cust_id,x.noso,
      x.tp,x.forex_id,x.kurs,b.kdpartner,b.nmpartner,b.address,b.city,b.zipcode
      ,d.simbol,d.nmforex,a.nopt,a.wh_id,e.nmwh,a.refno,a.pengirim
      ORDER BY a.id DESC`,
      []
    )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return "error on getting Delivery Order " + JSON.stringify(e);
    });
}


async rptSumKirims(startdate: any, enddate: any) {
  return this.databaseObj
     
    .executeSql(
      `SELECT a.nodo as [No DO],strftime ('%Y-%m-%d', a.dodate) as [DO Date],a.stat as Status,e.nmwh as Warehouse,
      x.noso as [SO No],x.tp as Type,b.kdpartner as [ID Cust],b.nmpartner as [Cust Name],b.address || ' ' || b.city || ' ' || b.zipcode as Address,
      case when c.qty is null then 0 else sum(c.qty*x.kurs*c.harga-c.diskon) + (sum(c.qty*x.kurs*c.harga-c.diskon) * 0.11) end Total
      FROM kirims a
      LEFT JOIN juals x on a.jual_id=x.id 
      LEFT JOIN kirimdets c on a.id=c.kirim_id 
      INNER JOIN partners b ON b.id = x.cust_id 
      LEFT JOIN forexs d on x.forex_id=d.id
      LEFT JOIN whs e on a.wh_id=e.id
      where strftime ('%Y-%m-%d', a.dodate) between '${startdate}' and '${enddate}'
      group by a.nodo,a.dodate,a.stat,e.nmwh,x.noso,
      x.tp,b.kdpartner,b.nmpartner,b.address,b.city,b.zipcode
      ORDER BY a.nodo DESC`,
      []
    )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return "error on getting Delivery Order " + JSON.stringify(e);
    });
}


async rptSumWhouts(startdate: any, enddate: any) {
  return this.databaseObj
     
    .executeSql(
      `SELECT a.nowhout as [No WH GO],strftime ('%Y-%m-%d', a.whoutdate) as [WH GO Date],a.stat as Status,e.nmwh as Warehouse,
      c.qty as [Qty],d.kdproduct as [ID Product],d.nmproduct as [Product Name],d.spek as [Spec],f.nmunit as [Unit Name]
      FROM whouts a
      LEFT JOIN whoutdets c on a.id=c.whout_id 
      LEFT JOIN products d on c.product_id=d.id 
      LEFT JOIN whs e on a.wh_id=e.id
      LEFT JOIN units f on d.unit_id=f.id 
      where strftime ('%Y-%m-%d', a.whoutdate) between '${startdate}' and '${enddate}'
      ORDER BY a.nowhout DESC`,
      []
    )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return "error on getting Delivery Order " + JSON.stringify(e);
    });
}


async rptSumWhins(startdate: any, enddate: any) {
  return this.databaseObj
     
    .executeSql(
      `SELECT a.nowhin as [No WH GI],strftime ('%Y-%m-%d', a.whindate) as [WH GI Date],a.stat as Status,e.nmwh as Warehouse,
      c.qty as [Qty],d.kdproduct as [ID Product],d.nmproduct as [Product Name],d.spek as [Spec],f.nmunit as [Unit Name]
      FROM whins a
      LEFT JOIN whindets c on a.id=c.whin_id 
      LEFT JOIN products d on c.product_id=d.id 
      LEFT JOIN whs e on a.wh_id=e.id
      LEFT JOIN units f on d.unit_id=f.id 
      where strftime ('%Y-%m-%d', a.whindate) between '${startdate}' and '${enddate}'
      ORDER BY a.nowhin DESC`,
      []
    )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return "error on getting Delivery Order " + JSON.stringify(e);
    });
}

async revertKirim(id: number) {
  return this.databaseObj
    .executeSql(`update ${this.tables.kirims} set stat='Draft' WHERE id = ${id}`, [])
    .then(() => {
      return "Delivery Order was Reverted";
    })
    .catch((e) => {
      return "error on Reverting Delivery Order " + JSON.stringify(e);
    });
}

//TERIMA


async editTerima(id: number,grdate: Date, noht: string,refno: string, penerima: string) {
  return this.databaseObj
    // .executeSql(
    //   `UPDATE ${this.tables.terimas} SET grdate=DATE('${grdate}','+1 day') WHERE id = ${id}`,
    //   []
    // )
    .executeSql(
      `UPDATE ${this.tables.terimas} SET grdate=DATE('${grdate}'),noht='${noht}',refno='${refno}',penerima='${penerima}' WHERE id = ${id}`,
      []
    )
    .then(() => {
      return "Transaction was updated";
    })
    .catch((e) => {
      return "error on updating Transaction" + JSON.stringify(e);
    });
}



async getTerimas(startdate: any, enddate: any) {
  return this.databaseObj
     
    .executeSql(
      `SELECT a.id,a.nogr,strftime ('%Y-%m-%d', a.grdate) as grdate,a.stat,x.vendor_id,x.nopo,
      x.tp,x.forex_id,x.kurs,b.kdpartner,b.nmpartner,b.address,b.city,b.zipcode,d.simbol,d.nmforex,
      a.noht,a.htdate,a.grandht,a.paidht,a.wh_id,e.nmwh,a.refno,a.penerima,
      case when c.qty is null then 0 else sum(c.qty*x.kurs*c.harga-c.diskon) end total
      FROM terimas a
      LEFT JOIN belis x on a.beli_id=x.id 
      LEFT JOIN terimadets c on a.id=c.terima_id 
      INNER JOIN partners b ON b.id = x.vendor_id 
      LEFT JOIN forexs d on x.forex_id=d.id
      LEFT JOIN whs e on a.wh_id=e.id
      where strftime ('%Y-%m-%d', a.grdate) between '${startdate}' and '${enddate}'
      group by a.id,a.nogr,a.grdate,a.stat,x.vendor_id,x.nopo,
      x.tp,x.forex_id,x.kurs,b.kdpartner,b.nmpartner,b.address,b.city,b.zipcode,
      d.simbol,d.nmforex,a.noht,a.htdate,a.grandht,a.paidht,a.wh_id,e.nmwh,a.refno,a.penerima
      ORDER BY a.id DESC`,
      []
    )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return "error on getting Goods Receipt " + JSON.stringify(e);
    });
}


async getWhouts(startdate: any, enddate: any) {
  
  var sQ= `SELECT a.id,a.nowhout,strftime ('%Y-%m-%d', a.whoutdate) as whoutdate,a.stat
  ,a.wh_id,e.nmwh,a.refno,a.pic,a.dodate
  FROM ${this.tables.whouts} a
  LEFT JOIN whs e on a.wh_id=e.id
  where strftime ('%Y-%m-%d', a.whoutdate) between '${startdate}' and '${enddate}'
  `;

  return this.databaseObj
     
    .executeSql(
     sQ,
      []
    )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return "error on getting WH Goods out " + sQ + JSON.stringify(e);
    });
}


async getWhins(startdate: any, enddate: any) {
  
  var sQ= `SELECT a.id,a.nowhin,strftime ('%Y-%m-%d', a.whindate) as whindate,a.stat
  ,a.wh_id,e.nmwh,a.refno,a.pic,a.grdate
  FROM ${this.tables.whins} a
  LEFT JOIN whs e on a.wh_id=e.id
  where strftime ('%Y-%m-%d', a.whindate) between '${startdate}' and '${enddate}'
  `;

  return this.databaseObj
     
    .executeSql(
     sQ,
      []
    )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return "error on getting WH Goods in " + sQ + JSON.stringify(e);
    });
}


async rptSumTerimas(startdate: any, enddate: any) {
  return this.databaseObj
     
    .executeSql(
      `SELECT a.nogr as [No.GR],strftime ('%Y-%m-%d', a.grdate) as [GR Date],e.nmwh as Warehouse,a.stat as [Status],x.nopo as [No. PO],
      x.tp as Type,b.kdpartner as [ID Vendor],b.nmpartner as [Vendor Name],b.address || ' ' || b.city || ' ' || b.zipcode as Address,
      a.noht as [Invoice No. AP],a.htdate as [Invoice Date AP],case when c.qty is null then 0 else sum(c.qty*x.kurs*c.harga-c.diskon) + (sum(c.qty*x.kurs*c.harga-c.diskon) * 0.11) end Total
      FROM terimas a
      LEFT JOIN belis x on a.beli_id=x.id 
      LEFT JOIN terimadets c on a.id=c.terima_id 
      INNER JOIN partners b ON b.id = x.vendor_id 
      LEFT JOIN forexs d on x.forex_id=d.id
      LEFT JOIN whs e on a.wh_id=e.id
      where strftime ('%Y-%m-%d', a.grdate) between '${startdate}' and '${enddate}'
      group by a.id,a.nogr,a.grdate,a.stat,x.nopo,
      x.tp,b.kdpartner,b.nmpartner,b.address,b.city,b.zipcode,
      a.noht,a.htdate,a.grandht,a.paidht,e.nmwh
      ORDER BY a.nogr DESC`,
      []
    )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return "error on getting Goods Receipt " + JSON.stringify(e);
    });
}

async deleteTerima(id: number) {
  return this.databaseObj
    .executeSql(`DELETE FROM ${this.tables.terimas} WHERE id = ${id}`, [])
    .then(() => {
      return "Good Receipt deleted";
    })
    .catch((e) => {
      return "error on deleting Goods Receipt " + JSON.stringify(e);
    });
}


async deleteTerimadetAll(id: number) {
  return this.databaseObj
    .executeSql(`DELETE FROM ${this.tables.terimadets} WHERE terima_id = ${id}`, [])
    .then(() => {
      return "Data was deleted";
    })
    .catch((e) => {
      return "error on deleting Detail " + JSON.stringify(e);
    });
}


async editKirim(id: number,dodate: Date,refno: string,pengirim: string) {
  return this.databaseObj
    .executeSql(
      `UPDATE ${this.tables.kirims} SET dodate=DATE('${dodate}','+1 day'),refno='${refno}',pengirim='${pengirim}' WHERE id = ${id}`,
      []
    )
    .then(() => {
      return "Transaction was updated";
    })
    .catch((e) => {
      return "error on updating Transaction" + JSON.stringify(e);
    });
}


async cleanDO(id: number) {

  var nowhout = await this.getWHNo(id);

  return this.databaseObj
    .executeSql(
      `UPDATE ${this.tables.kirims} SET nowhout='None' WHERE nowhout = '${nowhout}'`,
      []
    )
    .then(() => {
      return "Transaction was updated";
    })
    .catch((e) => {
      return "error on updating Transaction" + JSON.stringify(e);
    });
}



async cleanGR(id: number) {

  var nowhin = await this.getWHNi(id);

  return this.databaseObj
    .executeSql(
      `UPDATE ${this.tables.terimas} SET nowhin='None' WHERE nowhin = '${nowhin}'`,
      []
    )
    .then(() => {
      return "Transaction was updated";
    })
    .catch((e) => {
      return "error on updating Transaction" + JSON.stringify(e);
    });
}


async editWhout(id: number,refno: string,pic: string) {
  return this.databaseObj
    .executeSql(
      `UPDATE ${this.tables.whouts} SET refno='${refno}',pic='${pic}' WHERE id = ${id}`,
      []
    )
    .then(() => {
      return "Transaction was updated";
    })
    .catch((e) => {
      return "error on updating Transaction" + JSON.stringify(e);
    });
}


async editWhin(id: number,refno: string,pic: string) {
  return this.databaseObj
    .executeSql(
      `UPDATE ${this.tables.whins} SET refno='${refno}',pic='${pic}' WHERE id = ${id}`,
      []
    )
    .then(() => {
      return "Transaction was updated";
    })
    .catch((e) => {
      return "error on updating Transaction" + JSON.stringify(e);
    });
}


async deleteKirim(id: number) {
  return this.databaseObj
    .executeSql(`DELETE FROM ${this.tables.kirims} WHERE id = ${id}`, [])
    .then(() => {
      return "Delivery Order deleted";
    })
    .catch((e) => {
      return "error on deleting Delivery Order " + JSON.stringify(e);
    });
}


async deleteWhout(id: number) {
  return this.databaseObj
    .executeSql(`DELETE FROM ${this.tables.whouts} WHERE id = ${id}`, [])
    .then(() => {
      return "WH Goods Out deleted";
    })
    .catch((e) => {
      return "error on deleting WH Goods Out " + JSON.stringify(e);
    });
}



async deleteWhin(id: number) {
  return this.databaseObj
    .executeSql(`DELETE FROM ${this.tables.whins} WHERE id = ${id}`, [])
    .then(() => {
      return "WH Goods In deleted";
    })
    .catch((e) => {
      return "error on deleting WH Goods In " + JSON.stringify(e);
    });
}



async deleteKirimdetAll(id: number) {
  return this.databaseObj
    .executeSql(`DELETE FROM ${this.tables.kirimdets} WHERE kirim_id = ${id}`, [])
    .then(() => {
      return "Data was deleted";
    })
    .catch((e) => {
      return "error on deleting Detail " + JSON.stringify(e);
    });
}



async deleteWhoutdetAll(id: number) {
  return this.databaseObj
    .executeSql(`DELETE FROM ${this.tables.whoutdets} WHERE whout_id = ${id}`, [])
    .then(() => {
      return "Data was deleted";
    })
    .catch((e) => {
      return "error on deleting Detail " + JSON.stringify(e);
    });
}


async deleteWhindetAll(id: number) {
  return this.databaseObj
    .executeSql(`DELETE FROM ${this.tables.whindets} WHERE whout_id = ${id}`, [])
    .then(() => {
      return "Data was deleted";
    })
    .catch((e) => {
      return "error on deleting Detail " + JSON.stringify(e);
    });
}



async deleteHutangdet(id: number) {
  return this.databaseObj
    .executeSql(`DELETE FROM ${this.tables.terimadets} WHERE id = ${id}`, [])
    .then(() => {
      return "Detail was deleted";
    })
    .catch((e) => {
      return "error on deleting Detail " + JSON.stringify(e);
    });
}

async deletePiutangdet(id: number) {
  return this.databaseObj
    .executeSql(`DELETE FROM ${this.tables.kirimdets} WHERE id = ${id}`, [])
    .then(() => {
      return "Detail was deleted";
    })
    .catch((e) => {
      return "error on deleting Detail " + JSON.stringify(e);
    });
}

async deleteTerimadet(id: number) {
  return this.databaseObj
    .executeSql(`DELETE FROM ${this.tables.terimadets} WHERE id = ${id}`, [])
    .then(() => {
      return "Detail was deleted";
    })
    .catch((e) => {
      return "error on deleting Detail " + JSON.stringify(e);
    });
}

async deleteKirimdet(id: number) {
  return this.databaseObj
    .executeSql(`DELETE FROM ${this.tables.kirimdets} WHERE id = ${id}`, [])
    .then(() => {
      return "Detail was deleted";
    })
    .catch((e) => {
      return "error on deleting Detail " + JSON.stringify(e);
    });
}

async revertTerima(id: number) {
  return this.databaseObj
    .executeSql(`update ${this.tables.terimas} set stat='Draft' WHERE id = ${id}`, [])
    .then(() => {
      return "Goods Receipt was Reverted";
    })
    .catch((e) => {
      return "error on Reverting Goods Receipt " + JSON.stringify(e);
    });
}


async revertWhout(id: number) {
  return this.databaseObj
    .executeSql(`update ${this.tables.whouts} set stat='Draft' WHERE id = ${id}`, [])
    .then(() => {
      return "Wh Goods Out was Reverted";
    })
    .catch((e) => {
      return "error on Reverting Wh Goods Out " + JSON.stringify(e);
    });
}



async revertWhin(id: number) {
  return this.databaseObj
    .executeSql(`update ${this.tables.whins} set stat='Draft' WHERE id = ${id}`, [])
    .then(() => {
      return "Wh Goods In was Reverted";
    })
    .catch((e) => {
      return "error on Reverting Wh Goods In " + JSON.stringify(e);
    });
}


async deleteHutang(id: number) {
  return this.databaseObj
    .executeSql(`update ${this.tables.terimas} set noht=null,htdate=null,grandht=0,paidht=0,stinv=null WHERE id = ${id}`, [])
    .then(() => {
      return "Payable Invoice was Deleted";
    })
    .catch((e) => {
      return "error on Deleting Payable Invoice " + JSON.stringify(e);
    });
}


async deletePiutang(id: number) {
  return this.databaseObj
    .executeSql(`update ${this.tables.kirims} set nopt=null,ptdate=null,grandpt=0,paidpt=0,stinv=null WHERE id = ${id}`, [])
    .then(() => {
      return "Receivable Invoice was Deleted";
    })
    .catch((e) => {
      return "error on Deleting Receivable Invoice " + JSON.stringify(e);
    });
}


async revertHutang(id: number) {
  return this.databaseObj
    .executeSql(`update ${this.tables.terimas} set stinv='Draft' WHERE id = ${id}`, [])
    .then(() => {
      return "Payable Invoice was Reverted";
    })
    .catch((e) => {
      return "error on Reverting Payable Invoice " + JSON.stringify(e);
    });
}


async revertPiutang(id: number) {
  return this.databaseObj
    .executeSql(`update ${this.tables.kirims} set stinv='Draft' WHERE id = ${id}`, [])
    .then(() => {
      return "Receivable Invoice was Reverted";
    })
    .catch((e) => {
      return "error on Reverting Receivable Invoice " + JSON.stringify(e);
    });
}


async addPartner(nmpartner: string,address: string,city: string,province: string,zipcode: string,phone: string,pic: string,tp: string,term:number,ppn:number,npwp: string) {

  var kdpartner= await this.maxPartner(tp);

  return this.databaseObj
    .executeSql(
      `INSERT INTO ${this.tables.partners} (kdpartner,nmpartner,address,city,province,zipcode,phone,pic,tp,term,ppn,npwp) VALUES ('${kdpartner}','${nmpartner}','${address}','${city}','${province}','${zipcode}','${phone}','${pic}','${tp}','${term}',${ppn},'${npwp}')`,
      []
    )
    .then(() => {
      return "Partner has been Successfully created";
    })
    .catch((e) => {
      if (e.code === 6) {
        return "Partner is already exists";
      }

      return "error on creating Partner " + JSON.stringify(e);
    });
}


async addMember(nmuser: string,address: string,city: string,province: string,zipcode: string,phone: string,email: string,ktp: string,npwp: string) {

  var kduser= await this.maxUser();
  var now= await this.convertDate();

  var sQ=`INSERT INTO ${this.tables.users} (kduser,nmuser,address,city,province,zipcode,phone,email,ktp,npwp,lastupdate,lastuser) 
  VALUES ('${kduser}','${nmuser}','${address}','${city}','${province}','${zipcode}','${phone}','${email}','${ktp}','${npwp}',${now},1)`;

      
  return this.databaseObj
    .executeSql(sQ,
      []
    )
    .then(() => {
      return sQ;
    })
    .catch((e) => {
      if (e.code === 6) {
        return "User is already exists";
      }

      return "error on creating Partner "+ kduser + JSON.stringify(e);
    });
}

async addCompany(name: string,address: string, city:string,province:string,zipcode: string,phone: string,email: string,website: string,dir: string,npwp: string) {

  var now= await this.convertDate();

  return this.databaseObj
    .executeSql(
      `INSERT INTO ${this.tables.companies} (name,address,city,province,zipcode,phone,email,website,dir,npwp,lastupdate,lastuser) 
      VALUES ('${name}','${address}','${city}','${province}','${zipcode}','${phone}','${email}','${website}','${dir}','${npwp}','${now}','1')`,
      []
      
    )
    .then(() => {
      return "Company was added!";
    })
    .catch((e) => {
      return "error on creating Company " + JSON.stringify(e);
    });
}

async editCompany(id: number,name: string,address: string, city:string,province:string,zipcode: string,phone: string,email: string,website: string,dir: string,npwp: string) {
  return this.databaseObj
    .executeSql(
      `UPDATE ${this.tables.companies} SET name = '${name}',address='${address}', city = '${city}',province='${province}',zipcode='${zipcode}',phone='${phone}',
      email='${email}',website='${website}',dir='${dir}',npwp='${npwp}' WHERE id = ${id}`,
      []
    )
    .then(() => {
      return "Company was updated!";
    })
    .catch((e) => {
      return "error on updating Company" + JSON.stringify(e);
    });
}


async maxWh() {
  return this.databaseObj
    .executeSql(
      `SELECT max(substr(kdwh,2,4)) as lastno FROM ${this.tables.whs}`,
      []
    )
    .then((res) => {

      var angka: string;
      var lth: number;
      var result: number;
      var prefix: string;

    
      result= parseInt(res.rows.item(0).lastno)+1;
      angka= result.toString();


        prefix="W";
      

      if (res.rows.item(0).lastno===null){
      
          return "W0001";
       
          
      }
      else{

        var str= "0";
        
        lth=angka.length;
        return prefix + str.repeat(4-lth)+ result;
 
      }
      
    })
    .catch((e) => {
      return "error on getting Wh " + JSON.stringify(e);
    });
}


async maxBranch() {
  return this.databaseObj
    .executeSql(
      `SELECT max(substr(kdbranch,2,4)) as lastno FROM ${this.tables.branches}`,
      []
    )
    .then((res) => {

      var angka: string;
      var lth: number;
      var result: number;
      var prefix: string;

    
      result= parseInt(res.rows.item(0).lastno)+1;
      angka= result.toString();


        prefix="B";
      

      if (res.rows.item(0).lastno===null){
      
          return "B0001";
       
          
      }
      else{

        var str= "0";
        
        lth=angka.length;
        return prefix + str.repeat(4-lth)+ result;
 
      }
      
    })
    .catch((e) => {
      return "error on getting Branch " + JSON.stringify(e);
    });
}



async maxPartner(tp:string) {
  return this.databaseObj
    .executeSql(
      `SELECT max(substr(kdpartner,2,4)) as lastno FROM ${this.tables.partners} where tp='${tp}'`,
      []
    )
    .then((res) => {

      var angka: string;
      var lth: number;
      var result: number;
      var prefix: string;

    
      result= parseInt(res.rows.item(0).lastno)+1;
      angka= result.toString();


      if (tp=='1'){
        prefix="V";
      }
      else {
        prefix="C";
      }

      if (res.rows.item(0).lastno===null){
        if (tp=='1'){
          return "V0001";
        }
        else {
          return "C0001";
        }
          
      }
      else{

        var str= "0";
        
        lth=angka.length;
        return prefix + str.repeat(4-lth)+ result;
 
      }
      
    })
    .catch((e) => {
      return "error on getting categories " + JSON.stringify(e);
    });
}


async maxUser() {
  return this.databaseObj
    .executeSql(
      `SELECT max(substr(kduser,2,4)) as lastno FROM ${this.tables.users}`,
      []
    )
    .then((res) => {

      var angka: string;
      var lth: number;
      var result: number;
      var prefix: string;

    
      result= parseInt(res.rows.item(0).lastno)+1;
      angka= result.toString();


        prefix="M";
      

      if (res.rows.item(0).lastno===null){
      
          return "M0001";
       
          
      }
      else{

        var str= "0";
        
        lth=angka.length;
        return prefix + str.repeat(4-lth)+ result;
 
      }
      
    })
    .catch((e) => {
      return "error on getting Member " + JSON.stringify(e);
    });
}

async getVendors() {
  return this.databaseObj
    .executeSql(
      `SELECT * FROM ${this.tables.partners} where tp='1' ORDER BY id DESC`,
      []
    )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return "error on getting categories " + JSON.stringify(e);
    });
}


async getUsers() {
  return this.databaseObj
    .executeSql(
      `SELECT * FROM ${this.tables.users} ORDER BY id DESC`,
      []
    )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return "error on getting User " + JSON.stringify(e);
    });
}

async getRekDesc() {
  return this.databaseObj
    .executeSql(
      `SELECT a.id,a.norek,a.branch,a.atasnama,b.nmbank FROM ${this.tables.reks} a inner join banks b on a.bank_id=b.id ORDER BY a.id DESC`,
      []
    )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return "error on getting categories " + JSON.stringify(e);
    });
}

async getCusts() {
  return this.databaseObj
    .executeSql(
      `SELECT * FROM ${this.tables.partners} where tp='2' ORDER BY id DESC`,
      []
    )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return "error on getting categories " + JSON.stringify(e);
    });
}

async deletePartner(id: number) {

  var cek= await this.cekPartner(id);
  var cek2= await this.cekPartner2(id);

  if (cek=='N' || cek2=='N'){

    return "errMsg: Record can't be deleted, this Partner is still being used in another transaction!";

  }
  else{

 
  return this.databaseObj
    .executeSql(`DELETE FROM ${this.tables.partners} WHERE id = ${id}`, [])
    .then(() => {
      return "Partner was deleted";
    })
    .catch((e) => {
      return "error on deleting Partner " + JSON.stringify(e);
    });

  }
}

async cekProduct(id:number) {
  return this.databaseObj
    .executeSql(
      `SELECT product_id FROM ${this.tables.belidets} where product_id= ${id}`,
      []
    )
    .then((res) => {
     
      if (res.rows.item(0).product_id==null){
          return "Y";
      }
      else{
          return "N";
      }
      
    })
    .catch((e) => {
      return "error on getting product " + JSON.stringify(e);
    });
}

async cekProduct2(id:number) {
  return this.databaseObj
    .executeSql(
      `SELECT product_id FROM ${this.tables.jualdets} where product_id= ${id}`,
      []
    )
    .then((res) => {
     
      if (res.rows.item(0).product_id==null){
          return "Y";
      }
      else{
          return "N";
      }
      
    })
    .catch((e) => {
      return "error on getting product " + JSON.stringify(e);
    });
}


async cekProduct3(id:number) {
  return this.databaseObj
    .executeSql(
      `SELECT product_raw_id FROM ${this.tables.joborderdets} where product_raw_id= ${id}`,
      []
    )
    .then((res) => {
     
      if (res.rows.item(0).product_raw_id==null){
          return "Y";
      }
      else{
          return "N";
      }
      
    })
    .catch((e) => {
      return "error on getting product " + JSON.stringify(e);
    });
}

async cekProduct4(id:number) {
  return this.databaseObj
    .executeSql(
      `SELECT product_fg_id FROM ${this.tables.joborders} where product_fg_id= ${id}`,
      []
    )
    .then((res) => {
     
      if (res.rows.item(0).product_fg_id==null){
          return "Y";
      }
      else{
          return "N";
      }
      
    })
    .catch((e) => {
      return "error on getting product " + JSON.stringify(e);
    });
}



async cekPartner(id:number) {
  return this.databaseObj
    .executeSql(
      `SELECT vendor_id FROM ${this.tables.belis} where vendor_id= ${id}`,
      []
    )
    .then((res) => {
     
      if (res.rows.item(0).vendor_id==null){
          return "Y";
      }
      else{
          return "N";
      }
      
    })
    .catch((e) => {
      return "error on getting partner " + JSON.stringify(e);
    });
}

async cekPartner2(id:number) {
  return this.databaseObj
    .executeSql(
      `SELECT cust_id FROM ${this.tables.juals} where cust_id= ${id}`,
      []
    )
    .then((res) => {
     
      if (res.rows.item(0).cust_id==null){
          return "Y";
      }
      else{
          return "N";
      }
      
    })
    .catch((e) => {
      return "error on getting cust ID " + JSON.stringify(e);
    });
}

async editPartner(nmpartner: string,address: string,city: string,province: string,zipcode: string,phone: string,pic: string,tp: string,term:number, id: number,ppn: number,npwp: string) {
  return this.databaseObj
    .executeSql(
      `UPDATE ${this.tables.partners} SET nmpartner = '${nmpartner}',address = '${address}',city = '${city}',province = '${province}',zipcode = '${zipcode}',phone = '${phone}',pic = '${pic}',term = '${term}',ppn = '${ppn}',npwp = '${npwp}' WHERE id = ${id}`,
      []
    )
    .then(() => {
      return "Partner updated";
    })
    .catch((e) => {
      if (e.code === 6) {
        return "Partner already exist";
      }

      return "error on updating Partner " + JSON.stringify(e);
    });
}


async editMember(nmuser: string,address: string,city: string,province: string,zipcode: string,phone: string,email: string,ktp: string,npwp: string,id: number) {
  return this.databaseObj
    .executeSql(
      `UPDATE ${this.tables.users} SET nmuser = '${nmuser}',address = '${address}',city = '${city}',province = '${province}',zipcode = '${zipcode}',phone = '${phone}',email = '${email}',ktp = '${ktp}',npwp = '${npwp}' WHERE id = ${id}`,
      []
    )
    .then(() => {
      return "User updated";
    })
    .catch((e) => {
      if (e.code === 6) {
        return "User already exist";
      }

      return "error on updating User " + JSON.stringify(e);
    });
}

async getBelidets(id:number) {
  return this.databaseObj

    .executeSql(
      `SELECT c.id,c.beli_id,c.product_id, a.stat,d.kdproduct,d.nmproduct,f.nmunit,d.spek,
      c.qty,d.hargabeli,d.diskon,a.kurs,e.simbol , (c.qty * (d.hargabeli-d.diskon) * a.kurs) as total
      FROM belis a
      LEFT JOIN belidets c on a.id=c.beli_id 
      LEFT JOIN products d on c.product_id=d.id 
      left JOIN forexs e ON a.forex_id = e.id 
      left JOIN units f ON d.unit_id = f.id 
      where a.id= ${id} and c.product_id is not null
      ORDER BY c.id DESC`,
      []
    )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return "error on getting Detail Purchase Order " + JSON.stringify(e);
    });
}

async rptDetBelixxxx(startdate: Date, enddate: Date,tr: string) {
  return this.databaseObj

    .executeSql(
      `SELECT a.nopo,a.podate,c.beli_id,c.product_id, a.stat,d.kdproduct,d.nmproduct,f.nmunit,d.spek,
      c.qty,d.hargabeli,d.diskon,a.kurs,e.simbol , (c.qty * (d.hargabeli-d.diskon) * a.kurs) as total
      FROM belis a
      LEFT JOIN belidets c on a.id=c.beli_id 
      LEFT JOIN products d on c.product_id=d.id 
      left JOIN forexs e ON a.forex_id = e.id 
      left JOIN units f ON d.unit_id = f.id 
      where strftime('%Y-%m-%d', a.podate) between '${startdate}' and '${enddate}' and c.product_id is not null and a.jenis='${tr}'
      ORDER BY a.nopo,a.podate DESC`,
      []
    )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return "error on getting Detail Purchase Order " + JSON.stringify(e);
    });
}


async rptDetBeli(startdate: Date, enddate: Date,tr: string) {
  return this.databaseObj
    .executeSql(
      `SELECT a.nopo as [No. PO],strftime('%Y-%m-%d', a.podate) as [PO Date],d.kdproduct as [ID Product],d.nmproduct as [Product Name],f.nmunit as [Unit],d.spek as [Spec],
      c.qty as [Qty],d.hargabeli as [Price],d.diskon as [Discount],(c.qty * (d.hargabeli-d.diskon) * a.kurs) as [Total]
      FROM belis a
      LEFT JOIN belidets c on a.id=c.beli_id 
      LEFT JOIN products d on c.product_id=d.id 
      left JOIN forexs e ON a.forex_id = e.id 
      left JOIN units f ON d.unit_id = f.id 
      where strftime('%Y-%m-%d', a.podate) between '${startdate}' and '${enddate}' and c.product_id is not null and a.jenis='${tr}'
      ORDER BY a.nopo DESC`,
      []
    )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return "error on getting Detail Purchase Order " + JSON.stringify(e);
    });
}


//c.id,c.bkout_id, a.stat,d.noht,c.detdesc, a.kurs,e.simbol , (c.amount * a.kurs) as total
// where a.id= ${id} 
async getBkoutdets(id:number) {
  return this.databaseObj

    .executeSql(
      `SELECT c.id,c.bkout_id,c.terima_id, a.stat,c.detdesc,c.amount, a.kurs,e.simbol ,a.nobkout,d.noht,
      case when a.pf='Invoice' then d.noht else c.nostruk end nomor,d.grandht,d.paidht,d.htdate, 
      (c.amount * a.kurs) as total,c.nostruk,a.pf
      FROM bkouts a
      inner JOIN bkoutdets c on a.id=c.bkout_id 
      left JOIN terimas d on c.terima_id=d.id 
      left JOIN forexs e ON a.forex_id = e.id 
      where a.id= ${id}
      ORDER BY c.id DESC`,
      []
    )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return "error on getting Detail Bank Cash Out " + JSON.stringify(e);
    });
}

async rptDetBankouts(startdate:Date, enddate: Date) {
  return this.databaseObj

    .executeSql(
      `SELECT a.nobkout as [BK No.],strftime('%Y-%m-%d', a.bkoutdate) as [BK Date],c.detdesc as Desc,c.amount as Amount
      ,c.nostruk as Receipt,a.pf as Type, d.noht as [Invoice AP No.], case when a.pf='Invoice' then d.noht else c.nostruk end Nomor, d.grandht as [AP Invoice Amount],d.paidht as [AP Paid],d.htdate as [AP DAte]
      
      FROM bkouts a
      inner JOIN bkoutdets c on a.id=c.bkout_id 
      left JOIN terimas d on c.terima_id=d.id 
      left JOIN forexs e ON a.forex_id = e.id 
      where strftime('%Y-%m-%d', a.bkoutdate) between '${startdate}' and '${enddate}'
      ORDER BY a.nobkout DESC`,
      []
    )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return "error on getting Detail Bank Cash Out " + JSON.stringify(e);
    });
}

async getBkindets(id:number) {
  return this.databaseObj

    .executeSql(
      `SELECT c.id,c.bkin_id,c.kirim_id, a.stat,c.detdesc,c.amount, a.kurs,e.simbol ,a.nobkin,d.nopt,
      case when a.pf='Invoice' then d.nopt else c.nostruk end nomor,d.grandpt,d.paidpt,d.ptdate, 
      (c.amount * a.kurs) as total,c.nostruk,a.pf
      FROM bkins a
      inner JOIN bkindets c on a.id=c.bkin_id 
      left JOIN kirims d on c.kirim_id=d.id 
      left JOIN forexs e ON a.forex_id = e.id 
      where a.id= ${id}
      ORDER BY c.id DESC`,
      []
    )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return "error on getting Detail Bank Cash Out " + JSON.stringify(e);
    });
}


async rptDetBankins(startdate:Date, enddate: Date) {
  return this.databaseObj

    .executeSql(
      `SELECT a.nobkin as [BK No],strftime('%Y-%m-%d', a.bkindate) as [BK Date],c.detdesc as [Desc],c.amount as [Amount], d.nopt as [Invoice AR No.],
      c.nostruk as [Receipt],a.pf as Type,case when a.pf='Invoice' then d.nopt else c.nostruk end Nomor,d.grandpt as [Total AR Invoice],d.paidpt as [Total Paid AR],d.ptdate as [AR Date]
      FROM bkins a
      inner JOIN bkindets c on a.id=c.bkin_id 
      left JOIN kirims d on c.kirim_id=d.id 
      left JOIN forexs e ON a.forex_id = e.id 
      where strftime('%Y-%m-%d', a.bkindate) between '${startdate}' and '${enddate}'
      ORDER BY a.nobkin DESC`,
      []
    )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return "error on getting Detail Bank Cash Out " + JSON.stringify(e);
    });
}

async getJoborderdets(id:number) {
  return this.databaseObj

    .executeSql(
      `SELECT c.id,c.job_id, a.status,c.harga,c.qty, a.kurs,f.simbol ,a.nojob,e.kdproduct,
      e.nmproduct,e.spek,g.nmunit,c.product_raw_id,c.product_raw_id as product_id,(c.harga * c.qty * a.kurs) as total
      FROM joborders a
      inner JOIN joborderdets c on a.id=c.job_id 
      left JOIN products e ON c.product_raw_id = e.id 
      left JOIN forexs f ON a.forex_id = f.id 
      left JOIN units g ON e.unit_id = g.id 
      where a.id= ${id}
      ORDER BY c.id DESC`,
      []
    )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return "error on getting Detail " + JSON.stringify(e);
    });
}


async getMutasidets(id:number) {
  return this.databaseObj

    .executeSql(
      `SELECT c.id,c.mutasi_id, a.status,c.qty,a.nomutasi,d.kdproduct,
      d.nmproduct,g.nmunit,c.product_id,a.wh_id,a.wh_id2,e.nmwh,f.nmwh as nmwh2
      FROM mutasis a
      inner JOIN mutasidets c on a.id=c.mutasi_id 
      left JOIN products d ON c.product_id = d.id 
      left JOIN whs e ON a.wh_id = e.id 
      left JOIN whs f ON a.wh_id2 = f.id 
      left JOIN units g ON d.unit_id = g.id 
      where a.id= ${id}
      ORDER BY c.id DESC`,
      []
    )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return "error on getting Detail " + JSON.stringify(e);
    });
}


async getStodets(id:number) {
  return this.databaseObj

    .executeSql(
      `SELECT c.id,c.sto_id, a.status,c.qty,a.nosto,d.kdproduct,
      d.nmproduct,g.nmunit,c.product_id,a.wh_id,e.nmwh,c.selisih
      FROM stos a
      inner JOIN stodets c on a.id=c.sto_id 
      left JOIN products d ON c.product_id = d.id 
      left JOIN whs e ON a.wh_id = e.id 
      left JOIN units g ON d.unit_id = g.id 
      where a.id= ${id}
      ORDER BY c.id DESC`,
      []
    )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return "error on getting Detail " + JSON.stringify(e);
    });
}



async getTerimadets(id:number) {
  return this.databaseObj

    .executeSql(
      `SELECT c.id,c.terima_id,c.product_id, a.stat,d.kdproduct,d.nmproduct,f.nmunit,d.spek,
      c.qty,c.harga,c.diskon,x.kurs,e.simbol, (c.qty * (c.harga-c.diskon) * x.kurs) as total
      FROM terimas a
      LEFT JOIN belis x on a.beli_id=x.id 
      LEFT JOIN terimadets c on a.id=c.terima_id 
      LEFT JOIN products d on c.product_id=d.id 
      left JOIN forexs e ON x.forex_id = e.id 
      left JOIN units f ON d.unit_id = f.id 
      where a.id= ${id} and c.product_id is not null
      ORDER BY c.id DESC`,
      []
    )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return "error on getting Detail " + JSON.stringify(e);
    });

    //    where a.id= ${id} and c.id is not null
}




async rptDetTerimas(startdate: Date, enddate: Date) {
  return this.databaseObj

    .executeSql(
      `SELECT a.nogr as [No.GR],strftime('%Y-%m-%d', a.grdate) as [GR Date], d.kdproduct as [ID Product],d.nmproduct as [Product Name],f.nmunit as [Unit],d.spek as [Spec],c.qty as Qty,
      (c.qty * (c.harga-c.diskon) * x.kurs) as Total
      FROM terimas a
      LEFT JOIN belis x on a.beli_id=x.id 
      LEFT JOIN terimadets c on a.id=c.terima_id 
      LEFT JOIN products d on c.product_id=d.id 
      left JOIN forexs e ON x.forex_id = e.id 
      left JOIN units f ON d.unit_id = f.id 
      where strftime('%Y-%m-%d', a.grdate) between '${startdate}' and '${enddate}' and c.product_id is not null
      ORDER BY a.nogr DESC`,
      []
    )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return "error on getting Detail " + JSON.stringify(e);
    });

    //    where a.id= ${id} and c.id is not null
}


async getKirimdets(id:number) {
  return this.databaseObj

    .executeSql(
      `SELECT c.id,c.kirim_id,c.product_id, a.stat,d.kdproduct,d.nmproduct,f.nmunit,d.spek,
      c.qty,c.harga,c.diskon,x.kurs,e.simbol, (c.qty * (c.harga-c.diskon) * x.kurs) as total
      FROM kirims a
      LEFT JOIN juals x on a.jual_id=x.id 
      LEFT JOIN kirimdets c on a.id=c.kirim_id 
      LEFT JOIN products d on c.product_id=d.id 
      left JOIN forexs e ON x.forex_id = e.id 
      left JOIN units f ON d.unit_id = f.id 
      where a.id= ${id} and c.product_id is not null
      ORDER BY c.id DESC`,
      []
    )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return "error on getting Detail " + JSON.stringify(e);
    });

    //    where a.id= ${id} and c.id is not null
}



async getWhoutdets(id:number) {
  return this.databaseObj

    .executeSql(
      `SELECT c.id,c.whout_id,c.product_id, a.stat,d.kdproduct,d.nmproduct,f.nmunit,d.spek,
      c.qty
      FROM whouts a
      LEFT JOIN whoutdets c on a.id=c.whout_id 
      LEFT JOIN products d on c.product_id=d.id 
      left JOIN units f ON d.unit_id = f.id 
      where a.id= ${id} and c.product_id is not null
      ORDER BY c.id DESC`,
      []
    )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return "error on getting Detail " + JSON.stringify(e);
    });

}


async getWhindets(id:number) {
  return this.databaseObj

    .executeSql(
      `SELECT c.id,c.whin_id,c.product_id, a.stat,d.kdproduct,d.nmproduct,f.nmunit,d.spek,
      c.qty
      FROM whins a
      LEFT JOIN whindets c on a.id=c.whin_id 
      LEFT JOIN products d on c.product_id=d.id 
      left JOIN units f ON d.unit_id = f.id 
      where a.id= ${id} and c.product_id is not null
      ORDER BY c.id DESC`,
      []
    )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return "error on getting Detail " + JSON.stringify(e);
    });

}



async rptDetKirims(startdate:Date, enddate: Date) {
  return this.databaseObj

    .executeSql(
      `SELECT a.nodo as [DO No],strftime ('%Y-%m-%d', a.dodate) as [DO Date],d.kdproduct as [ID Product],
      d.nmproduct as [Product Name],f.nmunit as Unit,d.spek as Spec,
      c.qty as Qty,c.harga as Price,c.diskon as Discount, (c.qty * (c.harga-c.diskon) * x.kurs) as Total
      FROM kirims a
      LEFT JOIN juals x on a.jual_id=x.id 
      LEFT JOIN kirimdets c on a.id=c.kirim_id 
      LEFT JOIN products d on c.product_id=d.id 
      left JOIN forexs e ON x.forex_id = e.id 
      left JOIN units f ON d.unit_id = f.id 
      where strftime ('%Y-%m-%d', a.dodate) between '${startdate}' and '${enddate}' and c.product_id is not null
      ORDER BY a.nodo DESC`,
      []
    )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return "error on getting Detail " + JSON.stringify(e);
    });

    //    where a.id= ${id} and c.id is not null
}


async rptDetwhouts(startdate:Date, enddate: Date) {
  return this.databaseObj

    .executeSql(
      `SELECT a.nowhout as [WH GO No],strftime ('%Y-%m-%d', a.whoutdate) as [WH GO Date],d.kdproduct as [ID Product],
      d.nmproduct as [Product Name],f.nmunit as Unit,d.spek as Spec,
      c.qty as Qty
      FROM whouts a
      LEFT JOIN whoutdets c on a.id=c.whout_id 
      LEFT JOIN products d on c.product_id=d.id 
      left JOIN units f ON d.unit_id = f.id 
      where strftime ('%Y-%m-%d', a.dodate) between '${startdate}' and '${enddate}' and c.product_id is not null
      ORDER BY a.nowhout DESC`,
      []
    )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return "error on getting Detail " + JSON.stringify(e);
    });

    //    where a.id= ${id} and c.id is not null
}




async rptDetWhins(startdate:Date, enddate: Date) {
  return this.databaseObj

    .executeSql(
      `SELECT a.nowhin as [WH GI No],strftime ('%Y-%m-%d', a.whindate) as [WH GI Date],d.kdproduct as [ID Product],
      d.nmproduct as [Product Name],f.nmunit as Unit,d.spek as Spec,
      c.qty as Qty
      FROM whins a
      LEFT JOIN whindets c on a.id=c.whin_id 
      LEFT JOIN products d on c.product_id=d.id 
      left JOIN units f ON d.unit_id = f.id 
      where strftime ('%Y-%m-%d', a.grdate) between '${startdate}' and '${enddate}' and c.product_id is not null
      ORDER BY a.nowhin DESC`,
      []
    )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return "error on getting Detail " + JSON.stringify(e);
    });

    //    where a.id= ${id} and c.id is not null
}




async getHutangdets(id:number) {
  return this.databaseObj

    .executeSql(
      `SELECT c.id,c.terima_id,c.product_id, a.stat,d.kdproduct,d.nmproduct,f.nmunit,d.spek,
      c.qty,c.harga,c.diskon,x.kurs,e.simbol, (c.qty * (c.harga-c.diskon) * x.kurs) as total
      FROM terimas a
      LEFT JOIN belis x on a.beli_id=x.id 
      LEFT JOIN terimadets c on a.id=c.terima_id 
      LEFT JOIN products d on c.product_id=d.id 
      left JOIN forexs e ON x.forex_id = e.id 
      left JOIN units f ON d.unit_id = f.id 
      where a.id= ${id} and c.product_id is not null
      ORDER BY c.id DESC`,
      []
    )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return "error on getting Detail " + JSON.stringify(e);
    });

    //    where a.id= ${id} and c.id is not null
}

async rptDetHutangs(startdate: Date, enddate: Date) {
  return this.databaseObj

    .executeSql(
      `SELECT a.noht as [Invoice AP No.],strftime('%Y-%m-%d', a.htdate) as [Invoice AP Date], d.kdproduct as [ID Product],d.nmproduct as [Product Name],f.nmunit as Unit,d.spek as Spec,
      c.qty as Qty,c.harga as Price,c.diskon as Discount,(c.qty * (c.harga-c.diskon) * x.kurs) as Total
      FROM terimas a
      LEFT JOIN belis x on a.beli_id=x.id 
      LEFT JOIN terimadets c on a.id=c.terima_id 
      LEFT JOIN products d on c.product_id=d.id 
      left JOIN forexs e ON x.forex_id = e.id 
      left JOIN units f ON d.unit_id = f.id 
      where strftime('%Y-%m-%d', a.htdate) between '${startdate}' and '${enddate}' and c.product_id is not null
      ORDER BY a.noht DESC`,
      []
    )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return "error on getting Detail " + JSON.stringify(e);
    });

    //    where a.id= ${id} and c.id is not null
}


async getPiutangdets(id:number) {
  return this.databaseObj

    .executeSql(
      `SELECT c.id,c.kirim_id,c.product_id, a.stat,d.kdproduct,d.nmproduct,f.nmunit,d.spek,
      c.qty,c.harga,c.diskon,x.kurs,e.simbol, (c.qty * (c.harga-c.diskon) * x.kurs) as total
      FROM kirims a
      LEFT JOIN juals x on a.jual_id=x.id 
      LEFT JOIN kirimdets c on a.id=c.kirim_id 
      LEFT JOIN products d on c.product_id=d.id 
      left JOIN forexs e ON x.forex_id = e.id 
      left JOIN units f ON d.unit_id = f.id 
      where a.id= ${id} and c.product_id is not null
      ORDER BY c.id DESC`,
      []
    )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return "error on getting Detail " + JSON.stringify(e);
    });

    //    where a.id= ${id} and c.id is not null
}


async rptDetPiutangs(startdate:Date, enddate: Date) {
  return this.databaseObj

    .executeSql(
      `SELECT a.nopt as [Invoice AR No],d.kdproduct as [ID Product],d.nmproduct as [Product Name],f.nmunit as Unit,
      d.spek as Spec,c.qty as Qty,c.harga as Price,c.diskon as Discount,(c.qty * (c.harga-c.diskon) * x.kurs) + ((c.qty * (c.harga-c.diskon) * x.kurs) * 0.11) as Total
      FROM kirims a
      LEFT JOIN juals x on a.jual_id=x.id 
      LEFT JOIN kirimdets c on a.id=c.kirim_id 
      LEFT JOIN products d on c.product_id=d.id 
      left JOIN forexs e ON x.forex_id = e.id 
      left JOIN units f ON d.unit_id = f.id 
      where strftime('%Y-%m-%d', a.ptdate) between '${startdate}' and '${enddate}' and c.product_id is not null
      ORDER BY a.nopt DESC`,
      []
    )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return "error on getting Detail " + JSON.stringify(e);
    });

    //    where a.id= ${id} and c.id is not null
}


}
