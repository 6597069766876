import { Product, CartService } from './../../services/cart.service';
import { Component, OnInit, Input } from '@angular/core';
import { ModalController, AlertController } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { Router, ActivatedRoute } from '@angular/router';

import { AddpospayPage } from '../../addpospay/addpospay.page';


@Component({
  selector: 'app-cart-modal',
  templateUrl: './cart-modal.page.html',
  styleUrls: ['./cart-modal.page.scss'],
})

export class CartModalPage {

  noso: any;
  id: any;
  msg: any;
  meja_id: any;

  @Input() mMejaId: any;

  cart: Product[] = [];

  modelData: any;
  cartid: any;

  ttl: any;
  ppn: any;
  grn: any;

  constructor(private modalController: ModalController,private router: Router,private actRoute: ActivatedRoute,private  http: HttpClient,public alertController: AlertController,private cartService: CartService, private modalCtrl: ModalController, private alertCtrl: AlertController) { }

  ngOnInit() {
    

  }



  ionViewWillEnter() {

    this.cart = this.cartService.getCart();

  }

  decreaseCartItem(product) {
    this.cartService.decreaseProduct(product);
  }

  increaseCartItem(product) {
    this.cartService.addProduct(product);
  }

  removeCartItem(product) {
    this.cartService.removeProduct(product);
  }


  removeCartAll(product) {
    this.cartService.removeAll(product);
  }
  

  getTotal2() {
    return this.cart.reduce((i, j) => i + j.hargajual * j.qty, 0);
  }

  getTotal() {
    return this.cart.reduce((i, j) => i + j.hargajual * j.qty, 0);
  }

  getPpn() {
    return this.cart.reduce((i, j) => i + (j.hargajual * j.qty) * 0.11, 0);
  }

  getGrand() {
    return this.cart.reduce((i, j) => i + (j.hargajual * j.qty) + (j.hargajual * j.qty) * 0.11, 0);
  }

  close() {


      this.alertController.create({
      header: 'Confirm Alert',
      subHeader: ' Close confirm',
      message: 'This wil remove all Orders..Are you sure to Close?',
      buttons: [
        {
          text: 'Ok',
          handler: () => {
     
            //this.removeCartAll(this.cart);

            for(var i =0 ; i <=this.cart.length ; i++){

              this.removeCartAll(this.cart);

            }

            this.router.navigate(['/cafe']);

            this.modalCtrl.dismiss();

           

                                   
          }
        },
        {
          text: 'Cancel',
          handler: () => {
            
          }
        }
      ]
    }).then(res => {
      res.present();
    });


  }

  async checkout() {
    

    let alert = await this.alertCtrl.create({
      header: 'Thanks for your Order!',
      message: 'We will deliver your food as soon as possible',
      buttons: ['OK']
    });
    alert.present().then(() => {
      this.modalCtrl.dismiss();
    });
  }


  showConfirm(n) {
    this.alertController.create({
      header: 'Confirm Alert',
      subHeader: ' Payment confirm',
      message: 'Are you sure to Checkout?',
      buttons: [
        {
          text: 'Ok',
          handler: () => {

              
              let url = 'https://realbiz.id/api-rbz/api.php';
           
            
              let postData2 = new FormData();
          
              var co =  sessionStorage.getItem('xco');
              var sc =  sessionStorage.getItem('xsc');
              var userid =  sessionStorage.getItem('xuserid');
          
              var branchid =  sessionStorage.getItem('xbranchid');
              var whid =  sessionStorage.getItem('xwhid');
              var custid =  sessionStorage.getItem('xcustid');
              var categid =  sessionStorage.getItem('xcategid');

              if (this.cartid>0){
                              
              }else{
                this.cartid=0;
              }

      
              postData2.append('cartid',this.cartid);  
              postData2.append('tp','cartNoso');    
              postData2.append('co',co); 
              postData2.append('sc',sc);    
              postData2.append('userid',userid); 
          
              postData2.append('branch_id',branchid); 
              postData2.append('wh_id',whid); 
              postData2.append('cust_id',custid); 
              postData2.append('categ_id',categid); 
              postData2.append('meja_id',this.mMejaId); 
             
              let data:Observable<any> = this.http.post(url, postData2);
          
              data.subscribe((result: any)=>{
                
              //  alert(result.id);
            
                this.cartid=result.id;   
                 
                this.loopCart(result.id,result.noso,n,result.sodate,result.detil);
                     
          
              });
          

                         
          }
        },
        {
          text: 'Cancel',
          handler: () => {
            
          }
        }
      ]
    }).then(res => {
      res.present();
    });
  }

  

  loopCart(id,noso,n,sodate,detil){

    let url = 'https://realbiz.id/api-rbz/api.php';

              let postData2 = new FormData();
          
              var co = sessionStorage.getItem('xco');
              var sc =  sessionStorage.getItem('xsc');
              var user_id =  sessionStorage.getItem('xuserid');

                           
              postData2.append('co',co);
              postData2.append('sc',sc);
              postData2.append('user_id',user_id);    
              
              postData2.append('id',id);  
              
           //   alert(detil);
              if (detil==true){
                  
                  for(var i =0 ; i <this.cart.length ; i++){

                      postData2.append('tp','addcart');
                      postData2.append('noso',noso);
                      postData2.append('product_id',this.cart[i].id);
                      postData2.append('nmproduct',this.cart[i].nmproduct);
                      postData2.append('spek',this.cart[i].spek);
                      postData2.append('harga',this.cart[i].hargajual);
                      postData2.append('qty',this.cart[i].qty);

                                                                                              
                      let data:Observable<any> = this.http.post(url, postData2);
                  
                      data.subscribe((wis: any)=>{

                                        
                                            
                      });
                      
                              
                      
                  }

              }

              
              if (n=='O'){
                                                
                alert('Your orders has been processed!');
                
                for(var i =0 ; i <=this.cart.length ; i++){

                  this.removeCartAll(this.cart);
    
                }
    
                this.router.navigate(['/cafe']);
    
                this.modalCtrl.dismiss();

                


              //  this.router.navigate(['/home']);

              }else{
            
                this.openIonModal(id,noso,sodate,this.meja_id);
              }
             

  }


  async openIonModal(id,noso,sodate,meja_id) {

     // alert(id);

      // let url = 'https://realbiz.id/api-rbz/api.php';

      // let postData2 = new FormData();
  
      // var co = sessionStorage.getItem('xco');
      // var sc =  sessionStorage.getItem('xsc');
      // var userid =  sessionStorage.getItem('xuserid');

                    
      // postData2.append('co',co);
      // postData2.append('sc',sc);
      // postData2.append('userid',userid);    
      
      // postData2.append('id',id); 

      // postData2.append('tp','sumCart');

          
                                                                                
      // let data:Observable<any> = this.http.post(url, postData2);
  
      // data.subscribe((wis: any)=>{

        //  alert(wis.msg);
        //  alert(wis.sbTtl);

          this.ttl=this.getTotal();
          this.ppn=this.getPpn();
          this.grn=this.getGrand();

        //  alert(this.ttl);
        
         // this.payCart(id,noso,sodate,wis.sbTtl,wis.sbPpn,wis.sbGrand,meja_id);        
          this.payCart(id,noso,sodate,this.ttl,this.ppn,this.grn,meja_id);                
                            
      // });
                                     
                      
  }


  async payCart(id,noso,sodate,sbTtl,sbPpn,sbGrand, meja_id) {


    var xsbtotal : any;

    if (sbTtl % 1 == 0){
      xsbtotal = sbTtl+ ".00";
    }
    else{
      xsbtotal = sbTtl;
    }

    var xsbppn : any;

    if (sbPpn % 1 == 0){
      xsbppn = sbPpn+ ".00";
    }
    else{
      xsbppn = sbPpn;
    }


    var xsbgrand : any;

    if (sbGrand % 1 == 0){
      xsbgrand = sbGrand+ ".00";
    }
    else{
      xsbgrand = sbGrand;
    }


    const modal = await this.modalController.create({
      component: AddpospayPage,
      componentProps: {
        'sbtotal': xsbtotal,
        'sbppn': xsbppn,
        'sbgrand': xsbgrand,
        'sbid': id,
        'sbnoso': noso,
        'sbsodate': sodate,
        'sbmejaid': meja_id,
        'sbtepe': 'CF'
      }
    });
    modal.onDidDismiss().then((modelData) => {
      if (modelData !== null) {
        this.modelData = modelData.data;
        console.log('Modal Data : ' + modelData.data);
      }
    });
    return await modal.present();

  }


  async presentAlert(hdr: any,msg: any) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: hdr,
      subHeader: '',
      message: msg,
      buttons: ['OK']
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);
  }



  getFormCart(id,noso){

    let url = 'https://realbiz.id/api-rbz/api.php';

    let postData2 = new FormData();

    var co = sessionStorage.getItem('xco');
    var sc =  sessionStorage.getItem('xsc');
    var user_id =  sessionStorage.getItem('xuserid');
                 
    postData2.append('co',co);
    postData2.append('sc',sc);
    postData2.append('user_id',user_id);   

    postData2.append('noso',noso);
    postData2.append('id',id);

    let data:Observable<any> = this.http.post(url, postData2);
              
    data.subscribe((result: any)=>{

      this.id = result.id;
      this.noso = result.noso;
        
      this.router.navigate(['/cart-modal/' + '1' +'/'+ this.id + '/' + this.noso]);  

    });


  }


  order(){

      alert('Your order is processed now! just wait...');

  }


  proses(n){
    
    this.showConfirm(n);

  }



}