import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DatabaseService } from "../database.service";
import { AlertController } from '@ionic/angular';
import { Router, ActivatedRoute } from '@angular/router';
import { ThermalPrinterPlugin } from 'thermal-printer-cordova-plugin/src';
import { BluetoothSerial } from '@awesome-cordova-plugins/bluetooth-serial/ngx';
import { HttpClient, HttpHeaders } from '@angular/common/http';
//import { Observable } from 'rxjs/internal/Observable';
import {Observable} from 'rxjs/Observable';
// import { CartModalPage } from '../pages/cart-modal/cart-modal.page';

declare let ThermalPrinter: ThermalPrinterPlugin;

@Component({
  selector: 'app-addpospay',
  templateUrl: './addpospay.page.html',
  styleUrls: ['./addpospay.page.scss'],
})


export class AddpospayPage implements OnInit {

  

  @Input() sbtotal: number;
  @Input() sbppn: number;
  @Input() sbgrand: number;

  @Input() sbid: any;
  @Input() sbnoso: string;
  @Input() sbsodate: string;
  @Input() sbmejaid: any;
  @Input() sbtepe: any;

  sbcash: number;
  sbchange: number;

  msg: any;

  totalstring: string;
  grandstring: string;
  cashstring: string;
  kembalistring: string;

  newgrand: any;
  newtotal: any;
  newcash: any;
  newkembali: any;

  constructor(
    private modalController: ModalController,public http: HttpClient, public database: DatabaseService,public alertController: AlertController,private router: Router,
    private actRoute: ActivatedRoute,private bluetoothSerial: BluetoothSerial
  ) { }
  


  wrt(){

   
    // this.bluetoothSerial.write('hello world').then(success, failure);

   
    // this.bluetoothSerial.write([186, 220, 222]).then(success, failure);


    var data = new Uint8Array(4);
    data[0] = 0x41;
    data[1] = 0x42;
    data[2] = 0x43;
    data[3] = 0x44;

    // this.bluetoothSerial.write(data).then(success, failure);

   
    // this.bluetoothSerial.write(data.buffer).then(success, failure);

    this.bluetoothSerial.write("Hello world").then((success)=>{
         alert(+success+' Success for write')
      }, (failure)=>{
         alert(failure + 'failed data writing')
      }
    );

}

  
  async closeModel() {
    const close: string = "Modal Removed";
    await this.modalController.dismiss(close);
  }


  async presentAlert(hdr:any,msg: any) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: hdr,
      subHeader: '',
      message: msg,
      buttons: ['OK']
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);
  }



  thermal(){

    ThermalPrinter.printFormattedText({
        type: 'bluetooth',
        id: 'first', // You can also use the identifier directly i. e. 00:11:22:33:44:55 (address) or name
        text: "[C]<u><font size='big'>Hello World</font></u>"
    }, function() {
        console.log('Successfully printed!');
    }, function(error) {
        console.error('Printing error', error);
    });

}


tcp(){

    ThermalPrinter.printFormattedText({
        type: 'tcp',
        address: '192.168.1.123',
        port: 9100,
        id: 'tcp-printer-001', // Use an unique identifier for each printer i. e. address:port or name
        text: "[C]<u><font size='big'>Hello World</font></u>"
    }, function() {
        console.log('Successfully printed!');
    }, function(error) {
        console.error('Printing error', error);
    });

}




  kembalian(){


    var tgrand = this.sbgrand.toString();
    this.grandstring = tgrand.replace(/,/gi, "");
    this.newgrand = parseFloat(this.grandstring);

    var tcash = this.sbcash.toString();
    this.cashstring = tcash.replace(/,/gi, "");
    this.newcash = parseFloat(this.cashstring);
    

    var mchange = this.newcash-this.newgrand;
    var achange: any;

    if (mchange % 1 == 0){
      achange = mchange+ ".00";
    }
    else{
      achange = mchange;
    }

    this.sbchange=achange;

  }



  async approvePos(){

    var toti = this.sbtotal.toString();
    this.totalstring = toti.replace(/,/gi, "");
    this.newtotal = parseFloat(this.totalstring);

    var cash = this.sbcash.toString();
    this.cashstring = cash.replace(/,/gi, "");
    this.newcash = parseFloat(this.cashstring);

    var kembali = this.sbchange.toString();
    this.kembalistring = kembali.replace(/,/gi, "");
    this.newkembali = parseFloat(this.kembalistring);

    if (this.newcash<this.newgrand){
      this.presentAlert("Warning","Cash must be greater or equal to Grand Total!");
      return; 
    }


    let url = 'https://realbiz.id/api-rbz/api.php';


     var user_id = sessionStorage.getItem('xuserid');
     var co = sessionStorage.getItem('xco');
     var sc = sessionStorage.getItem('xsc');
     var acc = sessionStorage.getItem('xacc');

     let postData2 = new FormData();
           
      postData2.append('user_id',user_id);
      postData2.append('tp','approvePos');
     
      postData2.append('id',this.sbid);
      postData2.append('meja_id',this.sbmejaid);
      postData2.append('noso',this.sbnoso);
      postData2.append('sodate',this.sbsodate);
      postData2.append('total',this.newtotal);

      postData2.append('co',co);
      postData2.append('sc',sc);

      postData2.append('newcash',this.newcash);
      postData2.append('newkembali',this.newkembali);
      postData2.append('acc',acc);
          
      let data:Observable<any> = this.http.post(url, postData2);
  
      data.subscribe((result: any)=>{

        // alert(result.msg);

        // this.msg=result.msg;

        this.presentAlert("Success!","Transaction has been finished!");
                               
       });


        await this.modalController.dismiss(close);

        if (this.sbtepe=='PS'){
          this.router.navigate(['/pos']);
        }else{

          // this.closecart.close();
          this.router.navigate(['/cafe']);

        }
        

     
  }


  // async approvePos2(){

  //   var grand = this.sbgrand.toString();
  //   this.grandstring = grand.replace(/,/gi, "");
  //   this.newgrand = parseFloat(this.grandstring);

  //   var cash = this.sbcash.toString();
  //   this.cashstring = cash.replace(/,/gi, "");
  //   this.newcash = parseFloat(this.cashstring);

  //   var kembali = this.sbcash.toString();
  //   this.kembalistring = kembali.replace(/,/gi, "");
  //   this.newkembali = parseFloat(this.cashstring);

  //   if (this.newcash<this.newgrand){
  //     this.presentAlert("Warning","Cash must be greater or equal to Grand Total!");
  //     return; 
  //   }
    
  //   this.database
  //   .approvePos(this.sbid,this.newcash,this.newkembali)
  //   .then((data) => {

  //     this.database.getPosdets(this.sbid).then((data: any) => { 
   
  //       if (data.rows.length > 0) {
  //         for (var i = 0; i < data.rows.length; i++) {
           
  //           this.database.minusStock(data.rows.item(i).product_id,data.rows.item(i).qty).then((data) => {
             


  //           });
    
            
  //         }
  
  //       }
  //     });
      
      
  //   });



  //   //Write to BK in


  //   this.database
  //   .addBkinCashPos(this.sbid)
  //   .then((data) => {

  //      this.database
  //     .getBkindetPos(data)
  //     .then((res) => {


  //       var bkinid = res['id'];

  //         this.database
  //         .addBkindetCashPos(bkinid,this.sbnoso,'Pembayaran Cash POS',this.newgrand)
  //         .then((data) => {
            
  //         });

        
  //     });

      
  //   });
     
  //   await this.modalController.dismiss(close);

  //   this.presentAlert("Success!","Transaction has been finished!");

  //   this.router.navigate(['/pos']);
 

  // }



  ngOnInit() { }


}