import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";

import { CartModalPageModule } from './pages/cart-modal/cart-modal.module';




//  ------- New --------

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { SQLite } from "@ionic-native/sqlite/ngx";
import { IonicSelectableModule } from 'ionic-selectable';

import { File } from '@ionic-native/file/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';

import { PDFGenerator } from '@ionic-native/pdf-generator/ngx';
import { InvoiceComponent } from './components/invoice/invoice.component';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { Printer, PrintOptions } from '@ionic-native/printer/ngx';
// import { Printer, PrintOptions } from '@awesome-cordova-plugins/printer/ngx';
import { BluetoothSerial } from '@awesome-cordova-plugins/bluetooth-serial/ngx';
import { Camera } from '@ionic-native/camera/ngx';
import { HttpClientModule } from '@angular/common/http';

import { Geolocation } from '@ionic-native/geolocation/ngx';

@NgModule({
  declarations: [AppComponent,InvoiceComponent],
  entryComponents: [],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule, IonicSelectableModule,HttpClientModule, CartModalPageModule],
  providers: [
    StatusBar,
    SplashScreen,
    BarcodeScanner,
    Printer,
    BluetoothSerial,
    Camera,
    Geolocation,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    SQLite,
    File,
    FileOpener,
    PDFGenerator,
    { 
      provide: RouteReuseStrategy, 
      useClass: IonicRouteStrategy 
    }
  ],
  bootstrap: [AppComponent],
})

export class AppModule {

 
}
