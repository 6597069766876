import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';



// export interface Product {
//   id: number;
//   name: string;
//   price: number;
//   amount: number;
  
// }

export interface Product {
  id: any;
  nmproduct: string;
  spek: string;
  hargajual: any;
  qty: any;
  img_file: string;
  
}

@Injectable({
  providedIn: 'root'
})

export class CartService {
  // data: Product[] = [
  //   { id: 0, name: 'Pizza Salami', price: 8.99, amount: 0 },
  //   { id: 1, name: 'Pizza Classic', price: 5.49, amount: 0 },
  //   { id: 2, name: 'Sliced Bread', price: 4.99, amount: 0 },
  //   { id: 3, name: 'Salad', price: 6.99, amount: 0 }
  // ];

  products: any=[];

  private cart = [];
  private cartItemCount = new BehaviorSubject(0);

  constructor(private http: HttpClient) {
        
        
  }

  getProducts() {

    

  //  return this.data;
  }

  getCart() {
    return this.cart;
  }

  getCartItemCount() {

    return this.cartItemCount;
  }

  getCartReset() {
    this.cartItemCount.next(0);
  }


  addProduct(product) {
    let added = false;
    for (let p of this.cart) {
      if (p.id === product.id) {
        p.qty += 1;
        added = true;
        break;
      }
    }
    if (!added) {
      product.qty = 1;
      this.cart.push(product);
    }
    this.cartItemCount.next(this.cartItemCount.value + 1);
  }

  decreaseProduct(product) {
    for (let [index, p] of this.cart.entries()) {
      if (p.id === product.id) {
        p.qty -= 1;
        if (p.qty == 0) {
          this.cart.splice(index, 1);
        }
      }
    }
    this.cartItemCount.next(this.cartItemCount.value - 1);
  }


  removeProduct(product) {
    for (let [index, p] of this.cart.entries()) {
      if (p.id === product.id) {
        this.cartItemCount.next(this.cartItemCount.value - p.qty);
        this.cart.splice(index, 1);
      }
    }
  }

  removeAll(product) {
    for (let [index, p] of this.cart.entries()) {
        this.cartItemCount.next(this.cartItemCount.value - p.qty);
        this.cart.splice(index, 1);
      
    }
  }

  
  removeProductAll() {
        
      this.cart.splice(0);
      
  }

  storeZero(){

    this.cartItemCount.next(0);

  }

  // addProduct(product) {
  //   let added = false;
  //   for (let p of this.cart) {
  //     if (p.id === product.id) {
  //       p.amount += 1;
  //       added = true;
  //       break;
  //     }
  //   }
  //   if (!added) {
  //     product.amount = 1;
  //     this.cart.push(product);
  //   }
  //   this.cartItemCount.next(this.cartItemCount.value + 1);
  // }

  // decreaseProduct(product) {
  //   for (let [index, p] of this.cart.entries()) {
  //     if (p.id === product.id) {
  //       p.amount -= 1;
  //       if (p.amount == 0) {
  //         this.cart.splice(index, 1);
  //       }
  //     }
  //   }
  //   this.cartItemCount.next(this.cartItemCount.value - 1);
  // }

  // removeProduct(product) {
  //   for (let [index, p] of this.cart.entries()) {
  //     if (p.id === product.id) {
  //       this.cartItemCount.next(this.cartItemCount.value - p.amount);
  //       this.cart.splice(index, 1);
  //     }
  //   }
  // }


}