import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";

const routes: Routes = [
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full",
  },
  {
    path: "folder/:id",
    loadChildren: () =>
      import("./folder/folder.module").then((m) => m.FolderPageModule),
  },
  {
    path: 'company',
    loadChildren: () => import('./company/company.module').then( m => m.CompanyPageModule)
  },
  {
    path: 'company/:editMode',
    loadChildren: () =>
      import('./company/company.module').then(
        (m) => m.CompanyPageModule
      ),
  },
  {
    path: 'company/:editMode/:editId/:name/:address/:city/:province/:zipcode/:phone/:dir/:email/:website/:npwp/:img_file',
    loadChildren: () =>
      import('./company/company.module').then(
        (m) => m.CompanyPageModule
      ),
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'home/:chr',
    loadChildren: () =>
      import('./home/home.module').then(
        (m) => m.HomePageModule
      ),
  },
  {
    path: 'beli',
    loadChildren: () => import('./beli/beli.module').then( m => m.BeliPageModule)
  },
  {
    path: 'beli/',
    loadChildren: () =>
      import('./beli/beli.module').then(
        (m) => m.BeliPageModule
      ),
  },
  {
    path: 'beli/:tr',
    loadChildren: () =>
      import('./beli/beli.module').then(
        (m) => m.BeliPageModule
      ),
  },
  {
    path: 'addbeli',
    loadChildren: () => import('./addbeli/addbeli.module').then( m => m.AddbeliPageModule)
  },
  {
    path: 'addbeli/:tr/:editMode/:id/:nopo/:podate/:vendor_id/:stat/:tp/:forex_id/:kurs/:kdpartner/:nmpartner/:address/:city/:zipcode/:branch_id/:cost_id/:total/:ppn/:grandtotal',
    loadChildren: () =>
      import('./addbeli/addbeli.module').then(
        (m) => m.AddbeliPageModule
      ),
  },
  {
    path: 'addbeli/:tr/:editMode',
    loadChildren: () =>
      import('./addbeli/addbeli.module').then(
        (m) => m.AddbeliPageModule
      ),
  },
  {
    path: 'vendor',
    loadChildren: () => import('./vendor/vendor.module').then( m => m.VendorPageModule)
  },
  {
    path: 'addvendor',
    loadChildren: () => import('./addvendor/addvendor.module').then( m => m.AddvendorPageModule)
  },
  {
    path: 'addvendor/:editMode',
    loadChildren: () =>
      import('./addvendor/addvendor.module').then(
        (m) => m.AddvendorPageModule
      ),
  },
  {
    path: 'addvendor/:editMode/:editId/:kdpartner/:nmpartner/:address/:city/:province/:zipcode/:phone/:pic/:tp/:term/:ppn/:bank_id/:norek/:atasnama/:gr_cash/:gr_credit/:invoice_ap_credit/:sr_cash/:sr_credit/:retur_cash/:do_cash/:do_credit/:invoice_ar_cash/:invoice_ar_credit',
    loadChildren: () =>
      import('./addvendor/addvendor.module').then(
        (m) => m.AddvendorPageModule
      ),
  },
  {
    path: 'cust',
    loadChildren: () => import('./cust/cust.module').then( m => m.CustPageModule)
  },
  {
    path: 'addcust',
    loadChildren: () => import('./addcust/addcust.module').then( m => m.AddcustPageModule)
  },
  {
    path: 'addcust/:editMode',
    loadChildren: () =>
      import('./addcust/addcust.module').then(
        (m) => m.AddcustPageModule
      ),
  },
  {
    path: 'addcust/:editMode/:editId/:kdpartner/:nmpartner/:address/:city/:province/:zipcode/:phone/:pic/:tp/:term/:ppn/:bank_id/:norek/:atasnama',
    loadChildren: () =>
      import('./addcust/addcust.module').then(
        (m) => m.AddcustPageModule
      ),
  },
  {
    path: 'addcust/:editMode/:editId/:kdpartner/:nmpartner/:address/:city/:province/:zipcode/:phone/:pic/:tp/:term/:ppn/:gr_cash/:gr_credit/:invoice_ap_credit/:sr_cash/:sr_credit/:retur_cash/:do_cash/:do_credit/:invoice_ar_cash/:invoice_ar_credit/:bank_id/:norek/:atasnama',
    loadChildren: () =>
      import('./addcust/addcust.module').then(
        (m) => m.AddcustPageModule
      ),
  },
  {
    path: 'product',
    loadChildren: () => import('./product/product.module').then( m => m.ProductPageModule)
  },
  {
    path: 'addproduct',
    loadChildren: () => import('./addproduct/addproduct.module').then( m => m.AddproductPageModule)
  },
  {
    path: 'addproduct/:editMode',
    loadChildren: () =>
      import('./addproduct/addproduct.module').then(
        (m) => m.AddproductPageModule
      ),
  },
  {
    path: 'addproduct/:editMode/:editId/:kdproduct/:nmproduct/:spek/:qty/:min/:hargabeli/:hargajual/:diskon/:diskon2/:unit_id/:grup_id/:categ_id/:wh_id/:img_file/:stokabel',
    loadChildren: () =>
      import('./addproduct/addproduct.module').then(
        (m) => m.AddproductPageModule
      ),
  },
  {
    path: 'grproduct',
    loadChildren: () => import('./grproduct/grproduct.module').then( m => m.GrproductPageModule)
  },
  {
    path: 'addproduct',
    loadChildren: () => import('./addproduct/addproduct.module').then( m => m.AddproductPageModule)
  },
  {
    path: 'unit',
    loadChildren: () => import('./unit/unit.module').then( m => m.UnitPageModule)
  },
  {
    path: 'add-beli-det',
    loadChildren: () => import('./add-beli-det/add-beli-det.module').then( m => m.AddBeliDetPageModule)
  },
  {
    path: 'add-beli-det/:tr/:editMode/:id/:beli_id/:product_id/:qty/:kdproduct/:nmproduct/:spek/:nmunit/:nopo/:branch_id',
    loadChildren: () =>
      import('./add-beli-det/add-beli-det.module').then(
        (m) => m.AddBeliDetPageModule
      ),
  },
  {
    path: 'forex',
    loadChildren: () => import('./forex/forex.module').then( m => m.ForexPageModule)
  },
  {
    path: 'terima',
    loadChildren: () => import('./terima/terima.module').then( m => m.TerimaPageModule)
  },
  {
    path: 'terima/',
    loadChildren: () =>
      import('./terima/terima.module').then(
        (m) => m.TerimaPageModule
      ),
  },
  {
    path: 'listbeli',
    loadChildren: () => import('./listbeli/listbeli.module').then( m => m.ListbeliPageModule)
  },
  {
    path: 'addterima',
    loadChildren: () => import('./addterima/addterima.module').then( m => m.AddterimaPageModule)
  },
  {
    path: 'addterima/:editMode/:id/:nogr/:grdate/:vendor_id/:stat/:tp/:forex_id/:kurs/:simbol/:kdpartner/:nmpartner/:address/:city/:zipcode/:total/:nmforex/:noht/:htdate/:grandht/:wh_id/:nopo/:refno/:penerima',
    loadChildren: () =>
      import('./addterima/addterima.module').then(
        (m) => m.AddterimaPageModule
      ),
  },
  {
    path: 'addterima/:editMode/:id',
    loadChildren: () =>
      import('./addterima/addterima.module').then(
        (m) => m.AddterimaPageModule
      ),
  },
  {
    path: 'jual',
    loadChildren: () => import('./jual/jual.module').then( m => m.JualPageModule)
  },
  {
    path: 'jual/',
    loadChildren: () =>
      import('./jual/jual.module').then(
        (m) => m.JualPageModule
      ),
  },
  {
    path: 'jual/:tr',
    loadChildren: () =>
      import('./jual/jual.module').then(
        (m) => m.JualPageModule
      ),
  },
  {
    path: 'addjual',
    loadChildren: () => import('./addjual/addjual.module').then( m => m.AddjualPageModule)
  },
  {
    path: 'addjual/:tr/:editMode/:id/:noso/:sodate/:cust_id/:stat/:tp/:forex_id/:kurs/:kdpartner/:nmpartner/:address/:city/:zipcode/:branch_id/:jp/:sales_id/:kduser/:nmuser/:total/:ppn/:grandtotal',
    loadChildren: () =>
      import('./addjual/addjual.module').then(
        (m) => m.AddjualPageModule
      ),
  },
  {
    path: 'addjual/:tr/:editMode',
    loadChildren: () =>
      import('./addjual/addjual.module').then(
        (m) => m.AddjualPageModule
      ),
  },
  {
    path: 'add-jual-det',
    loadChildren: () => import('./add-jual-det/add-jual-det.module').then( m => m.AddJualDetPageModule)
  },
  {
    path: 'add-jual-det/:tr/:editMode/:id/:jual_id/:product_id/:qty/:kdproduct/:nmproduct/:spek/:nmunit/:noso/:branch_id/:jp',
    loadChildren: () =>
      import('./add-jual-det/add-jual-det.module').then(
        (m) => m.AddJualDetPageModule
      ),
  },
  {
    path: 'setting',
    loadChildren: () => import('./setting/setting.module').then( m => m.SettingPageModule)
  },
  {
    path: 'printbeli',
    loadChildren: () => import('./printbeli/printbeli.module').then( m => m.PrintbeliPageModule)
  },
  {
    path: 'printbeli/:editMode/:id/:nopo/:podate/:vendor_id/:stat/:tp/:forex_id/:kurs/:tr',
    loadChildren: () =>
      import('./printbeli/printbeli.module').then(
        (m) => m.PrintbeliPageModule
      ),
  },
  {
    path: 'add-terima-det',
    loadChildren: () => import('./add-terima-det/add-terima-det.module').then( m => m.AddTerimaDetPageModule)
  },
  {
    path: 'add-terima-det/:editMode/:id/:terima_id/:product_id/:qty/:kdproduct/:nmproduct/:spek/:nmunit/:nogr/:wh_id/:harga',
    loadChildren: () =>
      import('./add-terima-det/add-terima-det.module').then(
        (m) => m.AddTerimaDetPageModule
      ),
  },
  {
    path: 'addhutang',
    loadChildren: () => import('./addhutang/addhutang.module').then( m => m.AddhutangPageModule)
  },
  {
    path: 'addhutang/:editMode/:id/:noht/:htdate/:vendor_id/:stat/:tp/:forex_id/:kurs/:simbol/:kdpartner/:nmpartner/:address/:city/:zipcode/:total/:nmforex/:paidht/:stinv',
    loadChildren: () =>
      import('./addhutang/addhutang.module').then(
        (m) => m.AddhutangPageModule
      ),
  },
  {
    path: 'addhutang/:editMode/:id',
    loadChildren: () =>
      import('./addhutang/addhutang.module').then(
        (m) => m.AddhutangPageModule
      ),
  },
  {
    path: 'add-hutang-det',
    loadChildren: () => import('./add-hutang-det/add-hutang-det.module').then( m => m.AddHutangDetPageModule)
  },
  {
    path: 'add-hutang-det/:editMode/:id/:terima_id/:product_id/:qty/:kdproduct/:nmproduct/:spek/:nmunit/:noht/:harga/:diskon',
    loadChildren: () =>
      import('./add-hutang-det/add-hutang-det.module').then(
        (m) => m.AddHutangDetPageModule
      ),
  },
  {
    path: 'listterima',
    loadChildren: () => import('./listterima/listterima.module').then( m => m.ListterimaPageModule)
  },
  {
    path: 'hutang',
    loadChildren: () => import('./hutang/hutang.module').then( m => m.HutangPageModule)
  },
  {
    path: 'kirim',
    loadChildren: () => import('./kirim/kirim.module').then( m => m.KirimPageModule)
  },
  {
    path: 'addkirim',
    loadChildren: () => import('./addkirim/addkirim.module').then( m => m.AddkirimPageModule)
  },
  {
    path: 'addkirim/:editMode/:id/:nodo/:dodate/:cust_id/:stat/:tp/:forex_id/:kurs/:simbol/:kdpartner/:nmpartner/:address/:city/:zipcode/:total/:nmforex/:nopt/:ptdate/:grandpt/:wh_id/:noso/:refno/:pengirim',
    loadChildren: () =>
      import('./addkirim/addkirim.module').then(
        (m) => m.AddkirimPageModule
      ),
  },
  {
    path: 'addkirim/:editMode/:id',
    loadChildren: () =>
      import('./addkirim/addkirim.module').then(
        (m) => m.AddkirimPageModule
      ),
  },
  {
    path: 'add-kirim-det',
    loadChildren: () => import('./add-kirim-det/add-kirim-det.module').then( m => m.AddKirimDetPageModule)
  },
  {
    path: 'add-kirim-det/:editMode/:id/:kirim_id/:product_id/:qty/:kdproduct/:nmproduct/:spek/:nmunit/:nodo/:harga',
    loadChildren: () =>
      import('./add-kirim-det/add-kirim-det.module').then(
        (m) => m.AddKirimDetPageModule
      ),
  },
  {
    path: 'listjual',
    loadChildren: () => import('./listjual/listjual.module').then( m => m.ListjualPageModule)
  },
  {
    path: 'listkirim',
    loadChildren: () => import('./listkirim/listkirim.module').then( m => m.ListkirimPageModule)
  },
  {
    path: 'piutang',
    loadChildren: () => import('./piutang/piutang.module').then( m => m.PiutangPageModule)
  },
  {
    path: 'addpiutang',
    loadChildren: () => import('./addpiutang/addpiutang.module').then( m => m.AddpiutangPageModule)
  },
  {
    path: 'addpiutang/:editMode/:id/:nopt/:ptdate/:cust_id/:stat/:tp/:forex_id/:kurs/:simbol/:kdpartner/:nmpartner/:address/:city/:zipcode/:total/:nmforex/:paidpt/:stinv',
    loadChildren: () =>
      import('./addpiutang/addpiutang.module').then(
        (m) => m.AddpiutangPageModule
      ),
  },
  {
    path: 'addpiutang/:editMode/:id',
    loadChildren: () =>
      import('./addpiutang/addpiutang.module').then(
        (m) => m.AddpiutangPageModule
      ),
  },
  {
    path: 'add-piutang-det',
    loadChildren: () => import('./add-piutang-det/add-piutang-det.module').then( m => m.AddPiutangDetPageModule)
  },
  {
    path: 'add-piutang-det/:editMode/:id/:kirim_id/:product_id/:qty/:kdproduct/:nmproduct/:spek/:nmunit/:nopt/:harga/:diskon',
    loadChildren: () =>
      import('./add-piutang-det/add-piutang-det.module').then(
        (m) => m.AddPiutangDetPageModule
      ),
  },
  {
    path: 'invoice',
    loadChildren: () => import('./invoice/invoice.module').then( m => m.InvoicePageModule)
  },
  {
    path: 'bank',
    loadChildren: () => import('./bank/bank.module').then( m => m.BankPageModule)
  },
  {
    path: 'rek',
    loadChildren: () => import('./rek/rek.module').then( m => m.RekPageModule)
  },
  {
    path: 'addrek',
    loadChildren: () => import('./addrek/addrek.module').then( m => m.AddrekPageModule)
  },
  {
    path: 'addrek/:editMode',
    loadChildren: () =>
      import('./addrek/addrek.module').then(
        (m) => m.AddrekPageModule
      ),
  },
  {
    path: 'addrek/:editMode/:editId/:norek/:branch/:atasnama/:bank_id/:account_id/:noacc/:nmacc',
    loadChildren: () =>
      import('./addrek/addrek.module').then(
        (m) => m.AddrekPageModule
      ),
  },
  {
    path: 'bkout',
    loadChildren: () => import('./bkout/bkout.module').then( m => m.BkoutPageModule)
  },
  {
    path: 'addbkout',
    loadChildren: () => import('./addbkout/addbkout.module').then( m => m.AddbkoutPageModule)
  },
  {
    path: 'addbkout/:editMode/:id/:nobkout/:bkoutdate/:vendor_id/:stat/:tp/:pf/:forex_id/:kurs/:kdpartner/:nmpartner/:address/:city/:zipcode/:rek_id/:ket',
    loadChildren: () =>
      import('./addbkout/addbkout.module').then(
        (m) => m.AddbkoutPageModule
      ),
  },
  {
    path: 'addbkout/:editMode',
    loadChildren: () =>
      import('./addbkout/addbkout.module').then(
        (m) => m.AddbkoutPageModule
      ),
  },
  {
    path: 'add-bkout-det',
    loadChildren: () => import('./add-bkout-det/add-bkout-det.module').then( m => m.AddBkoutDetPageModule)
  },
  {
    path: 'add-bkout-det/:editMode/:id/:bkout_id/:terima_id/:detdesc/:total/:nobkout/:noht/:htdate/:grandht/:paidht/:nostruk/:pf/:account_id/:noacc/:nmacc',
    loadChildren: () =>
      import('./add-bkout-det/add-bkout-det.module').then(
        (m) => m.AddBkoutDetPageModule
      ),
  },
  {
    path: 'addbkin',
    loadChildren: () => import('./addbkin/addbkin.module').then( m => m.AddbkinPageModule)
  },
  {
    path: 'addbkin/:editMode/:id/:nobkin/:bkindate/:cust_id/:stat/:tp/:pf/:forex_id/:kurs/:kdpartner/:nmpartner/:address/:city/:zipcode/:rek_id/:ket',
    loadChildren: () =>
      import('./addbkin/addbkin.module').then(
        (m) => m.AddbkinPageModule
      ),
  },
  {
    path: 'addbkin/:editMode',
    loadChildren: () =>
      import('./addbkin/addbkin.module').then(
        (m) => m.AddbkinPageModule
      ),
  },
  {
    path: 'bkin',
    loadChildren: () => import('./bkin/bkin.module').then( m => m.BkinPageModule)
  },
  {
    path: 'add-bkin-det',
    loadChildren: () => import('./add-bkin-det/add-bkin-det.module').then( m => m.AddBkinDetPageModule)
  },
  {
    path: 'add-bkin-det/:editMode/:id/:bkin_id/:kirim_id/:detdesc/:total/:nobkin/:nopt/:ptdate/:grandpt/:paidpt/:nostruk/:pf/:account_id/:noacc/:nmacc',
    loadChildren: () =>
      import('./add-bkin-det/add-bkin-det.module').then(
        (m) => m.AddBkinDetPageModule
      ),
  },
  {
    path: 'barcode',
    loadChildren: () => import('./barcode/barcode.module').then( m => m.BarcodePageModule)
  },
  {
    path: 'pdf',
    loadChildren: () => import('./pdf/pdf.module').then( m => m.PdfPageModule)
  },
  {
    path: 'pos',
    loadChildren: () => import('./pos/pos.module').then( m => m.PosPageModule)
  },
  {
    path: 'addpos',
    loadChildren: () => import('./addpos/addpos.module').then( m => m.AddposPageModule)
  },
  {
    path: 'addpos/:editMode',
    loadChildren: () =>
      import('./addpos/addpos.module').then(
        (m) => m.AddposPageModule
      ),
  },
  {
    path: 'addpos/:editMode/:id/:noso/:sodate/:cust_id/:stat/:tp/:forex_id/:kurs/:kdpartner/:nmpartner/:address/:city/:zipcode',
    loadChildren: () =>
      import('./addpos/addpos.module').then(
        (m) => m.AddposPageModule
      ),
  },
  {
    path: 'addpos/:editMode',
    loadChildren: () =>
      import('./addpos/addpos.module').then(
        (m) => m.AddposPageModule
      ),
  },
  {
    path: 'addpospay',
    loadChildren: () => import('./addpospay/addpospay.module').then( m => m.AddpospayPageModule)
  },
  {
    path: 'modal-pospay',
    loadChildren: () => import('./modal-pospay/modal-pospay.module').then( m => m.ModalPospayPageModule)
  },
  {
    path: 'branch',
    loadChildren: () => import('./branch/branch.module').then( m => m.BranchPageModule)
  },
  {
    path: 'wh',
    loadChildren: () => import('./wh/wh.module').then( m => m.WhPageModule)
  },
  {
    path: 'category',
    loadChildren: () => import('./category/category.module').then( m => m.CategoryPageModule)
  },
  {
    path: 'addbranch',
    loadChildren: () => import('./addbranch/addbranch.module').then( m => m.AddbranchPageModule)
  },
  {
    path: 'addbranch/:editMode',
    loadChildren: () =>
      import('./addbranch/addbranch.module').then(
        (m) => m.AddbranchPageModule
      ),
  },
  {
    path: 'addbranch/:editMode/:editId/:kdbranch/:nmbranch/:address/:city/:province/:zipcode/:phone/:pic',
    loadChildren: () =>
      import('./addbranch/addbranch.module').then(
        (m) => m.AddbranchPageModule
      ),
  },
  {
    path: 'addwh',
    loadChildren: () => import('./addwh/addwh.module').then( m => m.AddwhPageModule)
  },
  {
    path: 'addwh/:editMode',
    loadChildren: () =>
      import('./addwh/addwh.module').then(
        (m) => m.AddwhPageModule
      ),
  },
  {
    path: 'addwh/:editMode/:editId/:kdwh/:nmwh/:branch_id',
    loadChildren: () =>
      import('./addwh/addwh.module').then(
        (m) => m.AddwhPageModule
      ),
  },
  {
    path: 'report',
    loadChildren: () => import('./report/report.module').then( m => m.ReportPageModule)
  },
  {
    path: 'joborder',
    loadChildren: () => import('./joborder/joborder.module').then( m => m.JoborderPageModule)
  },
  {
    path: 'addjoborder',
    loadChildren: () => import('./addjoborder/addjoborder.module').then( m => m.AddjoborderPageModule)
  },
  {
   
    path: 'addjoborder/:editMode/:id/:nojob/:jobdate/:finishdate/:target/:final/:reject/:status/:wh_id/:product_fg_id/:hpp/:hargajual/:kdproduct/:nmproduct/:spek/:nmunit/:cs',
    loadChildren: () =>
      import('./addjoborder/addjoborder.module').then(
        (m) => m.AddjoborderPageModule
      ),
  },
  {
   
    path: 'addjoborder/:editMode/:id/:nojob/:jobdate/:finishdate/:target/:final/:reject/:status/:wh_id/:product_fg_id/:hpp/:hargajual/:kdproduct/:nmproduct/:spek/:nmunit/:cs/:mix_start/:mix_end/:mix_stat/:fermen_start/:fermen_end/:fermen_stat/:logg_start/:logg_end/:logg_stat/:steril_start/:steril_end/:steril_stat/:bibit_start/:bibit_end/:bibit_stat/:inkubasi_start/:inkubasi_end/:inkubasi_stat/:gagal/:rada',
    loadChildren: () =>
      import('./addjoborder/addjoborder.module').then(
        (m) => m.AddjoborderPageModule
      ),
  },
  {
    path: 'addjoborder/:editMode',
    loadChildren: () =>
      import('./addjoborder/addjoborder.module').then(
        (m) => m.AddjoborderPageModule
      ),
  },
  {
    path: 'add-joborder-det',
    loadChildren: () => import('./add-joborder-det/add-joborder-det.module').then( m => m.AddJoborderDetPageModule)
  },
  {
    path: 'add-joborder-det/:editMode/:id/:job_id/:product_raw_id/:qty/:harga/:nojob/:wh_id/:kdproduct/:nmproduct/:spek/:nmunit',
    loadChildren: () =>
      import('./add-joborder-det/add-joborder-det.module').then(
        (m) => m.AddJoborderDetPageModule
      ),
  },
  {
    path: 'mutasi',
    loadChildren: () => import('./mutasi/mutasi.module').then( m => m.MutasiPageModule)
  },
  {
    path: 'mutasi/:chr',
    loadChildren: () =>
      import('./mutasi/mutasi.module').then(
        (m) => m.MutasiPageModule
      ),
  },
  {
    path: 'addmutasi',
    loadChildren: () => import('./addmutasi/addmutasi.module').then( m => m.AddmutasiPageModule)
  },
  {
    path: 'addmutasi/:editMode',
    loadChildren: () =>
      import('./addmutasi/addmutasi.module').then(
        (m) => m.AddmutasiPageModule
      ),
  },
  {
    path: 'addmutasi/:editMode/:id/:nomutasi/:mutasidate/:stat/:wh_id/:wh_id2/:nmwh/:nmwh2',
    loadChildren: () =>
      import('./addmutasi/addmutasi.module').then(
        (m) => m.AddmutasiPageModule
      ),
  },
  {
    path: 'add-mutasi-det',
    loadChildren: () => import('./add-mutasi-det/add-mutasi-det.module').then( m => m.AddMutasiDetPageModule)
  },
  {
    path: 'add-mutasi-det/:editMode/:id/:mutasi_id/:nomutasi/:product_id/:qty/:wh_id/:kdproduct/:nmproduct/:spek/:nmunit',
    loadChildren: () =>
      import('./add-mutasi-det/add-mutasi-det.module').then(
        (m) => m.AddMutasiDetPageModule
      ),
  },
  {
    path: 'sto',
    loadChildren: () => import('./sto/sto.module').then( m => m.StoPageModule)
  },
  {
    path: 'sto/:chr',
    loadChildren: () =>
      import('./sto/sto.module').then(
        (m) => m.StoPageModule
      ),
  },
  {
    path: 'addsto',
    loadChildren: () => import('./addsto/addsto.module').then( m => m.AddstoPageModule)
  },
  {
    path: 'addsto/:editMode',
    loadChildren: () =>
      import('./addsto/addsto.module').then(
        (m) => m.AddstoPageModule
      ),
  },
  {
    path: 'addsto/:editMode/:id/:nosto/:stodate/:status/:wh_id/:nmwh',
    loadChildren: () =>
      import('./addsto/addsto.module').then(
        (m) => m.AddstoPageModule
      ),
  },
  {
    path: 'add-sto-det',
    loadChildren: () => import('./add-sto-det/add-sto-det.module').then( m => m.AddStoDetPageModule)
  },
  {
    path: 'add-sto-det/:editMode/:id/:sto_id/:nosto/:product_id/:qty/:wh_id/:kdproduct/:nmproduct/:spek/:nmunit',
    loadChildren: () =>
      import('./add-sto-det/add-sto-det.module').then(
        (m) => m.AddStoDetPageModule
      ),
  },
  {
    path: 'excel',
    loadChildren: () => import('./excel/excel.module').then( m => m.ExcelPageModule)
  },
  {
    path: 'about',
    loadChildren: () => import('./about/about.module').then( m => m.AboutPageModule)
  },
  {
    path: 'cetak',
    loadChildren: () => import('./cetak/cetak.module').then( m => m.CetakPageModule)
  },
  {
    path: 'printterima',
    loadChildren: () => import('./printterima/printterima.module').then( m => m.PrintterimaPageModule)
  },
  {
    path: 'printterima/:editMode/:id/:nogr/:grdate/:stat/:forex_id/:kurs/:vendor_id/:nopo/:refno/:penerima',
    loadChildren: () =>
      import('./printterima/printterima.module').then(
        (m) => m.PrintterimaPageModule
      ),
  },
  {
    path: 'printhutang',
    loadChildren: () => import('./printhutang/printhutang.module').then( m => m.PrinthutangPageModule)
  },
  {
    path: 'printhutang/:editMode/:id/:noht/:htdate/:stat/:forex_id/:kurs/:vendor_id',
    loadChildren: () =>
      import('./printhutang/printhutang.module').then(
        (m) => m.PrinthutangPageModule
      ),
  },
  {
    path: 'printjual',
    loadChildren: () => import('./printjual/printjual.module').then( m => m.PrintjualPageModule)
  },
  {
    path: 'printjual/:editMode/:id/:noso/:sodate/:cust_id/:stat/:tp/:forex_id/:kurs/:tr',
    loadChildren: () =>
      import('./printjual/printjual.module').then(
        (m) => m.PrintjualPageModule
      ),
  },
  {
    path: 'printkirim',
    loadChildren: () => import('./printkirim/printkirim.module').then( m => m.PrintkirimPageModule)
  },
  {
    path: 'printkirim/:editMode/:id/:nodo/:dodate/:stat/:forex_id/:kurs/:cust_id/:noso/:refno/:pengirim',
    loadChildren: () =>
      import('./printkirim/printkirim.module').then(
        (m) => m.PrintkirimPageModule
      ),
  },
  {
    path: 'printpiutang',
    loadChildren: () => import('./printpiutang/printpiutang.module').then( m => m.PrintpiutangPageModule)
  },
  {
    path: 'printpiutang/:editMode/:id/:nopt/:ptdate/:stat/:forex_id/:kurs/:cust_id',
    loadChildren: () =>
      import('./printpiutang/printpiutang.module').then(
        (m) => m.PrintpiutangPageModule
      ),
  },
  {
    path: 'printbkin',
    loadChildren: () => import('./printbkin/printbkin.module').then( m => m.PrintbkinPageModule)
  },
  {
    path: 'printbkin/:editMode/:id/:nobkin/:bkindate/:cust_id/:stat/:tp/:pf/:forex_id/:kurs/:kdpartner/:nmpartner/:address/:city/:zipcode/:rek_id/:ket',
    loadChildren: () =>
      import('./printbkin/printbkin.module').then(
        (m) => m.PrintbkinPageModule
      ),
  },
  {
    path: 'printbkout',
    loadChildren: () => import('./printbkout/printbkout.module').then( m => m.PrintbkoutPageModule)
  },
  {
    path: 'printbkout/:editMode/:id/:nobkout/:bkoutdate/:vendor_id/:stat/:tp/:pf/:forex_id/:kurs/:kdpartner/:nmpartner/:address/:city/:zipcode/:rek_id/:ket',
    loadChildren: () =>
      import('./printbkout/printbkout.module').then(
        (m) => m.PrintbkoutPageModule
      ),
  },
  {
    path: 'member',
    loadChildren: () => import('./member/member.module').then( m => m.MemberPageModule)
  },
  {
    path: 'addmember',
    loadChildren: () => import('./addmember/addmember.module').then( m => m.AddmemberPageModule)
  },
  {
    path: 'addmember/:editMode/:editId/:kduser/:nmuser/:address/:city/:province/:zipcode/:phone/:email/:ktp/:npwp',
    loadChildren: () =>
      import('./addmember/addmember.module').then(
        (m) => m.AddmemberPageModule
      ),
  },
  {
    path: 'koperasi',
    loadChildren: () => import('./koperasi/koperasi.module').then( m => m.KoperasiPageModule)
  },
  {
    path: 'simpan',
    loadChildren: () => import('./simpan/simpan.module').then( m => m.SimpanPageModule)
  },
  {
    path: 'simpan/:tr',
    loadChildren: () =>
      import('./simpan/simpan.module').then(
        (m) => m.SimpanPageModule
      ),
  },
  {
    path: 'addsimpan',
    loadChildren: () => import('./addsimpan/addsimpan.module').then( m => m.AddsimpanPageModule)
  },
  {
    path: 'addsimpan/:tr/:editMode/:id/:nosimpan/:simpandate/:user_id/:stat/:amount/:kduser/:nmuser/:address',
    loadChildren: () =>
      import('./addsimpan/addsimpan.module').then(
        (m) => m.AddsimpanPageModule
      ),
  },
  {
    path: 'addsimpan/:tr/:editMode',
    loadChildren: () =>
      import('./addsimpan/addsimpan.module').then(
        (m) => m.AddsimpanPageModule
      ),
  },
  {
    path: 'accounting',
    loadChildren: () => import('./accounting/accounting.module').then( m => m.AccountingPageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then( m => m.DashboardPageModule)
  },
  {
    path: 'whout',
    loadChildren: () => import('./whout/whout.module').then( m => m.WhoutPageModule)
  },
  {
    path: 'addwhout',
    loadChildren: () => import('./addwhout/addwhout.module').then( m => m.AddwhoutPageModule)
  },
  {
    path: 'addwhout/:editMode/:editId/:nowhout/:whoutdate/:wh_id/:nmwh/:refno/:pic/:dodate/:stat',
    loadChildren: () =>
      import('./addwhout/addwhout.module').then(
        (m) => m.AddwhoutPageModule
      ),
  },
  {
    path: 'listdo',
    loadChildren: () => import('./listdo/listdo.module').then( m => m.ListdoPageModule)
  },
  {
    path: 'printwhout',
    loadChildren: () => import('./printwhout/printwhout.module').then( m => m.PrintwhoutPageModule)
  },
  {
    path: 'printwhout/:editMode/:id/:nowhout/:whoutdate/:dodate/:stat/:wh_id/:nmwh/:refno/:pic',
    loadChildren: () =>
      import('./printwhout/printwhout.module').then(
        (m) => m.PrintwhoutPageModule
      ),
  },
  {
    path: 'listso',
    loadChildren: () => import('./listso/listso.module').then( m => m.ListsoPageModule)
  },
  {
    path: 'whin',
    loadChildren: () => import('./whin/whin.module').then( m => m.WhinPageModule)
  },
  {
    path: 'adwhin',
    loadChildren: () => import('./adwhin/adwhin.module').then( m => m.AdwhinPageModule)
  },
  {
    path: 'adwhin/:editMode/:editId/:nowhin/:whindate/:wh_id/:nmwh/:refno/:pic/:grdate/:stat',
    loadChildren: () =>
      import('./adwhin/adwhin.module').then(
        (m) => m.AdwhinPageModule
      ),
  },
  {
    path: 'printwhin',
    loadChildren: () => import('./printwhin/printwhin.module').then( m => m.PrintwhinPageModule)
  },
  {
    path: 'printwhin/:editMode/:id/:nowhin/:whindate/:grdate/:stat/:wh_id/:nmwh/:refno/:pic',
    loadChildren: () =>
      import('./printwhin/printwhin.module').then(
        (m) => m.PrintwhinPageModule
      ),
  },
  {
    path: 'menu',
    loadChildren: () => import('./menu/menu.module').then( m => m.MenuPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'absen',
    loadChildren: () => import('./absen/absen.module').then( m => m.AbsenPageModule)
  },
  {
    path: 'map',
    loadChildren: () => import('./map/map.module').then( m => m.MapPageModule)
  },
  {
    path: 'coa',
    loadChildren: () => import('./coa/coa.module').then( m => m.CoaPageModule)
  },
  {
    path: 'addcoa',
    loadChildren: () => import('./addcoa/addcoa.module').then( m => m.AddcoaPageModule)
  },
  {
    path: 'addcoa/:editMode',
    loadChildren: () =>
      import('./addcoa/addcoa.module').then(
        (m) => m.AddcoaPageModule
      ),
  },
  {
    path: 'addcoa/:editMode/:editId/:noacc/:nmacc/:accdate/:posdef/:used/:grup/:subgrup/:saldoawal/:ortu',
    loadChildren: () =>
      import('./addcoa/addcoa.module').then(
        (m) => m.AddcoaPageModule
      ),
  },
  {
    path: 'accperiod',
    loadChildren: () => import('./accperiod/accperiod.module').then( m => m.AccperiodPageModule)
  },
  {
    path: 'accsetup',
    loadChildren: () => import('./accsetup/accsetup.module').then( m => m.AccsetupPageModule)
  },
  {
    path: 'accmap',
    loadChildren: () => import('./accmap/accmap.module').then( m => m.AccmapPageModule)
  },
  {
    path: 'addaccmap',
    loadChildren: () => import('./addaccmap/addaccmap.module').then( m => m.AddaccmapPageModule)
  },
  {
    path: 'addaccmap/:editMode',
    loadChildren: () =>
      import('./addaccmap/addaccmap.module').then(
        (m) => m.AddaccmapPageModule
      ),
  },
  {
    path: 'addaccmap/:editMode/:editId/:kdauto/:nmauto/:cara_bayar/:grup',
    loadChildren: () =>
      import('./addaccmap/addaccmap.module').then(
        (m) => m.AddaccmapPageModule
      ),
  },
  {
    path: 'add-accmap-det',
    loadChildren: () => import('./add-accmap-det/add-accmap-det.module').then( m => m.AddAccmapDetPageModule)
  },
  {
    path: 'add-accmap-det/:editMode/:id/:editId/:account_id/:posisi/:persen/:noacc/:nmacc/:kdauto',
    loadChildren: () =>
      import('./add-accmap-det/add-accmap-det.module').then(
        (m) => m.AddAccmapDetPageModule
      ),
  },
  {
    path: 'jurnal',
    loadChildren: () => import('./jurnal/jurnal.module').then( m => m.JurnalPageModule)
  },
  {
    path: 'addjurnal',
    loadChildren: () => import('./addjurnal/addjurnal.module').then( m => m.AddjurnalPageModule)
  },
  {
    path: 'addjurnal/:editMode',
    loadChildren: () =>
      import('./addjurnal/addjurnal.module').then(
        (m) => m.AddjurnalPageModule
      ),
  },
  {
    
    path: 'addjurnal/:editMode/:datejrn/:nojrn/:trndate/:notrn/:deskripsi/:cost_id/:posted/:approved',
    loadChildren: () =>
      import('./addjurnal/addjurnal.module').then(
        (m) => m.AddjurnalPageModule
      ),
  },
  {
    path: 'add-jurnal-det',
    loadChildren: () => import('./add-jurnal-det/add-jurnal-det.module').then( m => m.AddJurnalDetPageModule)
  },
  {
    
    path: 'add-jurnal-det/:editMode/:nojrn',
    loadChildren: () =>
      import('./add-jurnal-det/add-jurnal-det.module').then(
        (m) => m.AddJurnalDetPageModule
      ),
  },
  {
    
    path: 'add-jurnal-det/:editMode/:editId/:account_id/:posisi/:ket_detil/:nominal/:noacc/:nmacc/:nojrn',
    loadChildren: () =>
      import('./add-jurnal-det/add-jurnal-det.module').then(
        (m) => m.AddJurnalDetPageModule
      ),
  },
  {
    
    path: 'add-jurnal-det/:editMode/:nojrn/:datejrn/:trndate/:notrn/:deskripsi/:cost_id',
    loadChildren: () =>
      import('./add-jurnal-det/add-jurnal-det.module').then(
        (m) => m.AddJurnalDetPageModule
      ),
  },
  {
    
    path: 'add-jurnal-det/:editMode/:editId/:account_id/:posisi/:ket_detil/:nominal/:nojrn/:datejrn/:trndate/:notrn/:deskripsi/:cost_id',
    loadChildren: () =>
      import('./add-jurnal-det/add-jurnal-det.module').then(
        (m) => m.AddJurnalDetPageModule
      ),
  },
  {
    path: 'printjurnal',
    loadChildren: () => import('./printjurnal/printjurnal.module').then( m => m.PrintjurnalPageModule)
  },
  {
    path: 'printjurnal/:nojrn/:datejrn/:deskripsi/:nmcc',
    loadChildren: () =>
      import('./printjurnal/printjurnal.module').then(
        (m) => m.PrintjurnalPageModule
      ),
  },
  {
    path: 'gensimpan',
    loadChildren: () => import('./gensimpan/gensimpan.module').then( m => m.GensimpanPageModule)
  },
  {
    path: 'gensimpan/:tr/:editMode',
    loadChildren: () =>
      import('./gensimpan/gensimpan.module').then(
        (m) => m.GensimpanPageModule
      ),
  },
  {
    path: 'user',
    loadChildren: () => import('./user/user.module').then( m => m.UserPageModule)
  },
  {
    path: 'user/:tr',
    loadChildren: () =>
      import('./user/user.module').then(
        (m) => m.UserPageModule
      ),
  },
  {
    path: 'adduser',
    loadChildren: () => import('./adduser/adduser.module').then( m => m.AdduserPageModule)
  },
  {
    path: 'adduser/:editMode',
    loadChildren: () =>
      import('./adduser/adduser.module').then(
        (m) => m.AdduserPageModule
      ),
  },
  {
    path: 'adduser/:editMode/:editId/:kduser/:nmuser/:username/:password/:address/:birthplace/:marital/:ptkp/:phone/:ktp/:npwp/:sex/:img_file/:email/:hakan/:birthdate/:branch_id/:joindate/:spv_id/:hrapprv/:fatapprv/:ttd_file/:ceoapprv/:fatkasir/:gaapprv',
    loadChildren: () =>
      import('./adduser/adduser.module').then(
        (m) => m.AdduserPageModule
      ),
  },
  {
    path: 'addfoto',
    loadChildren: () => import('./addfoto/addfoto.module').then( m => m.AddfotoPageModule)
  },
  {
    path: 'addlocation',
    loadChildren: () => import('./addlocation/addlocation.module').then( m => m.AddlocationPageModule)
  },
  {
    path: 'accreport',
    loadChildren: () => import('./accreport/accreport.module').then( m => m.AccreportPageModule)
  },
  {
    path: 'printtbd',
    loadChildren: () => import('./printtbd/printtbd.module').then( m => m.PrinttbdPageModule)
  },
  {
    path: 'printtbd/:tahun/:bulan',
    loadChildren: () =>
      import('./printtbd/printtbd.module').then(
        (m) => m.PrinttbdPageModule
      ),
  },
  {
    path: 'printtbs',
    loadChildren: () => import('./printtbs/printtbs.module').then( m => m.PrinttbsPageModule)
  },
  {
    path: 'printtbs/:tahun/:bulan',
    loadChildren: () =>
      import('./printtbs/printtbs.module').then(
        (m) => m.PrinttbsPageModule
      ),
  },
  {
    path: 'printnrd',
    loadChildren: () => import('./printnrd/printnrd.module').then( m => m.PrintnrdPageModule)
  },
  {
    path: 'printnrd/:tahun/:bulan',
    loadChildren: () =>
      import('./printnrd/printnrd.module').then(
        (m) => m.PrintnrdPageModule
      ),
  },
  {
    path: 'printnrs',
    loadChildren: () => import('./printnrs/printnrs.module').then( m => m.PrintnrsPageModule)
  },
  {
    path: 'printnrs/:tahun/:bulan',
    loadChildren: () =>
      import('./printnrs/printnrs.module').then(
        (m) => m.PrintnrsPageModule
      ),
  },
  {
    path: 'printpld',
    loadChildren: () => import('./printpld/printpld.module').then( m => m.PrintpldPageModule)
  },
  {
    path: 'printpld/:tahun/:bulan',
    loadChildren: () =>
      import('./printpld/printpld.module').then(
        (m) => m.PrintpldPageModule
      ),
  },
  {
    path: 'printpls',
    loadChildren: () => import('./printpls/printpls.module').then( m => m.PrintplsPageModule)
  },
  {
    path: 'printpls/:tahun/:bulan',
    loadChildren: () =>
      import('./printpls/printpls.module').then(
        (m) => m.PrintplsPageModule
      ),
  },
  {
    path: 'printcfd',
    loadChildren: () => import('./printcfd/printcfd.module').then( m => m.PrintcfdPageModule)
  },
  {
    path: 'printcfd/:tahun/:bulan',
    loadChildren: () =>
      import('./printcfd/printcfd.module').then(
        (m) => m.PrintcfdPageModule
      ),
  },
  {
    path: 'comlist',
    loadChildren: () => import('./comlist/comlist.module').then( m => m.ComlistPageModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./signup/signup.module').then( m => m.SignupPageModule)
  },
  {
    path: 'signup/:editMode',
    loadChildren: () =>
      import('./signup/signup.module').then(
        (m) => m.SignupPageModule
      ),
  },
  {
    path: 'acctools',
    loadChildren: () => import('./acctools/acctools.module').then( m => m.AcctoolsPageModule)
  },
  {
    path: 'hris',
    loadChildren: () => import('./hris/hris.module').then( m => m.HrisPageModule)
  },
  {
    path: 'gentrf',
    loadChildren: () => import('./gentrf/gentrf.module').then( m => m.GentrfPageModule)
  },
  {
    path: 'printmutasi',
    loadChildren: () => import('./printmutasi/printmutasi.module').then( m => m.PrintmutasiPageModule)
  },
  {
    path: 'printmutasi/:editMode/:id/:nomutasi/:mutasidate/:stat/:wh_id/:wh_id2',
    loadChildren: () =>
      import('./printmutasi/printmutasi.module').then(
        (m) => m.PrintmutasiPageModule
      ),
  },
  {
    path: 'resto',
    loadChildren: () => import('./resto/resto.module').then( m => m.RestoPageModule)
  },
  {
    path: 'cart-modal',
    loadChildren: () => import('./pages/cart-modal/cart-modal.module').then( m => m.CartModalPageModule)
  },{
    path: 'cart-modal/:editMode/:id/:noso',
    loadChildren: () =>
      import('./pages/cart-modal/cart-modal.module').then(
        (m) => m.CartModalPageModule
      ),
  },
  {
    path: 'cafe',
    loadChildren: () => import('./cafe/cafe.module').then( m => m.CafePageModule)
  },
  {
    path: 'jobcost',
    loadChildren: () => import('./jobcost/jobcost.module').then( m => m.JobcostPageModule)
  },
  {
    path: 'jobcost/:chr',
    loadChildren: () =>
      import('./jobcost/jobcost.module').then(
        (m) => m.JobcostPageModule
      ),
  },
  {
    path: 'addjobcost',
    loadChildren: () => import('./addjobcost/addjobcost.module').then( m => m.AddjobcostPageModule)
  },
  {
   
    path: 'addjobcost/:editMode/:id/:nojob/:jobdate/:finishdate/:target/:final/:reject/:status/:wh_id/:product_fg_id/:hpp/:hargajual/:kdproduct/:nmproduct/:spek/:nmunit/:cs',
    loadChildren: () =>
      import('./addjobcost/addjobcost.module').then(
        (m) => m.AddjobcostPageModule
      ),
  },
  {
    path: 'addjobcost/:editMode',
    loadChildren: () =>
      import('./addjobcost/addjobcost.module').then(
        (m) => m.AddjobcostPageModule
      ),
  },
  {
    path: 'add-jobcost-det',
    loadChildren: () => import('./add-jobcost-det/add-jobcost-det.module').then( m => m.AddJobcostDetPageModule)
  },
  {
    path: 'add-jobcost-det/:editMode/:id/:job_id/:product_raw_id/:qty/:harga/:nojob/:wh_id/:kdproduct/:nmproduct/:spek/:nmunit',
    loadChildren: () =>
      import('./add-jobcost-det/add-jobcost-det.module').then(
        (m) => m.AddJobcostDetPageModule
      ),
  },
  {
    path: 'htsa',
    loadChildren: () => import('./htsa/htsa.module').then( m => m.HtsaPageModule)
  },
  {
    path: 'htsa/:editMode',
    loadChildren: () =>
      import('./htsa/htsa.module').then(
        (m) => m.HtsaPageModule
      ),
  },
  {
    path: 'ptsa',
    loadChildren: () => import('./ptsa/ptsa.module').then( m => m.PtsaPageModule)
  },
  {
    path: 'ptsa/:editMode',
    loadChildren: () =>
      import('./ptsa/ptsa.module').then(
        (m) => m.PtsaPageModule
      ),
  },
  {
    path: 'paycart',
    loadChildren: () => import('./paycart/paycart.module').then( m => m.PaycartPageModule)
  },
  {
    path: 'restocafe',
    loadChildren: () => import('./restocafe/restocafe.module').then( m => m.RestocafePageModule)
  },
  {
    path: 'orderlist',
    loadChildren: () => import('./orderlist/orderlist.module').then( m => m.OrderlistPageModule)
  },
  {
    path: 'expense',
    loadChildren: () => import('./expense/expense.module').then( m => m.ExpensePageModule)
  },
  {
    path: 'addexp',
    loadChildren: () => import('./addexp/addexp.module').then( m => m.AddexpPageModule)
  },
  {
    path: 'addexp/:editMode',
    loadChildren: () =>
      import('./addexp/addexp.module').then(
        (m) => m.AddexpPageModule
      ),
  },
  {
    path: 'addexp/:editMode/:editId/:nmexp/:account_id/:noacc/:nmacc',
    loadChildren: () =>
      import('./addexp/addexp.module').then(
        (m) => m.AddexpPageModule
      ),
  },
  {
    path: 'addbudi',
    loadChildren: () => import('./addbudi/addbudi.module').then( m => m.AddbudiPageModule)
  },
  {
    path: 'addbudi/:editMode',
    loadChildren: () =>
      import('./addbudi/addbudi.module').then(
        (m) => m.AddbudiPageModule
      ),
  },
  {
    path: 'addbudi/:editMode/:id/:budidate/:nobudi/:kumbung_id/:numlogg/:panen/:status',
    loadChildren: () =>
      import('./addbudi/addbudi.module').then(
        (m) => m.AddbudiPageModule
      ),
  },
  {
    path: 'budi',
    loadChildren: () => import('./budi/budi.module').then( m => m.BudiPageModule)
  },
  {
    path: 'kumbung',
    loadChildren: () => import('./kumbung/kumbung.module').then( m => m.KumbungPageModule)
  },
  {
    path: 'dept',
    loadChildren: () => import('./dept/dept.module').then( m => m.DeptPageModule)
  },
  {
    path: 'title',
    loadChildren: () => import('./title/title.module').then( m => m.TitlePageModule)
  },
  {
    path: 'addgrade',
    loadChildren: () => import('./addgrade/addgrade.module').then( m => m.AddgradePageModule)
  },
  {
    path: 'addgrade/:editMode',
    loadChildren: () =>
      import('./addgrade/addgrade.module').then(
        (m) => m.AddgradePageModule
      ),
  },
  {
 
    path: 'addgrade/:editMode/:id/:grade/:minsalary/:maxsalary/:leavenum/:mealhour/:otmeal/:chkmeal/:transhour/:ottrans/:chktrans/:paidot/:deskripsi',
    loadChildren: () =>
      import('./addgrade/addgrade.module').then(
        (m) => m.AddgradePageModule
      ),
  },
  {
    path: 'grade',
    loadChildren: () => import('./grade/grade.module').then( m => m.GradePageModule)
  },
  {
    path: 'variable',
    loadChildren: () => import('./variable/variable.module').then( m => m.VariablePageModule)
  },
  {
    path: 'car',
    loadChildren: () => import('./car/car.module').then( m => m.CarPageModule)
  },
  {
    path: 'room',
    loadChildren: () => import('./room/room.module').then( m => m.RoomPageModule)
  },
  {
    path: 'asset',
    loadChildren: () => import('./asset/asset.module').then( m => m.AssetPageModule)
  },
  {
    path: 'payroll',
    loadChildren: () => import('./payroll/payroll.module').then( m => m.PayrollPageModule)
  },
  {
    path: 'addpayroll',
    loadChildren: () => import('./addpayroll/addpayroll.module').then( m => m.AddpayrollPageModule)
  },
  {
    path: 'addpayroll/:editMode',
    loadChildren: () =>
      import('./addpayroll/addpayroll.module').then(
        (m) => m.AddpayrollPageModule
      ),
  },
  {
    path: 'addpayroll/:editMode/:id/:crdate/:jenis/:deskripsi/:stat',
    loadChildren: () =>
      import('./addpayroll/addpayroll.module').then(
        (m) => m.AddpayrollPageModule
      ),
  },
  {
    path: 'printslip',
    loadChildren: () => import('./printslip/printslip.module').then( m => m.PrintslipPageModule)
  },
  {
    path: 'printslip/:id/:crdate/:kduser/:nmuser/:basicsalary/:thp/:stat/:jenis/:deskripsi/:genslip_id/:asal',
    loadChildren: () =>
      import('./printslip/printslip.module').then(
        (m) => m.PrintslipPageModule
      ),
  },
  {
    path: 'genmed',
    loadChildren: () => import('./genmed/genmed.module').then( m => m.GenmedPageModule)
  },
  {
    path: 'genmed/:chr',
    loadChildren: () =>
      import('./genmed/genmed.module').then(
        (m) => m.GenmedPageModule
      ),
  },
  {
    path: 'addgenmed',
    loadChildren: () => import('./addgenmed/addgenmed.module').then( m => m.AddgenmedPageModule)
  },
  {
    path: 'addgenmed/:editMode',
    loadChildren: () =>
      import('./addgenmed/addgenmed.module').then(
        (m) => m.AddgenmedPageModule
      ),
  },
  {
    path: 'addgenmed/:editMode/:id/:crdate/:deskripsi/:stat/:start/:finish/:grandtotal',
    loadChildren: () =>
      import('./addgenmed/addgenmed.module').then(
        (m) => m.AddgenmedPageModule
      ),
  },
  {
    path: 'addpayrolldet',
    loadChildren: () => import('./addpayrolldet/addpayrolldet.module').then( m => m.AddpayrolldetPageModule)
  },
  {
    path: 'addpayrolldet/:id',
    loadChildren: () =>
      import('./addpayrolldet/addpayrolldet.module').then(
        (m) => m.AddpayrolldetPageModule
      ),
  },
  {
    path: 'adddetslip',
    loadChildren: () => import('./adddetslip/adddetslip.module').then( m => m.AdddetslipPageModule)
  },
  {
    path: 'adddetslip/:id/:nmvar/:amount/:headslip_id',
    loadChildren: () =>
      import('./adddetslip/adddetslip.module').then(
        (m) => m.AdddetslipPageModule
      ),
  },
  {
    path: 'medical',
    loadChildren: () => import('./medical/medical.module').then( m => m.MedicalPageModule)
  },
  {
    path: 'medical/:chr',
    loadChildren: () =>
      import('./medical/medical.module').then(
        (m) => m.MedicalPageModule
      ),
  },
  {
    path: 'addmedical',
    loadChildren: () => import('./addmedical/addmedical.module').then( m => m.AddmedicalPageModule)
  },
  {
    path: 'addmedical/:editMode/:chr',
    loadChildren: () =>
      import('./addmedical/addmedical.module').then(
        (m) => m.AddmedicalPageModule
      ),
  },
  {
    path: 'addmedical/:editMode/:chr/:id/:nomed/:crdate/:user_id/:deskripsi/:kduser/:nmuser/:grandtotal/:stat/:empsign/:spvsign/:hrsign/:reject/:alasan/:spv_id',
    loadChildren: () =>
      import('./addmedical/addmedical.module').then(
        (m) => m.AddmedicalPageModule
      ),
  },
  {
    path: 'add-medical-det',
    loadChildren: () => import('./add-medical-det/add-medical-det.module').then( m => m.AddMedicalDetPageModule)
  },
  {
    path: 'add-medical-det/:editMode',
    loadChildren: () =>
      import('./add-medical-det/add-medical-det.module').then(
        (m) => m.AddMedicalDetPageModule
      ),
  },
  {
    path: 'add-medical-det/:editMode/:chr/:medical_id',
    loadChildren: () =>
      import('./add-medical-det/add-medical-det.module').then(
        (m) => m.AddMedicalDetPageModule
      ),
  },
  {
    path: 'add-medical-det/:editMode/:id/:medical_id/:rsdate/:deskripsi/:qty/:amount/:struk_file/:atasnama/:tp',
    loadChildren: () =>
      import('./add-medical-det/add-medical-det.module').then(
        (m) => m.AddMedicalDetPageModule
      ),
  },
  {
    path: 'printmedical',
    loadChildren: () => import('./printmedical/printmedical.module').then( m => m.PrintmedicalPageModule)
  },
  {
    path: 'printmedical/:id/:nomed/:crdate/:kduser/:nmuser/:grandtotal/:empdate/:spvdate/:hrdate/:emptitle/:spvtitle/:hrtitle/:empttd/:spvttd/:hrttd/:empsign/:spvsign/:hrsign/:nmemp/:nmspv/:nmhr',
    loadChildren: () =>
      import('./printmedical/printmedical.module').then(
        (m) => m.PrintmedicalPageModule
      ),
  },
  {
    path: 'leave',
    loadChildren: () => import('./leave/leave.module').then( m => m.LeavePageModule)
  },
  {
    path: 'leave/:editMode',
    loadChildren: () =>
      import('./leave/leave.module').then(
        (m) => m.LeavePageModule
      ),
  },
  {
    path: 'addleave',
    loadChildren: () => import('./addleave/addleave.module').then( m => m.AddleavePageModule)
  },
  {
    path: 'addleave/:editMode',
    loadChildren: () =>
      import('./addleave/addleave.module').then(
        (m) => m.AddleavePageModule
      ),
  },
  {
    path: 'addleave/:editMode/:id/:noleave/:crdate/:leavedate1/:leavedate2/:user_id/:deskripsi/:jenis/:tahun/:this.pengganti_id/:kduser/:nmuser/:stat/:empsign/:spvsign/:hrsign/:reject/:alasan/:spv_id',
    loadChildren: () =>
      import('./addleave/addleave.module').then(
        (m) => m.AddleavePageModule
      ),
  },
  {
    path: 'holiday',
    loadChildren: () => import('./holiday/holiday.module').then( m => m.HolidayPageModule)
  },
  {
    path: 'addholiday',
    loadChildren: () => import('./addholiday/addholiday.module').then( m => m.AddholidayPageModule)
  },
  {
    path: 'addholiday/:editMode',
    loadChildren: () =>
      import('./addholiday/addholiday.module').then(
        (m) => m.AddholidayPageModule
      ),
  },
  {
    path: 'addholiday/:editMode/:id/:holydate/:hari/:ket',
    loadChildren: () =>
      import('./addholiday/addholiday.module').then(
        (m) => m.AddholidayPageModule
      ),
  },
  {
    path: 'addorder',
    loadChildren: () => import('./addorder/addorder.module').then( m => m.AddorderPageModule)
  },
  {
    path: 'addorder/:tr/:editMode',
    loadChildren: () =>
      import('./addorder/addorder.module').then(
        (m) => m.AddorderPageModule
      ),
  }, 
  {
    path: 'addorder/:tr/:editMode/:id/:noso/:sodate/:cust_id/:stat/:tp/:forex_id/:kurs/:kdpartner/:nmpartner/:address/:city/:zipcode/:branch_id/:jp/:sales_id/:kduser/:nmuser',
    loadChildren: () =>
      import('./addorder/addorder.module').then(
        (m) => m.AddorderPageModule
      ),
  }, 
  {
    path: 'loan',
    loadChildren: () => import('./loan/loan.module').then( m => m.LoanPageModule)
  },
  {
    path: 'loan/:chr',
    loadChildren: () =>
      import('./loan/loan.module').then(
        (m) => m.LoanPageModule
      ),
  },
  {
    path: 'addloan',
    loadChildren: () => import('./addloan/addloan.module').then( m => m.AddloanPageModule)
  },
  {
    path: 'addloan/:editMode/:chr',
    loadChildren: () =>
      import('./addloan/addloan.module').then(
        (m) => m.AddloanPageModule
      ),
  },
  {
    path: 'addloan/:editMode/:chr/:id/:noloan/:loandate/:user_id/:deskripsi/:stat/:amount/:term/:cicilan/:sisa/:ostd/:amtadd/:ke/:hold/:payee/:bank_id/:cabang/:rekening/:startloan/:lastpaid/:total/:empsign/:spvsign/:hrsign/:ceosign/:fatsign/:reject/:alasan/:spv_id/:kduser/:nmuser/:totpaid/:tipe',
    loadChildren: () =>
      import('./addloan/addloan.module').then(
        (m) => m.AddloanPageModule
      ),
  },
  {
    path: 'printloan',
    loadChildren: () => import('./printloan/printloan.module').then( m => m.PrintloanPageModule)
  },
  {
    path:'printloan/:id/:noloan/:loandate/:deskripsi/:stat/:amount/:kduser/:nmuser/:term/:cicilan/:sisa/:ostd/:amtadd/:ke/:hold/:payee/:nmbank/:cabang/:rekening/:startloan/:lastpaid/:total/:empdate/:spvdate/:hrdate/:ceodate/:fatdate/:fatdate/:emptitle/:spvtitle/:hrtitle/:ceotitle/:fattitle/:empttd/:spvttd/:hrttd/:ceottd/:fatttd/:nmemp/:nmspv/:nmhr/:nmceo/:nmfat/:totpaid/:empsign/:spvsign/:hrsign/:ceosign/:fatsign/:tipe',
    loadChildren: () =>
      import('./printloan/printloan.module').then(
        (m) => m.PrintloanPageModule
      ),
  },
  {
    path: 'overtime',
    loadChildren: () => import('./overtime/overtime.module').then( m => m.OvertimePageModule)
  },
  {
    path: 'overtime/:chr',
    loadChildren: () =>
      import('./overtime/overtime.module').then(
        (m) => m.OvertimePageModule
      ),
  },
  {
    path: 'addovertime',
    loadChildren: () => import('./addovertime/addovertime.module').then( m => m.AddovertimePageModule)
  },
  {
    path: 'addovertime/:editMode',
    loadChildren: () =>
      import('./addovertime/addovertime.module').then(
        (m) => m.AddovertimePageModule
      ),
  },
  {
    path: 'addovertime/:editMode/:id/:nolembur/:crdate/:user_id/:deskripsi/:kduser/:nmuser/:stat/:empsign/:spvsign/:reject/:alasan/:spv_id/:h1/:m1/:s1/:h2/:m2/:s2',
    loadChildren: () =>
      import('./addovertime/addovertime.module').then(
        (m) => m.AddovertimePageModule
      ),
  },
  {
    path: 'printovertime',
    loadChildren: () => import('./printovertime/printovertime.module').then( m => m.PrintovertimePageModule)
  },
  {
    path: 'printovertime/:id/:nolembur/:crdate/:deskripsi/:kduser/:nmuser/:stat/:empdate/:spvdate/:emptitle/:spvtitle/:empttd/:spvttd/:empsign/:spvsign/:nmemp/:nmspv/:mulai/:akhir',
    loadChildren: () =>
      import('./printovertime/printovertime.module').then(
        (m) => m.PrintovertimePageModule
      ),
  },
  {
    path: 'carusage',
    loadChildren: () => import('./carusage/carusage.module').then( m => m.CarusagePageModule)
  },
  {
    path: 'addcarusage',
    loadChildren: () => import('./addcarusage/addcarusage.module').then( m => m.AddcarusagePageModule)
  },
  {
    path: 'addcarusage/:editMode',
    loadChildren: () =>
      import('./addcarusage/addcarusage.module').then(
        (m) => m.AddcarusagePageModule
      ),
  },
  {
    path: 'addcarusage/:editMode/:id/:norent/:crdate/:user_id/:deskripsi/:kduser/:nmuser/:stat/:empsign/:spvsign/:gasign/:reject/:alasan/:spv_id/:h1/:m1/:h2/:m2/:nopol_id/:tanggal1/:tanggal2/:pergi/:pulang/:nopol/:jenis',
    loadChildren: () =>
      import('./addcarusage/addcarusage.module').then(
        (m) => m.AddcarusagePageModule
      ),
  },
  {
    path: 'roomusage',
    loadChildren: () => import('./roomusage/roomusage.module').then( m => m.RoomusagePageModule)
  },
  {
    path: 'addroomusage',
    loadChildren: () => import('./addroomusage/addroomusage.module').then( m => m.AddroomusagePageModule)
  },
  {
    path: 'addroomusage/:editMode',
    loadChildren: () =>
      import('./addroomusage/addroomusage.module').then(
        (m) => m.AddroomusagePageModule
      ),
  },
  {
    path: 'addroomusage/:editMode/:id/:norent/:crdate/:user_id/:deskripsi/:kduser/:nmuser/:stat/:empsign/:spvsign/:gasign/:reject/:alasan/:spv_id/:h1/:m1/:h2/:m2/:room_id/:tanggal1/:tanggal2/:pergi/:pulang/:nmroom/:lokasi',
    loadChildren: () =>
      import('./addroomusage/addroomusage.module').then(
        (m) => m.AddroomusagePageModule
      ),
  },
  {
    path: 'ablist',
    loadChildren: () => import('./ablist/ablist.module').then( m => m.AblistPageModule)
  },
  {
    path: 'addablist',
    loadChildren: () => import('./addablist/addablist.module').then( m => m.AddablistPageModule)
  },
  {
    path: 'addablist/:editMode',
    loadChildren: () =>
      import('./addablist/addablist.module').then(
        (m) => m.AddablistPageModule
      ),
  },
  {
    path: 'addablist/:editMode/:id/:user_id/:abdate/:h1/:m1/:s1/:h2/:m2/:s2/:notes_in/:notes_out/:latitude/:longitude/:mskfile/:klrfile/:kduser/:nmuser',
    loadChildren: () =>
      import('./addablist/addablist.module').then(
        (m) => m.AddablistPageModule
      ),
  },
  {
    path: 'genot',
    loadChildren: () => import('./genot/genot.module').then( m => m.GenotPageModule)
  },
  {
    path: 'addgenot',
    loadChildren: () => import('./addgenot/addgenot.module').then( m => m.AddgenotPageModule)
  },
  {
    path: 'addgenot/:editMode',
    loadChildren: () =>
      import('./addgenot/addgenot.module').then(
        (m) => m.AddgenotPageModule
      ),
  },
  {
    path: 'addgenot/:editMode/:id/:crdate/:deskripsi/:stat/:start/:finish/:grandtotal/:ramadhan',
    loadChildren: () =>
      import('./addgenot/addgenot.module').then(
        (m) => m.AddgenotPageModule
      ),
  },
  {
    path: 'payslip',
    loadChildren: () => import('./payslip/payslip.module').then( m => m.PayslipPageModule)
  },
  {
    path: 'addtitle',
    loadChildren: () => import('./addtitle/addtitle.module').then( m => m.AddtitlePageModule)
  },
  {
    path: 'addtitle/:editMode',
    loadChildren: () =>
      import('./addtitle/addtitle.module').then(
        (m) => m.AddtitlePageModule
      ),
  },
  {
    path: 'addtitle/:editMode/:id/:nmtitle/:lepel/:uper/:nmspr',
    loadChildren: () =>
      import('./addtitle/addtitle.module').then(
        (m) => m.AddtitlePageModule
      ),
  },
  {
    path: 'addasset',
    loadChildren: () => import('./addasset/addasset.module').then( m => m.AddassetPageModule)
  },
  {
    path: 'addasset/:editMode',
    loadChildren: () =>
      import('./addasset/addasset.module').then(
        (m) => m.AddassetPageModule
      ),
  },
  {
    path: 'addasset/:editMode/:id/:kdasset/:nmasset/:spek/:qty/:hargabeli/:branch_id/:img_file/:kategori/:tipe/:factorycode/:brandname/:buydate',
    loadChildren: () =>
      import('./addasset/addasset.module').then(
        (m) => m.AddassetPageModule
      ),
  },
  {
    path: 'assettype',
    loadChildren: () => import('./assettype/assettype.module').then( m => m.AssettypePageModule)
  },
  {
    path: 'leavetype',
    loadChildren: () => import('./leavetype/leavetype.module').then( m => m.LeavetypePageModule)
  },
  {
    path: 'pagrup',
    loadChildren: () => import('./pagrup/pagrup.module').then( m => m.PagrupPageModule)
  },
  {
    path: 'padefault',
    loadChildren: () => import('./padefault/padefault.module').then( m => m.PadefaultPageModule)
  },
  {
    path: 'addpadefault',
    loadChildren: () => import('./addpadefault/addpadefault.module').then( m => m.AddpadefaultPageModule)
  },
  {
    path: 'addpadefault/:editMode',
    loadChildren: () =>
      import('./addpadefault/addpadefault.module').then(
        (m) => m.AddpadefaultPageModule
      ),
  },
  {
    path: 'addpadefault/:editMode/:editId/:padesc/:pagrup_id',
    loadChildren: () =>
      import('./addpadefault/addpadefault.module').then(
        (m) => m.AddpadefaultPageModule
      ),
  },
  {
    path: 'printdefault',
    loadChildren: () => import('./printdefault/printdefault.module').then( m => m.PrintdefaultPageModule)
  },
  {
    path: 'printdefault/:dept_id/:nmdept',
    loadChildren: () =>
      import('./printdefault/printdefault.module').then(
        (m) => m.PrintdefaultPageModule
      ),
  },
  {
    path: 'genkpi',
    loadChildren: () => import('./genkpi/genkpi.module').then( m => m.GenkpiPageModule)
  },
  {
    path: 'addgenkpi',
    loadChildren: () => import('./addgenkpi/addgenkpi.module').then( m => m.AddgenkpiPageModule)
  },
  {
    path: 'addgenkpi/:editMode/:emp',
    loadChildren: () =>
      import('./addgenkpi/addgenkpi.module').then(
        (m) => m.AddgenkpiPageModule
      ),
  },
  {
    path: 'addgenkpi/:editMode/:editId/:tahun/:deskripsi/:stat/:emp',
    loadChildren: () =>
      import('./addgenkpi/addgenkpi.module').then(
        (m) => m.AddgenkpiPageModule
      ),
  },
  {
    path: 'addgenkpi/:editMode/:emp/:chr',
    loadChildren: () =>
      import('./addgenkpi/addgenkpi.module').then(
        (m) => m.AddgenkpiPageModule
      ),
  },
  {
    path: 'itempadefault',
    loadChildren: () => import('./itempadefault/itempadefault.module').then( m => m.ItempadefaultPageModule)
  },
  {
    path: 'itempadefault/:editMode/:id/:editId/:padesc/:nmdept/:weight/:dept_id/:padesc_id/:pagrup_id',
    loadChildren: () =>
      import('./itempadefault/itempadefault.module').then(
        (m) => m.ItempadefaultPageModule
      ),
  },
  {
    path: 'addpaitem',
    loadChildren: () => import('./addpaitem/addpaitem.module').then( m => m.AddpaitemPageModule)
  },
  {
    path: 'paitem',
    loadChildren: () => import('./paitem/paitem.module').then( m => m.PaitemPageModule)
  },
  {
    path: 'genkpiemp',
    loadChildren: () => import('./genkpiemp/genkpiemp.module').then( m => m.GenkpiempPageModule)
  },
  {
    path: 'genkpiemp/:editId/:nmdept/:nmtitle/:tahun/:user_id/:kduser/:nmuser/:kpi_id/:deskripsi/:dept_id/:stat/:emp',
    loadChildren: () =>
      import('./genkpiemp/genkpiemp.module').then(
        (m) => m.GenkpiempPageModule
      ),
  },
  {
    path: 'genkpiempdet',
    loadChildren: () => import('./genkpiempdet/genkpiempdet.module').then( m => m.GenkpiempdetPageModule)
  },
  {
    path: 'genkpiempdet/:editId/:padesc/:nmgrup/:tahun/:weight/:kpi_id/:deskripsi/:nmdept/:nmtitle/:user_id/:kduser/:nmuser/:genkpi_id/:padesc_id/:dept_id/:stat/:emp',
    loadChildren: () =>
      import('./genkpiempdet/genkpiempdet.module').then(
        (m) => m.GenkpiempdetPageModule
      ),
  },
  {
    path: 'printkpi',
    loadChildren: () => import('./printkpi/printkpi.module').then( m => m.PrintkpiPageModule)
  },
  {
    path: 'printkpi/:id/:kduser/:nmuser/:nmdept/:nmtitle/:deskripsi/:tahun/:stat/:kpi_id/:dept_id/:emp/:editId',
    loadChildren: () =>
      import('./printkpi/printkpi.module').then(
        (m) => m.PrintkpiPageModule
      ),
  },
  {
    path: 'meja',
    loadChildren: () => import('./meja/meja.module').then( m => m.MejaPageModule)
  },
  {
    path: 'addmeja',
    loadChildren: () => import('./addmeja/addmeja.module').then( m => m.AddmejaPageModule)
  },
  {
    path: 'addmeja/:editMode',
    loadChildren: () =>
      import('./addmeja/addmeja.module').then(
        (m) => m.AddmejaPageModule
      ),
  },
  {
    path: 'addmeja/:editMode/:editId/:nmmeja/:seat/:bookdate/:booked/:mulai/:jam',
    loadChildren: () =>
      import('./addmeja/addmeja.module').then(
        (m) => m.AddmejaPageModule
      ),
  },
  {
    path: 'organ',
    loadChildren: () => import('./organ/organ.module').then( m => m.OrganPageModule)
  },

  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
